import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getCurrentCompany, submitCompany, submitCompanyURL} from "../../actions/companyActions";
import PropTypes from "prop-types";
import isEmpty from "../../validation/is-empty";
import companyImg from '../../assets/img/company.svg';
import bizzerdLogo from '../../assets/img/bizzerd_logo.png';
import {ErrorMessage} from "../common/AuthErrorComponent";


class CreateCompany extends Component{
    constructor(props){
        super(props);
        this.state= {
            domain:'',
            company: {},
            display: false
        }
    }

    componentDidMount(){
        if(this.props.company.company.name === undefined){
            this.props.getCurrentCompany();
        }
        else{
            this.setState({
                company: this.props.company.company
            }, () => this.createValue());

        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.errors){
            this.setState({errors: nextProps.errors.errors});
        }
        if(!isEmpty(nextProps.company.company)){
            this.setState({company: nextProps.company.company},() => {
                    this.createValue();
            });
        }
    }

    createValue = () => {
        let domainsuggest = this.state.company.name.toLowerCase().replace(/[^a-z0-9]/g, "");
        if(this.state.company !== undefined){
            this.setState({
                domain: domainsuggest,
                display: true
            });
        }
        else{
            return "";
        }
    };

    onChange = (event) => {
        this.setState({
            [event.target.name] : event.target.value
        })
    };

    onSubmit = (event) => {
        event.preventDefault();
        this.props.submitCompanyURL({domain: this.state.domain}, this.state.company._id, this.props.history)
    };

    render(){
        const {errors} = this.state;
        let error;
        if(errors !== undefined){
            error = <ErrorMessage errors={errors}/>
        }
        let display;
        if(!this.state.display){
            display = <h4>Getting Company name</h4>
        }
        else {
            display =
                <form onSubmit={this.onSubmit} className="form-group">
                    <div className="bedrijfsnaam--input-field__wrapper">
                        <div className="input-field__label">
                        </div>
                        <div className="bedrijfsnaam--suffix">
                            <span>app2.bizzerd.com/show/</span>
                        </div>
                        <input id="bedrijfsnaam" name="domain" type="text" placeholder="Bedrijfsnaam"
                               autoFocus="autofocus" className="bedrijfsnaam--input-field" onChange={this.onChange} value={this.state.domain}/>

                    </div>
                    <div className="form-group__footer">
                        <button type="submit" className="button button--primary">
                            Bizzerd creëren
                        </button>
                    </div>
                    {error}
                </form>
        }
        return(
            <div className="wrapper">
                <div className="card">
                    <h2>Welke URL wil je gebruiken voor je digitale visitekaartjes?</h2>
                    <p>Kies het webadres waar de digitale visitekaartjes op komen te staan.</p>
                    {display}
                    <div className="card--graphic">
                        <img src={companyImg} alt="Klik hier om terug te gaan"/>
                    </div>
                </div>
                <div className="wrapper--footer">
                    <p>Versie: 2.0. Ondervind je problemen? Neem dan contact op met <a href="mailto:support@bizzerd.com" target="_blank" rel="noopener noreferrer">support@bizzerd.com</a>.</p>
                </div>
                <div className="wrapper--logo">
                    <a href="https://www.bizzerd.com" target="_blank" rel="noopener noreferrer">
                        <img src={bizzerdLogo} alt="Klik hier om naar de homepage te gaan"/>
                    </a>
                </div>
            </div>
        )
    }
}
CreateCompany.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    submitCompany : PropTypes.func.isRequired,
    company: PropTypes.object.isRequired,
    getCurrentCompany: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
    company: state.company
});
export default connect(mapStateToProps, {submitCompany, getCurrentCompany, submitCompanyURL})(CreateCompany)