import {GET_ERRORS, GET_PAYMENT, GET_PAYMENT_RESULT, GET_PAYMENT_STATUS, GET_PAYMENTS} from "./types";
import axios from "axios/index";

export const initiatePaymentSession = (email, description, returnUrl, webhook) => dispatch => {
    const post = {
        email: email,
        description: description,
        redirectUrl: returnUrl,
        webhookUrl: webhook
    };
    axios.post(`/api/payment/initiate`, post)
        .then( res => {
            dispatch({
                type: GET_PAYMENT_STATUS,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({
                type:GET_ERRORS,
                payload: err.response.data
            });
        })
}

export const checkPaymentSession = (paymentId) => dispatch => {
    const post = {
        id: paymentId
    };
    axios.post(`/api/payment/result`, post)
        .then(res => {
            dispatch({
                type: GET_PAYMENT_RESULT,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({
                type:GET_ERRORS,
                payload: err.response.data
            });
        })
}

export const getPaymentData = (id) => dispatch => {
    axios.get(`/api/payment/invoice/${id}`)
        .then(res => {
            dispatch({
                type: GET_PAYMENT,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        })
};

export const getPayments = () => dispatch => {
    axios.get('/api/payment/payments')
        .then(res => {
            dispatch({
                type: GET_PAYMENTS,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        })
}