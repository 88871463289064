import React, {Component} from 'react';
import {connect} from 'react-redux';
import StatisticsHeader from './StatisticsHeader';
import '../../../node_modules/react-vis/dist/style.css';
import PropTypes from "prop-types";
import {getSharesByCompany, getViewsByBizzerdName, getViewsByCompany} from "../../actions/userActions";
import {getCurrentCompany, updateCompany} from "../../actions/companyActions";
import {getPersonsByCompany} from "../../actions/personActions";
import {
    Crosshair,
    Hint,
    HorizontalGridLines,
    LabelSeries,
    LineSeries,
    RadarChart,
    RadialChart,
    VerticalBarSeries,
    VerticalGridLines,
    XAxis,
    XYPlot,
    YAxis
} from 'react-vis';
import CheckBoxTable from "react-table";
import "react-table/react-table.css";
/*
import ForceDirectedGraph from "./ForceDirectedGraph";
*/

const tipStyle = {
    display: 'flex',
    color: '#fff',
    background: '#000',
    alignItems: 'center',
    padding: '5px'
};


class Statistics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chartdata: [],
            company: {},
            value: 0,
            value2: false,
            refresh: false,
            persons: [],
            selected: {},
            selectAll: 0,
            DATA: [{
                name: 'Spider5',
                QRCode: 5,
                Sms: 5,
                Email: 5,
                fill: '#f8f8f8',
                stroke: '#cccccc'
            },
                {
                    name: 'Spider4',
                    QRCode: 4,
                    Sms: 4,
                    Email: 4,
                    fill: 'white',
                    stroke: '#cccccc'
                },
                {
                    name: 'Spider3',
                    QRCode: 3,
                    Sms: 3,
                    Email: 3,
                    fill: '#f8f8f8',
                    stroke: '#cccccc'
                },
                {
                    name: 'Spider2',
                    QRCode: 2,
                    Sms: 2,
                    Email: 2,
                    fill: 'white',
                    stroke: '#cccccc'
                },
                {
                    name: 'Spider1',
                    QRCode: 1,
                    Sms: 1,
                    Email: 1,
                    fill: '#f8f8f8',
                    stroke: '#cccccc'
                }],
            tabledata: [],
        };
        this.toggleRow = this.toggleRow.bind(this);
    }

    componentDidMount() {
        this.props.getPersonsByCompany();
        if (!this.props.company.name) {
            this.props.getCurrentCompany();
        } else {
            this.setState({
                company: this.props.company.company
            });
        }
        window.addEventListener('resize', this.updateWindowDimensions);
        this.updateWindowDimensions()
    }

    componentWillReceiveProps(nextProps) {
        // this.setState({data: null});
        if (nextProps.company.company !== this.state.company) {
            this.setState({
                company: nextProps.company.company
            })
        }
        if (nextProps.data.data) {
            if (!nextProps.data.data.empty) {
                if (nextProps.data.data.total_views === 0) {
                    return
                }
                this.setState({data: nextProps.data.data});
                let counter = 0;
                this.setState({chartdata: []})
                for (let key in nextProps.data.data) {
                    if (nextProps.data.data.hasOwnProperty(key)) {
                        if (key === "total_views") {
                            this.setState({total_view: nextProps.data.data[key]})
                            continue;
                        }
                        let date = new Date(key);
                        let chartdata = this.state.chartdata
                        chartdata[counter] = {x: date.getTime(), y: nextProps.data.data[key]};
                        this.setState({chartdata: chartdata})
                        counter++;
                    }
                }
            }
        }
        if (nextProps.data.databizzerdname) {
            if (!nextProps.data.databizzerdname.empty) {
                this.setState({networkdata: this.generateNetworkData(nextProps.data.databizzerdname)});
                let degreesByUrl = {};
                let degrees = [];
                let counter = 0;
                let totalviews = parseInt(nextProps.data.databizzerdname["total_views"]);
                for (let key in nextProps.data.databizzerdname) {
                    if (nextProps.data.databizzerdname.hasOwnProperty(key)) {
                        if (key === "total_views") {
                            this.setState({totalviews: parseInt(nextProps.data.databizzerdname[key])})
                            continue;
                        }
                        degreesByUrl[key] = parseInt(nextProps.data.databizzerdname[key]) / totalviews * 360;
                        degrees[counter] = {
                            theta: parseInt(nextProps.data.databizzerdname[key]) / totalviews * 360,
                            title: key.substr(key.lastIndexOf("/") + 1, key.length)
                        };
                        counter++;
                    }
                }
                this.setState({degrees: degrees})
            }

        }
        if (nextProps.data.shares) {
            this.setState({shares: nextProps.data.shares})
            let shares = [];
            let counter = 0;
            for (let key in nextProps.data.shares) {
                if (nextProps.data.shares.hasOwnProperty(key)) {
                    if (key === "total_shares") {
                        continue;
                    }
                    shares[counter] = {x: key, y: nextProps.data.shares[key]};
                    counter++;
                }
            }
            this.setState({
                labelData: shares.map((d, idx) => ({
                    x: d.x,
                    y: shares[idx].y
                }))
            })
            this.setState({greenData:shares})
            this.setState({total_share:nextProps.data.shares["total_shares"]})
            let biggest_part = nextProps.data.shares["total_shares"] / 5;
            let data = this.state.DATA
            data[5] = {
                name: 'Shares',
                QRCode: (nextProps.data.shares["QRCode"] / biggest_part),
                Sms: (nextProps.data.shares["Sms"] / biggest_part),
                Email: (nextProps.data.shares["Email"] / biggest_part),
                fill: 'rgba(247,168,35,0.9)',
                stroke: 'rgba(247,168,35,0.9)'
            }
            this.setState({DATA:data})
        }
        if (nextProps.persons.persons && nextProps.data.databizzerdname) {
            let viewedbyemployee = {};
            let employees = [];
            let holdercounter = 0;
            for (let key in nextProps.data.databizzerdname) {
                if (nextProps.data.databizzerdname.hasOwnProperty(key)) {
                    if (key === "total_views") {
                        continue;
                    }
                    let employeename = key.substring(key.lastIndexOf("/") + 1, key.length);
                    employees[holdercounter] = employeename;
                    viewedbyemployee[employeename] = nextProps.data.databizzerdname[key];
                    holdercounter++;
                }
            }
            for (let person in nextProps.persons.persons) {
                let personObject = nextProps.persons.persons[person];
                let tabledata = this.state.tabledata;
                tabledata[person] = {
                    firstName: personObject.firstname,
                    lastName: personObject.lastname,
                    title: personObject.jobtitle,
                    bizzerdname: personObject.bizzerdName,
                    view: ((viewedbyemployee[personObject.bizzerdName]) ? viewedbyemployee[personObject.bizzerdName] : 0),
                    share: 63
                };
                this.setState({tabledata:tabledata})
            }
            if (this.state.persons.length === 0) {
                this.setState({refresh:true})
            }

        }
        if (nextProps.persons.persons && !(nextProps.data.databizzerdname || nextProps.data.shares)) {
            let bizzerdnames = [];
            for (let person in nextProps.persons.persons) {
                bizzerdnames[person] = nextProps.persons.persons[person].bizzerdName;
            }
            this.props.getViewsByCompany(bizzerdnames);
            this.props.getViewsByBizzerdName(bizzerdnames);
            this.props.getSharesByCompany(bizzerdnames);
        }
        if (nextProps.persons.persons && this.state.refresh) {
            this.setState({persons: nextProps.persons.persons,refresh:false});
            this.toggleSelectAll();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        let widthOfGraph = 0;
        if (window.innerWidth < 1300)
            if (window.innerWidth > 990)
                widthOfGraph = (window.innerWidth - 210) / 1.2;
            else
                widthOfGraph = (window.innerWidth) / 1.2;
        else {
            widthOfGraph = 950
        }
        this.setState({width: widthOfGraph});
    };

    onHover = (value) => {
        this.setState({value: value});
    };

    generateNetworkData = (databizzerdname) => {
        let data = {nodes: [], links: []};
        let holdercounter = 0;
        let employees = [];
        let viewedbyemployee = {};
        for (let key in databizzerdname) {
            if (databizzerdname.hasOwnProperty(key)) {
                if (key === "total_views") {
                    continue;
                }
                let employeename = key.substring(key.lastIndexOf("/") + 1, key.length);
                employees[holdercounter] = employeename;
                viewedbyemployee[employeename] = databizzerdname[key];
                holdercounter++;
            }
        }
        let counter = 0;
        let groupcounter = 0;
        let linkcounter = 0;
        for (let employee in employees) {
            let randomValue = viewedbyemployee[employees[employee]];
            data.nodes[counter] = {
                id: employees[employee],
                group: /*parseInt(employee)*/groupcounter,
                title: employees[employee] + ":" + randomValue,
                val: randomValue / 3 / 5
            };
            groupcounter++;
            randomValue = randomValue / 3;
            data.links[linkcounter] = {
                source: employees[employee],
                target: -1,
                value: Math.round(randomValue / 5) + 1,
                color: "#b5b5b5"
            };
            counter++;
            linkcounter++;
            for (let i = 0; i < randomValue; i++) {
                data.nodes[counter] = {
                    id: counter,
                    group: /*parseInt(employee)*/groupcounter,
                    val: viewedbyemployee[employees[employee]] / 100
                };

                data.links[linkcounter] = {source: counter, target: employees[employee], value: 1, color: "#b5b5b5"};
                counter++;
                linkcounter++;
            }

        }
        data.nodes[counter] = {
            id: -1,
            group: groupcounter,
            title: "Bizzerd:" + this.state.total_view,
            val: 12,
            color: "#f7a823"
        };

        return data;
    };

    viewsByDateForAllUsers = () => {
        if (this.state.width) {
            return (<XYPlot height={300} width={this.state.width} xType={"time"}
                            onMouseLeave={() => this.setState({value: false})}
            >
                <VerticalGridLines/>
                <HorizontalGridLines/>
                <XAxis tickLabelAngle={-45}/>
                <YAxis title={"Bekeken"}/>
                <LineSeries
                    animation
                    data={this.state.chartdata}
                    color="#f7a823"
                    onNearestX={this.onHover}
                    style={{strokeWidth: 4}}
                />
                {<Crosshair values={[this.state.value]} titleFormat={(d) => ({
                    title: 'Datum',
                    value: new Date(d[0].x).toLocaleDateString("ne-NE")
                })}
                            itemsFormat={(d) => [{
                                title: 'Bekeken',
                                value: d[0].y
                            }]}

                />}
            </XYPlot>)
        }
    };

    viewsByPageAllDates = () => {
        if (this.state.degrees) {
            return (
                <div>
                    <RadialChart
                        innerRadius={100}
                        radius={140}
                        getAngle={d => d.theta}
                        data={this.state.degrees}
                        onValueMouseOver={v => this.setState({value2: v})}
                        onSeriesMouseOut={v => this.setState({value2: false})}
                        width={300}
                        height={300}
                        padAngle={0.02}

                    >
                        {this.state.value2 !== false && <Hint value={this.state.value2}
                                                              format={(d) => [{
                                                                  title: d['title'],
                                                                  value: Math.round(parseInt(d['theta']) / 360 * this.state.totalviews)
                                                              }]}
                        />}
                        <div style={{
                            color: 'black',
                            position: 'absolute',
                            padding: '5px',
                            left: '130px',
                            bottom: '135px',
                        }}>
                            {this.state.total_view}
                        </div>
                    </RadialChart>

                </div>
            )
        }
    };

    sharesByAllUsers = () => {
        if (this.state.shares && this.state.DATA) {
            const {hoveredCell} = this.state;
            return (
                <RadarChart
                    data={this.state.DATA}
                    margin={{left: 30, right: 30, top: 70}}
                    tickFormat={t => {
                        return '';
                    }}
                    domains={[
                        {name: 'QRCode', domain: [0, 5], getValue: d => d.QRCode},
                        {name: 'Email', domain: [0, 5], getValue: d => d.Email},
                        {name: 'Sms', domain: [0, 5], getValue: d => d.Sms},
                    ]}
                    width={350}
                    height={350}
                    onValueMouseOver={v => {
                        this.setState({hoveredCell: v});
                    }}
                    onValueMouseOut={v => this.setState({hoveredCell: false})}
                    style={{
                        polygons: {
                            strokeWidth: 1,
                            strokeOpacity: 0.8,
                            fillOpacity: 0.8
                        },
                        labels: {
                            textAnchor: 'middle'
                        },
                        axes: {
                            line: {
                                fillOpacity: 0.8,
                                strokeWidth: 0.5,
                                strokeOpacity: 0.8
                            },
                            ticks: {
                                fillOpacity: 0,
                                strokeOpacity: 0
                            },
                            text: {}
                        }
                    }}
                    colorRange={['transparent']}
                    hideInnerMostValues={false}
                    renderAxesOverPolygons={true}
                >
                    {hoveredCell &&
                    hoveredCell.dataName === 'Shares' && (
                        <Hint value={hoveredCell}>
                            <div style={tipStyle}>
                                {hoveredCell.domain}: {Math.round(hoveredCell.value * ((this.state.total_share / 5)))}
                            </div>
                        </Hint>
                    )}
                </RadarChart>

            );
        }
    };

    sharesByAllUsersBarGraph = () => {
        if (this.state.greenData) {
            return (<XYPlot xType="ordinal" width={300} height={300} xDistance={500}>
                <VerticalGridLines/>
                <HorizontalGridLines/>
                <XAxis/>
                <YAxis/>
                <VerticalBarSeries data={this.state.greenData}/>
                <LabelSeries data={this.state.labelData} getLabel={d => d.y}/>
            </XYPlot>)
        }
    };

    render() {
        let first_graph = this.viewsByDateForAllUsers();
        let second_graph = this.viewsByPageAllDates();
        // let third_graph = this.sharesByAllUsers();
        let forth_graph = this.sharesByAllUsersBarGraph();
        let search_box = this.searchCollegas();
        return (
            <div className="dashboard--main">
                <div className="dashboard--main-content">
                    <StatisticsHeader/>
                    <div className="container">
                        <div className="cards--wrapper">
                            <div className="card dashboard--card element--card">
                                <div className="card--header">
                                    <p>Collega's selecteren</p>
                                </div>
                                <div className="card--main">
                                    <div className="table--body">
                                        {search_box}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="cards--wrapper">
                            <div className="card dashboard--card element--card">
                                <div className="card--header">
                                    <p>Totaal bekeken</p>
                                </div>
                                <div className="card--main">
                                    <div className="table--body">
                                        {first_graph}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="cards--wrapper">
                            <div className="card dashboard--card">
                                <div className="card--header">
                                    <p>Bekeken per collega</p>
                                </div>
                                <div className="card--main--pie">
                                    <div className="table--body">
                                        {/*<ForceDirectedGraph
                                        dataNetwork={this.state.networkdata}
                                        height={350}
                                        width={400}
                                        animation
                                        />*/}
                                        {second_graph}
                                    </div>
                                </div>
                            </div>
                            <div className="card dashboard--card">
                                <div className="card--header">
                                    <p>Succesvol gedeeld</p>
                                </div>
                                <div className="card--main--pie">
                                    <div className="table--body">
                                        {forth_graph}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    toggleRow(bizzerdname) {
        const newSelected = Object.assign({}, this.state.selected);
        newSelected[bizzerdname] = !this.state.selected[bizzerdname];
        this.setState({
            selected: newSelected,
            selectAll: 2
        });
    }

    toggleSelectAll() {
        let newSelected = {};

        if (this.state.selectAll === 0) {
            this.state.tabledata.forEach(x => {
                newSelected[x.bizzerdname] = true;
            });
        }

        this.setState({
            selected: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }

    searchCollegas = () => {
        const {tabledata} = this.state;
        const colums = [
            {
                Header: "Naam",
                columns: [{
                    id: "checkbox",
                    accessor: "",
                    Cell: ({original}) => {
                        return (
                            <input
                                type="checkbox"
                                className="checkbox"
                                checked={this.state.selected[original.bizzerdname] === true}
                                onChange={() => this.toggleRow(original.bizzerdname)}
                            />
                        );
                    },
                    Header: x => {
                        return (
                            <input
                                type="checkbox"
                                className="checkbox"
                                width={45}
                                checked={this.state.selectAll === 1}
                                ref={input => {
                                    if (input) {
                                        input.indeterminate = this.state.selectAll === 2;
                                    }
                                }}
                                onChange={() => this.toggleSelectAll()}
                            />
                        );
                    },
                    sortable: false,
                    width: 45
                },
                    {
                        Header: "Voornaam",
                        accessor: "firstName"
                    },
                    {
                        Header: "Achternaam",
                        id: "lastName",
                        accessor: d => d.lastName
                    }
                ]
            },
            {
                Header: "Info",
                columns: [
                    {
                        Header: "Functie",
                        accessor: "title"
                    }, {
                        Header: "Bizzerdnaam",
                        accessor: "bizzerdname"
                    }
                ]
            },
            {
                Header: 'Statistieken',
                columns: [
                    {
                        Header: "Bekeken",
                        accessor: "view"
                    },
                    {
                        Header: "Gedeeld",
                        accessor: "share"
                    }
                ]
            }
        ];
        return (
            <div>
                <CheckBoxTable
                    ref={r => (this.checkboxTable = r)}
                    toggleSelection={this.toggleSelection}
                    selectAll={this.state.selectAll}
                    toggleAll={this.toggleAll}
                    selectType="checkbox"
                    isSelected={this.isSelected}
                    data={tabledata}
                    keyField="id"
                    columns={colums}
                    defaultPageSize={5}
                    className="-striped -highlight"
                />
                <br/>
                <button style={{color: "#FFF", position: "absolute", right: 0}} onClick={this.handleClick}>Aanpassen
                </button>
            </div>
        );
    };

    handleClick = () => {

        let activeBizzerdNames = [];
        let counter = 0;
        for (let selected in this.state.selected) {
            if (this.state.selected[selected]) {
                activeBizzerdNames[counter] = selected;
                counter++;
            }
        }
        let count = 0;
        for (let name in activeBizzerdNames) {
            for (let index in this.state.tabledata) {
                if (activeBizzerdNames[name] === this.state.tabledata[index].bizzerdname) {
                    count = count + this.state.tabledata[index].view;
                }
            }
        }
        if (activeBizzerdNames.length === 0 || count === 0) {
            return
        }
        console.log(this.state);
        this.setState({shares: [], chartdata: [], degrees: [], greenData: []});
        this.props.getSharesByCompany(activeBizzerdNames);
        this.props.getViewsByCompany(activeBizzerdNames);
        this.props.getViewsByBizzerdName(activeBizzerdNames);
    }
}

Statistics
    .propTypes = {
    getViewsByCompany: PropTypes.func.isRequired,
    getViewsByBizzerdName: PropTypes.func.isRequired,
    getSharesByCompany: PropTypes.func.isRequired,
    getPersonsByCompany: PropTypes.func.isRequired
};

const
    mapStateToProps = (state) => ({
        company: state.company,
        data: state.data,
        persons: state.persons
    });

export default connect(mapStateToProps, {
    getViewsByCompany,
    getCurrentCompany,
    getSharesByCompany,
    updateCompany,
    getViewsByBizzerdName,
    getPersonsByCompany
})(Statistics);
