import React, {Component} from 'react';

class TextLine extends Component {

    render() {
        const backgroundStyle = {
            position: 'absolute',
            left: this.props.resize(this.props.layout.left),
            top: this.props.resize(this.props.layout.top),
            width: this.props.resize(this.props.layout.width),
            height: this.props.resize(this.props.layout.height),
            zIndex: this.props.layout.zindex
        };
        const textStyle = {
            color: this.props.layout.color,
            fontSize: this.props.resize(this.props.layout.fontSize),
            fontWeight: (this.props.layout.bold ? "bold" : "normal"),
            fontStyle: (this.props.layout.italic ? "italic" : "normal"),
            fontFamily: this.props.layout.font,
            wordWrap: 'break-word',
            lineHeight: '1.1em',
            display: 'block',
            textAlign: (this.props.layout.textAlign)
        };
        if (this.props.layout.rotation !== undefined) {
            backgroundStyle.transform = "rotate(" + this.props.layout.rotation + "deg)"
        }
        let textTag;
        if (this.props.layout.type !== "translation" && (this.props.link || this.props.layout.link || this.props.layout.type === "adresveld")) {

            let text = this.props.text
            let newText = text.split('\n').map((item, i) => {
                if (item === "") {
                    return <br/>
                }
                return <span key={i}>{item}<br/></span>;
            });

            if (this.props.layout.type === "adresveld") {
                if (text.includes('\n')) {
                    this.props.layout.link = "https://www.google.com/maps/place/" + text.replace("\n", ' ')
                } else {
                    this.props.layout.link = "https://www.google.com/maps/place/" + text
                }
            }
            if (this.props.link && (this.props.link.startsWith("mailto") || this.props.link.startsWith("tel"))) {
                textTag = <a href={this.props.link}
                             style={textStyle} target={"_parent"}>{newText} </a>;
            } else if (this.props.layout.link) {
                textTag = <a href={this.props.layout.link} target={'_blank'} rel="noopener noreferrer"
                             style={textStyle}>{newText}</a>;
            } else {
                textTag = <a href={this.props.link} target={'_blank'} rel="noopener noreferrer"
                             style={textStyle}>{newText}</a>;
            }
        } else {
            let text = this.props.text;
            if (this.props.layout.type !== 'translation' && text.includes('\n')) {
                let newText = text.split('\n').map((item, i) => {
                    if (item === "") {
                        return <br/>
                    }
                    return <p key={i}>{item}<br/></p>;
                });
                textTag = <text style={textStyle}>{newText}</text>

            } else if (this.props.layout.type === 'translation' &&false) {
                textStyle['-webkit-user-select'] = "none"
                textStyle['-moz-user-select'] = "none"
                textStyle['-ms-user-select'] = "none"
                textStyle['user-select'] = "none"
                textTag = <a href={"#/"} style={textStyle} onClick={() => {
                    this.props.link()
                }}>
                    {
                        text
                    }
                </a>
            } else {
                textTag = <p style={textStyle}>{text}</p>
            }

        }
        if (!this.props.shouldBeClickable){
            let newText = this.props.text;
            if (this.props.text) {
                newText = this.props.text.split('\n').map((item, i) => {
                    if (item === "") {
                        return <br/>
                    }
                    return <span key={i}>{item}<br/></span>;
                });
            }
            textTag = <p style={textStyle}>{newText}</p>
        }
        return (
            <div onMouseDown={this.props.onChange} style={backgroundStyle}>
                {textTag}
            </div>
        )
    }
}

export default TextLine;
