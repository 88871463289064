import {
    DELETE_UPLOAD,
    GET_ERRORS,
    GET_SHARES_BY_COMPANY,
    GET_STATS_BY_COMPANY,
    GET_STATS_BY_COMPANY_BY_BIZZERDNAME,
    GET_UPLOADS,
    GET_USER,
    GET_USERS,
    RESET_REQUEST_SUCCESSFUL,
    RESET_SUCCESSFUL,
    SET_CURRENT_USER,
    UPDATE_TUTORIAL
} from "./types";
import axios from "axios/index";
import setAuthToken from '../utils/setAuthToken';
import jwt_decode from 'jwt-decode';

//Send the login credentials to the server for checking.
export const login = (user) => dispatch => {
    axios.post(`/api/user/login`, user)
    //If the login was correct, store the user Token
        .then( res => {
            //Save to local storage
            const { token } = res.data;
            //Set token to local storage
            localStorage.setItem('jwtToken', token);
            //Set token to auth header
            setAuthToken(token);
            //Decode token to get user data
            const decoded = jwt_decode(token);
            //Set current user
            dispatch(setCurrentUser(decoded));
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: (err.response !== undefined ? err.response.data : '')
            })
        });
};
export const getOrCreateUserByEmail=(email,company_id,oid,sub)=> dispatch =>{
    axios.post(`/api/user/getuserbyemail/${email}/${company_id}/${oid}/${sub}`)
        .then( res => {
            dispatch({
                type: GET_USERS,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
            type: GET_USERS,
            payload: err.response.data
        })})
};

export const loginSSO=(user)=> dispatch =>{
    axios.post(`/api/user/loginSSO`, user)
        //If the login was correct, store the user Token
        .then( res => {
            // Save to local storage
            const { token } = res.data;
            //Set token to local storage
            localStorage.setItem('jwtToken', token);
            //Set token to auth header
            setAuthToken(token);
            //Decode token to get user data
            const decoded = jwt_decode(token);
            //Set current user
            dispatch(setCurrentUser(decoded));
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: (err.response !== undefined ? err.response.data : '')
            })
        });
};

//Set logged in User
export const setCurrentUser = (decoded) => {
    return{
        type: SET_CURRENT_USER,
        payload: decoded
    }
};

//Get current profile
export const getCurrentUser = () => dispatch => {
    axios.get('/api/user/current')
        .then( res => {
            dispatch({
                type: GET_USER,
                payload:res.data
            })
        })
        .catch( err => dispatch({
            type: GET_USER,
            payload: {}
        }));
};

export const getViewsByCompany = (bizzerdnames) =>dispatch =>{
    axios.post('/api/user/statistieken',bizzerdnames)
        .then( res => {
            dispatch({
                type: GET_STATS_BY_COMPANY,
                payload:res.data
            })
        })
        .catch( err => dispatch({
            type: GET_STATS_BY_COMPANY,
            payload: {}
        }));
};

export const getViewsByBizzerdName = (bizzerdnames) =>dispatch =>{
    axios.post('/api/user/statistieken/bybizzerdname',bizzerdnames)
        .then( res => {
            dispatch({
                type: GET_STATS_BY_COMPANY_BY_BIZZERDNAME,
                payload:res.data
            })
        })
        .catch( err => dispatch({
            type: GET_STATS_BY_COMPANY_BY_BIZZERDNAME,
            payload: {}
        }));
};

export const getSharesByCompany = (bizzerdnames) =>dispatch =>{
    axios.post('/api/user/statistieken/byshare',bizzerdnames)
        .then( res => {
            dispatch({
                type: GET_SHARES_BY_COMPANY,
                payload:res.data
            })
        })
        .catch( err => dispatch({
            type: GET_SHARES_BY_COMPANY,
            payload: {}
        }));
};

export const updateTutorial= (tutorial) => dispatch => {
    axios.post('/api/user/updatetutorial',tutorial)
        .then( res => {
            dispatch({
                type: UPDATE_TUTORIAL,
                payload:res.data
            })

        })
        .catch( err => dispatch({
            type: UPDATE_TUTORIAL,
            payload: {}
        }));
};

export const getUploads = () => dispatch => {
    axios.get('/api/user/uploads')
        .then(res => {
            dispatch({
                type: GET_UPLOADS,
                payload: res.data
            })
        })
        .catch(err => {
        })
};

export const deleteUpload = (id) => dispatch => {
    axios.get('/api/user/uploads/delete/'+id)
        .then(res => {
            dispatch({
                type: DELETE_UPLOAD,
                payload: res.data
            })
        })
        .catch(err => {
        })
};

export const resetPassword = (email) => dispatch => {
    axios.post('/api/user/request', email)
        .then(res => {
            dispatch({
                type: RESET_REQUEST_SUCCESSFUL,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        });
};

export const submitNewPassword = (passwordData) => dispatch => {
    axios.post('/api/user/newpassword', passwordData)
        .then(res => {
            dispatch({
                type: RESET_SUCCESSFUL,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        })
}

export const uploadImage = (formData) => dispatch => {
    axios
        .post(`/api/bizzerd/image`,formData, {
            headers: {
                'Content-Type' : 'multipart/form-data'
            }
        })
        .then(res => {
            dispatch({
                type: GET_UPLOADS,
                payload: res.data
            })
        })
        .catch(err => {
        });
}

//Log user out
export const logoutUser = () => dispatch => {
    //Remove token from localStorage
    localStorage.removeItem('jwtToken');
    //Remove auth header for future requests
    setAuthToken(false);
    //Set current user to {} which will set isAuthenticated to false
    dispatch(setCurrentUser({}));
};
