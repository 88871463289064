import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {getCurrentUser} from "../../actions/userActions";
import {setBizzerdForPerson} from "../../actions/personActions";
import {getBizzerds} from "../../actions/companyActions";
import DisplayBizzerd from "../bizzerddisplay/DisplayBizzerd";

class SelectDesign extends Component{
    componentDidMount(){
        this.props.getCurrentUser();
    }
    componentWillReceiveProps(nextProps){
        if(this.props.user.user !== nextProps.user.user){
            this.props.getBizzerds(nextProps.user.user.company);
        }
    }

    onSelectDesign = (event, bizzerdId) => {
        event.preventDefault();
        this.props.onSubmit(event,bizzerdId);
        //this.props.setBizzerdForPerson(this.props.match.params.id, bizzerdId);
    };

    render() {
        let designs;
        if(this.props.bizzerd.bizzerds.length > 0){
            designs = this.props.bizzerd.bizzerds.map(bizzerd => {
                return (<a key={bizzerd._id} className={'bizzerd'} href={"#/"} style={{display: "inline-block", border:
                        (bizzerd._id===this.props.person.bizzerd ? "2px solid #ff0000" : "")}}
                           onClick={(event) => {this.onSelectDesign(event, bizzerd._id)}}>
                                <DisplayBizzerd bizzerdLayout={bizzerd} shouldBeClickable={false} personContent={this.props.person} realWidth={150}/>
                        </a>)
            })
        }
        else {
            designs = (
                <div>
                    <h5>Je hebt nog geen ontwerpen aangemaakt</h5>
                    <Link to={'/dashboard/ontwerpen/kiestemplate'}>Klik hier om een nieuw ontwerp te maken</Link>
                </div>);
        }

        return(
            <div className={'bizzerd--cards-wrapper'}>
                {designs}
            </div>
        )
    }
}

SelectDesign.propTypes ={
    getCurrentUser : PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    getBizzerds: PropTypes.func.isRequired,
    bizzerd: PropTypes.object.isRequired,
    onChange: PropTypes.func
};

const mapStateToProps = (state) => ({
    user: state.users,
    bizzerd: state.bizzerd
});

export default connect(mapStateToProps, {getCurrentUser, getBizzerds, setBizzerdForPerson})(SelectDesign);