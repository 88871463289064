export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const GET_BIZZERD = 'GET_BIZZERD';
export const BIZZERD_LOADING = 'BIZZERD_LOADING';
export const BIZZERD_NOT_FOUND = 'BIZZERD_NOT_FOUND';
export const CLEAR_CURRENT_BIZZERD = 'CLEAR_CURRENT_BIZZERD';
export const GET_BIZZERDS = 'GET_BIZZERDS';
export const PHOTO_SUCCESS = 'PHOTO_SUCCESS';
export const EXCEL_SUCCESS = 'EXCEL_SUCCESS';
export const BIZZERDS_LOADING = 'BIZZERDS_LOADING';
export const DELETE_BIZZERD = 'DELETE_BIZZERD';
export const ADD_BIZZERD = 'ADD_BIZZERD';
export const BIZZERD_STORED = 'BIZZERD_STORED';
export const BIZZERD_STORING = 'BIZZERD_STORING';
export const GET_PERSON = 'GET_PERSON';
export const PERSON_LOADING = 'PERSON_LOADING';
export const GET_PERSONS = 'GET_PERSONS';
export const GET_USER = 'GET_USER';
export const GET_USERS = 'GET_USERS';
export const GET_STATS_BY_COMPANY = 'GET_STATS_BY_COMPANY';
export const GET_STATS_BY_COMPANY_BY_BIZZERDNAME = 'GET_STATS_BY_COMPANY_BY_BIZZERDNAME';
export const GET_SHARES_BY_COMPANY = 'GET_SHARES_BY_COMPANY';
export const USER_LOADING = 'USER_LOADING';
export const GET_COMPANY = "GET_COMPANY";
export const GET_TEMPLATES = "GET_TEMPLATES";
export const INVITE_SENT = "INVITE_SENT";
export const LINK_GENERATED = "LINK_GENERATED";
export const EMAIL_SUBMITTED = "EMAIL_SUBMITTED";
export const EMAIL_CONFIRMED = "EMAIL_CONFIRMED";
export const UPLOAD_LOADING = "UPLOAD_LOADING";
export const GET_PAYMENT_STATUS = "GET_PAYMENT_STATUS";
export const GET_PAYMENT_RESULT = "GET_PAYMENT_RESULT";
export const GET_PAYMENT = "GET_PAYMENT";
export const UPDATE_TUTORIAL = "UPDATE_TUTORIAL";
export const GET_PAYMENTS = "GET_PAYMENTS";
export const GET_UPLOADS = "GET_UPLOADS";
export const DELETE_UPLOAD = "DELETE_UPLOAD";
export const RESET_REQUEST_SUCCESSFUL = "RESET_REQUEST_SUCCESSFUL";
export const RESET_SUCCESSFUL = "RESET_SUCCESSFUL";
export const POST_STAT = "POST_STAT";
export const ADD_TRANSLATION = "ADD_TRANSLATION";
export const GET_TRANSLATION = "GET_TRANSLATION";
export const UPDATE_TRANSLATION = "UPDATE_TRANSLATION";
export const GET_VCARD = "GET_VCARD";
