import {getPos} from "../../Utils";


export function onStartRotation(state, props){
    return {
        ...state,
        actionstatus:{
            dragging: false,
            resizing: false,
            rotating: true
        }
    }
}

export function onRotation(state, props, event){
    const newState = state;
    //Calculate rotation
    const startingPosition = {
        x: state.item[0].startingPosition.x + state.item[0].startingPosition.width/2,
        y: state.item[0].startingPosition.y - 10
    };
    const eventPosition = getPos(document.getElementById('main'),
        document.getElementById('background'),event.pageX,event.pageY);
    const rotationPoint = {
        x: state.item[0].startingPosition.x + state.item[0].startingPosition.width/2,
        y: state.item[0].startingPosition.y + state.item[0].startingPosition.height/2
    }
    const rotatingValues = (startingPosition.x - eventPosition.x)/(startingPosition.y - rotationPoint.y);
    let rotation = Math.atan(rotatingValues) * (180 / Math.PI);
    if(eventPosition.y-rotationPoint.y > 0){
        rotation = 180 - rotation;
    }
    newState.bizzerd[state.item[0].type][state.item[0].name].rotation = rotation;
    return newState;
}