import React, {Component} from 'react';
import Sidebar from "../layout/Sidebar";
import {Link, Route, Switch} from 'react-router-dom';
import Designtool from "../designtool/tool/Designtool";
import Colleagues from "../colleagues/Colleagues";
import ColleagueOverview from "../colleagues/ColleagueOverview";
import AddColleague from "../colleagues/AddColleague";
import DisplayBizzerd from "../bizzerddisplay/DisplayBizzerd";
import ChooseDesign from "../designtool/selectdesign/ChooseDesign";
import SelectDesign from "../colleagues/SelectDesign";
import InviteColleagues from "../colleagues/InviteColleagues";
import GenerateLink from "../colleagues/GenerateLink";
import ChooseTemplate from "../designtool/selectdesign/ChooseTemplate";
import CompanySettings from "../settings/CompanySettings";
import PaymentSettings from "../settings/PaymentSettings";
import Statistics from "../statistics/Statistics";
import PermissionSettings from "../settings/PermissionSettings";
import Invoice from "../settings/Invoice";
import RightsError from './../layout/RightsError';
import {logoutUser} from "../../actions/userActions";
import {connect} from 'react-redux';
import Koppeling from "../crmkoppeling/Koppeling";
import Excellijst from "../colleagues/Excellijst";
import {ErrorMessage} from "../common/AuthErrorComponent";
import {getCurrentCompany} from "../../actions/companyActions";
import * as ReactGA from "react-ga";


function updateGA() {
    if (!window.location.href.includes("rijksmuseum")) {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }
}

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.auth.user
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.user !== this.state.user) {
            this.setState({
                user: nextProps.auth.user
            });
        }
        if (nextProps.company && nextProps.company.company) {
            this.setState({company: nextProps.company.company})
        }
    }

    componentDidMount() {
        this.props.getCurrentCompany()
    }

    onLogoutClick = (e) => {
        e.preventDefault();
        this.props.logoutUser();
    };

    render() {
        updateGA();
        let modal;
        if (this.state.user.inactive) {
            modal = (
                <div className={'modal--mask-black'}>
                    <div className="modal">
                        <h2>Je bizzerd is inactief</h2>
                        <p>Om je bizzerd te blijven gebruiken moet je eerst betaalgegevens opgeven.</p>
                        <Link to={'/payment'} className={'button button--primary'}>Betalen</Link>
                        <a onClick={this.onLogoutClick} href={"#/"} className={'button button--text'}>Uitloggen</a>
                    </div>
                </div>)
        }
        let routes;
        if (this.state.user.superuser !== undefined) {
            if (this.state.user.superuser) {
                routes = (
                    <div className={'dashboard--wrapper'} id={'dashboard'}>
                        {modal}
                        <Sidebar page={window.location.href} superuser={true}/>
                        <Switch>
                            <Route path={`${this.props.match.path}/selectdesign/:id?`} component={SelectDesign}/>
                            <Route path={`${this.props.match.path}/collegas/`} exact component={Colleagues}/>
                            <Route path={`${this.props.match.path}/collegas/importeren`} exact component={Excellijst}/>
                            <Route path={`${this.props.match.path}/collegas/overzicht`} component={ColleagueOverview}/>
                            <Route path={`${this.props.match.path}/collegas/toevoegen/:type?/:value1?/:value2?`}
                                   component={AddColleague}/>
                            <Route path={`${this.props.match.path}/collegas/wijzigen/:type?/:value1?`}
                                   component={AddColleague}/>
                            <Route path={`${this.props.match.path}/collegas/uitnodigen`} component={InviteColleagues}/>
                            <Route path={`${this.props.match.path}/collegas/registratielink`} component={GenerateLink}/>
                            <Route path={`${this.props.match.path}/ontwerpen/kiesdesign`} component={ChooseDesign}/>
                            <Route path={`${this.props.match.path}/ontwerpen/kiestemplate`} component={ChooseTemplate}/>
                            <Route path={`${this.props.match.path}/ontwerpen/tool/:id?`} component={Designtool}/>
                            <Route path={`${this.props.match.path}/instellingen/bedrijf`} component={CompanySettings}/>
                            <Route path={`${this.props.match.path}/instellingen/betaal`} component={PaymentSettings}/>
                            <Route path={`${this.props.match.path}/instellingen/gebruiker`}
                                   component={PermissionSettings}/>
                            <Route path={`${this.props.match.path}/instellingen/invoice/:id`} component={Invoice}/>
                            {/*STATISTIEKEN*/}
                            <Route path={`${this.props.match.path}/statistics/`} component={Statistics}/>
                            {/*CRM KOPPELING*/}
                            <Route path={`${this.props.match.path}/koppeling/`} component={Koppeling}/>

                            <Route path={`${this.props.match.path}/display/:id?`} component={DisplayBizzerd}/>
                        </Switch>
                    </div>
                )
            } else if (this.state.company && !this.state.company.editableByEmployees) {
                routes = (<ErrorMessage
                    errors={"Het bedrijf heeft geen toestemming gegeven aan medewerkers om het kaartje aan te passen. Je bent nu wel klaar om in de app in te loggen"}/>)
            } else {
                routes = (

                    <div className={'dashboard--wrapper'} id={'dashboard'}>
                        <Sidebar page={window.location.href} superuser={false}/>
                        <Switch>
                            <Route path={`${this.props.match.path}/collegas/toevoegen/`} component={AddColleague}/>
                            <Route component={RightsError}/>
                        </Switch>
                    </div>
                )

            }
        } else {
            routes = (
                <div className={'dashboard--wrapper'} id={'dashboard'}>
                    Loading
                </div>);
        }
        return routes
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    company: state.company
});

export default connect(mapStateToProps, {logoutUser, getCurrentCompany})(Dashboard);
