import React, {Component} from 'react';

class SelectionSquare extends Component{
    render(){
        const startingPoint = {
            x: Math.min(this.props.selectionSquare.startingx, this.props.selectionSquare.currentX),
            y: Math.min(this.props.selectionSquare.startingy, this.props.selectionSquare.currentY)
        };
        const dimension = {
            width: Math.max(this.props.selectionSquare.startingx, this.props.selectionSquare.currentX) -
                Math.min(this.props.selectionSquare.startingx, this.props.selectionSquare.currentX),
            height: Math.max(this.props.selectionSquare.startingy, this.props.selectionSquare.currentY) -
                Math.min(this.props.selectionSquare.startingy, this.props.selectionSquare.currentY)
        };
        return(
            <div style={{position: 'absolute', left: startingPoint.x, top: startingPoint.y, width: dimension.width,
                height: dimension.height, backgroundColor: "rgba(0,0,0,0.2)", border: "2px solid #000", zIndex: 999}}>
            </div>
        )
    }
}
export default SelectionSquare;