import React, {Component} from 'react'
import Login from './Login';
import ResetPassword from './ResetPassword';
import NewPassword from './NewPassword';
import bizzerdLogo from '../../assets/img/bizzerd_logo.png';
import PropTypes from "prop-types";
import {logoutUser} from "../../actions/userActions";
import {connect} from "react-redux";

class LoginFrame extends Component{
    constructor(props){
        super(props);
        this.state = {
            username: '',
            password: ''
        }
    };

    componentWillReceiveProps(nextProps){
        if(nextProps.errors){
            this.setState({errors: nextProps.errors.errors});
        }
        if(nextProps.auth.isAuthenticated){
            this.props.history.push('/dashboard/collegas');
        }
    }
    componentDidMount() {
        this.props.logoutUser()
    }

    onChange = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    };

    onSubmit = (e) => {
        e.preventDefault();
        this.props.login({email: this.state.username, password: this.state.password});
    };

    render(){
        let leftDiv;
        if(this.props.match.params.reset !== undefined){
            if(this.props.match.params.reset==='request'){
                leftDiv = <ResetPassword/>
            }
            else{
                // console.log(this.props.match.params.reset);
                leftDiv = <NewPassword resetkey={this.props.match.params.reset}/>
            }
        }
        else{
            leftDiv = <Login/>
        }
        return(
            <div>
                {/* <OldWebshopHeader/> */}
                <div className="wrapper">
                    <div className="login--wrapper">
                        {leftDiv}
                        {/* <div className="login--right">
                            <h3>Welkom</h3>
                            <p>Bij onze nieuwe web-app. Log in en ga aan de slag met het maken of aanpassen van jouw bizzerd. En deel daarna jouw
                                bizzerd zo vaak als je wilt!</p>
                            <div className="card--graphic">
                                <img src={loginSvg} alt="Social Media on mobile"/>
                            </div>
                        </div> */}
                    </div>
                    <div className="wrapper--footer">
                        <p>Versie: 2.0. Ondervind je problemen? Neem dan contact op met <a href="mailto:support@bizzerd.com" target="_blank" rel="noopener noreferrer">support@bizzerd.com</a>.</p>
                    </div>
                    <div className="wrapper--logo">
                        <a href="https://www.bizzerd.com" target="_blank" rel="noopener noreferrer">
                            <img src={bizzerdLogo} alt="Klik hier om naar de homepage te gaan"/>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}
LoginFrame.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(mapStateToProps, {logoutUser})(LoginFrame);