import React, {Component} from 'react'
import TextFieldGroup from '../common/TextFieldGroup';
import {Link} from 'react-router-dom'
import {resetPassword, submitNewPassword} from "../../actions/userActions";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {ErrorMessage} from "../common/AuthErrorComponent";

class NewPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            password2: '',
            success: false
        }
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({errors: nextProps.errors.errors});
        }
        if(nextProps.auth){
            if(nextProps.auth.requestSuccess){
                this.setState({
                    success: true
                });
            }
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    };

    onSubmit = (e) => {
        e.preventDefault();
        this.props.submitNewPassword({password: this.state.password, password2: this.state.password2,
            key: this.props.resetkey});
    };

    render() {
        const {errors} = this.state;
        let error;
        if (errors !== undefined) {
            error = <ErrorMessage errors={errors}/>
        }
        let content;
        if(!this.props.auth.resetSuccess){
            content = (
                <div className="login--left">
                    <h1 className="login--title">Wachtwoord vergeten?</h1>
                    <p className="login--text">Vul hieronder je e-mailadres in waarmee je een nieuw wachtwoord kunt
                        instellen.</p>
                    <form onSubmit={this.onSubmit} className={'form-group'}>
                        <TextFieldGroup
                            labeltop={"Wachtwoord"}
                            placeholder={"Wachtwoord"}
                            name={'password'}
                            type={'password'}
                            value={this.state.password}
                            onChange={this.onChange}/>
                        <TextFieldGroup
                            labeltop={"Herhaal Wachtwoord"}
                            placeholder={"Wachtwoord"}
                            name={'password2'}
                            type={'password'}
                            value={this.state.password2}
                            onChange={this.onChange}/>
                        {error}
                        <div className="form-group__footer">
                            <button type="submit" className="button button--primary">
                                Verzenden
                            </button>
                        </div>
                        <div className="login__forgot-password">
                                <span>
                                    <Link to="/login">← Terug naar inloggen</Link>
                                </span>
                        </div>
                    </form>
                </div>);
        }
        else{
            content = (
                <div className="login--left">
                    <h1>Je wachtwoord is veranderd!</h1>
                    <Link to={'/login'} className="login--text">Ga terug naar de inlogpagina om je opnieuw in te loggen.</Link>
                    <div className="login__forgot-password">
                            <span>
                                <Link to="/login">← Terug naar inloggen</Link>
                            </span>
                    </div>
                </div>
            )
        }
        // console.log(this.props.resetkey);
        return content;
    }
}
NewPassword.propTypes = {
    resetPassword: PropTypes.func.isRequired,
    submitNewPassword: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    resetkey: PropTypes.string
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(mapStateToProps, {resetPassword, submitNewPassword})(NewPassword);