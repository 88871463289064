import React, {Component} from 'react';
import TableInputField from "../common/TableInputField";

class ContactTab extends Component {
    render() {

        let tel = this.props.person.tel
        let mobile = this.props.person.mobile
        let adresveld = this.props.person.adresveld
        if (this.props.translationActive) {
            tel = this.props.translation.tel
            mobile = this.props.translation.mobile
            adresveld = this.props.translation.adresveld
        }

        return (
            <div className="table--body">
                <TableInputField editMode={this.props.editMode} name={"tel"} label={"Telefoonnummer"}
                                 value={tel} onChange={this.props.onChange}/>
                <TableInputField editMode={this.props.editMode} name={"mobile"} label={"Mobielnummer"}
                                 value={mobile} onChange={this.props.onChange}/>
                <TableInputField editMode={this.props.editMode} name={"adresveld"} label={"Adres"}
                                 value={adresveld} onChange={this.props.onChange}/>
            </div>
        )
    }
}

export default ContactTab;