import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getBizzerdById} from "../../actions/bizzerdActions";
import {getPersonByName} from "../../actions/personActions";
import DisplayBizzerd from '../bizzerddisplay/DisplayBizzerd';
import PropTypes from 'prop-types';
import DisplaySharePage from "./DisplaySharePage";
import HttpsRedirect from "react-https-redirect";
import {Redirect} from "react-router-dom";

class ShowBizzerd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: '',
            bizzerd: {},
            person: {},
            width: 0,
            height: 0,
        }
    }

    componentDidMount() {
        if (this.props.match.params.company !== undefined && this.props.match.params.name !== undefined) {
            this.props.getPersonByName(this.props.match.params.company, this.props.match.params.name)
        } else {
            this.setState({
                errors: 'Persoon of bedrijf is niet gevonden'
            });
        }
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    calculateDisplayWidth = () => {
        if (this.state.bizzerd) {
            if (Object.keys(this.state.bizzerd).length === 0) {
                return 0
            } else {
                if (this.state.width < this.state.height - 300)
                    return this.state.width - 30;
                return (this.state.bizzerd.background[0].width) * this.state.height /
                    this.state.bizzerd.background[0].height - 40;
            }
        } else {
            return 0
        }
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors && nextProps.errors.location) {
            console.log(nextProps.errors.location)
            if (!nextProps.errors.location.startsWith("https://")) {
                window.location.href = "https://" + nextProps.errors.location
            } else {
                window.location.href = nextProps.errors.location
            }
        }
        if (nextProps.person.person !== this.props.person.person) {
            this.setState({
                person: nextProps.person.person
            });
            this.props.getBizzerdById(nextProps.person.person.bizzerd);
        } else if (nextProps.bizzerd.bizzerd !== this.props.bizzerd.bizzerd) {
            this.setState({
                bizzerd: nextProps.bizzerd.bizzerd
            })
        }
        if (nextProps.errors.errors) {
            this.setState({errors: nextProps.errors.errors})
        }
    }

    updateWindowDimensions = () => {
        this.setState({width: window.visualViewport.width, height: window.visualViewport.height});
    };

    render() {
        let width = this.calculateDisplayWidth();
        let page;
        if (this.state.errors) {
            width = this.state.width / 2;
            page = <h2>{this.state.errors}</h2>;
        } else if (this.props.match.params.page === undefined) {
            page = <DisplayBizzerd shouldBeClickable={true} realWidth={width} bizzerdLayout={this.state.bizzerd}
                                   personContent={this.state.person}/>;
        } else if (this.props.match.params.page === 'deel') {
            page = <DisplaySharePage realWidth={width} bizzerdLayout={this.state.bizzerd}
                                     personContent={this.state.person}/>
        }
        return (
            <div style={{display: 'block', width: width, margin: '20px auto'}}>
                {page}
            </div>
        )
    }
}

ShowBizzerd.propTypes = {
    person: PropTypes.object.isRequired,
    bizzerd: PropTypes.object.isRequired,
    getBizzerdById: PropTypes.func.isRequired,
    getPersonByName: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    person: state.persons,
    bizzerd: state.bizzerd,
    errors: state.errors
});

export default connect(mapStateToProps, {getBizzerdById, getPersonByName})(ShowBizzerd);