import React, {Component} from 'react'
import TableInputField from "../common/TableInputField";

class SocialTab extends Component{
    render(){
        let website = this.props.person.website
        let linkedin = this.props.person.linkedin
        let facebook = this.props.person.facebook
        let twitter = this.props.person.twitter
        let instagram = this.props.person.instagram
        let pinterest = this.props.person.pinterest
        if (this.props.translationActive){
            website = this.props.translation.website
            linkedin = this.props.translation.linkedin
            facebook = this.props.translation.facebook
            twitter = this.props.translation.twitter
            instagram = this.props.translation.instagram
            pinterest = this.props.translation.pinterest
        }





        return(
            <div className="table--body">
                <TableInputField editMode={this.props.editMode} name={"website"} label={"Website"} value={website} onChange={this.props.onChange}/>
                <TableInputField editMode={this.props.editMode} name={"linkedin"} label={"LinkedIn"} value={linkedin} onChange={this.props.onChange}/>
                <TableInputField editMode={this.props.editMode} name={"facebook"} label={"Facebook"} value={facebook} onChange={this.props.onChange}/>
                <TableInputField editMode={this.props.editMode} name={"twitter"} label={"Twitter"} value={twitter} onChange={this.props.onChange}/>
                <TableInputField editMode={this.props.editMode} name={"instagram"} label={"Instagram"} value={instagram} onChange={this.props.onChange}/>
                <TableInputField editMode={this.props.editMode} name={"pinterest"} label={"Pinterest"} value={pinterest} onChange={this.props.onChange}/>
            </div>
        )
    }
}

export default SocialTab;