import React, {Component} from 'react';
import SettingsHeader from "./SettingsHeader";

class PermissionSettings extends Component{
    render() {
        return (
            <div className="dashboard--main">
                <div className="dashboard--main-content">
                    <SettingsHeader/>
                    <div className="container">
                        <div className="cards--wrapper">
                            <div className="card dashboard--card element--card">
                                <div className="card--header">
                                    <p>Wijzig de rechten van je team (in ontwikkeling).</p>
                                </div>
                                <div className="card--main">
                                    <a href={'mailto:contact@bizzerd.com?subject=Gebruikersrechten%20wijzigen&body=Beste%20bizzerd-team'} target={'_blank'} rel="noopener noreferrer"
                                       className={'button button--text'}>
                                        Wil je gebruikersrechten wijzigen, neem dan contact met ons.</a>
                                </div>
                                <div className="card--footer">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PermissionSettings;
