import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {Provider} from 'react-redux';

import Register from './components/auth/Register.js'
import Signup from './components/auth/Signup'
import Dashboard from "./components/dashboard/Dashboard";
import jwt_decode from 'jwt-decode'
import './assets/scss/styles.scss';
import store from './store';
import CreateCompany from "./components/auth/CreateCompany";
import {logoutUser, setCurrentUser} from "./actions/userActions";
import setAuthToken from "./utils/setAuthToken";
import PrivateRoute from "./components/common/PrivateRoute"
import WaitForConfirmation from "./components/auth/WaitForConfirmation";
import TermsOfService from "./components/auth/TermsOfService";
import CompanyURL from "./components/auth/CompanyURL";
import EnterRegistrationKey from "./components/auth/EnterRegistrationKey";
import ShowBizzerd from "./components/bizzerddisplay/ShowBizzerd";
import Payment from "./components/auth/Payment";
import PaymentResult from "./components/auth/PaymentResult";
import LoginFrame from "./components/auth/LoginFrame";
import IconSetDisplay from './components/layout/IconsetDisplay'
import PaymentInfo from "./components/auth/PaymentInfo";
import AddPerson from "./components/auth/AddPerson";
import HttpsRedirect from 'react-https-redirect'
import SsoAuth from "./components/auth/SsoAuth";
import * as ReactGA from "react-ga";

if (!window.location.href.includes("rijksmuseum")) {
    ReactGA.initialize('UA-73972616-6');
}

// Setup GA
function updateGA() {
    ReactGA.pageview(window.location.pathname + window.location.search);
}

if (RegExp("(http|https):\\/\\/app2.bizzerd\\.com($|\\/$)").test(window.location.href) ||
    // eslint-disable-next-line
    RegExp("(http|https):\/\/localhost:3000($|\/$)").test(window.location.href)) {
    window.location.href = window.location.href + 'login';
}

//Check for token
if (localStorage.jwtToken) {
    //Set auth token header Auth
    setAuthToken(localStorage.jwtToken);
    //Decode
    const decoded = jwt_decode(localStorage.jwtToken);
    //Set user and isAuthenticated
    store.dispatch(setCurrentUser(decoded));

    //Check for expired token
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
        //Logout user
        store.dispatch(logoutUser());
        // Redirect to login
        if (!window.location.href.includes("/show/")) {
            window.location.href = '/login';
        }
    }
}


class App extends Component {

    constructor(props) {
        super(props);
    }


    render() {
        if (!window.location.href.includes("rijksmuseum")) {
            updateGA();
        }
        return (
            <HttpsRedirect>
                <Provider store={store}>
                    <Router>
                        <div className="App">
                            <Switch>
                                <Route exact path={'/login/:reset?'} component={LoginFrame}/>
                                <Route exact path={'/ssoauth'} component={SsoAuth}/>
                                <Route exact path={'/iconsets'} component={IconSetDisplay}/>
                                <Route exact path={'/waitforconfirmation'} component={WaitForConfirmation}/>
                                <Route exact path={'/register/:key?'} component={Register}/>
                                <Route exact path={'/registrationkey/:email'} component={EnterRegistrationKey}/>
                                <Route path={'/signup/:key?'} component={Signup}/>
                                <Route path={'/show/:company/:name/:page?'} component={ShowBizzerd}/>
                                <PrivateRoute exact path={'/termsofservice'} component={TermsOfService}/>
                                <PrivateRoute exact path={'/createcompany'} component={CreateCompany}/>
                                <PrivateRoute exact path={'/companyurl'} component={CompanyURL}/>
                                <PrivateRoute exact path={'/addperson/:type?'} component={AddPerson}/>
                                <PrivateRoute exact path={'/paymentinfo'} component={PaymentInfo}/>
                                <PrivateRoute exact path={'/payment'} component={Payment}/>
                                <PrivateRoute exact path={'/payment/result/:payload?'} component={PaymentResult}/>
                                <PrivateRoute path={'/dashboard'} component={Dashboard}/>
                            </Switch>
                        </div>
                    </Router>
                </Provider>
            </HttpsRedirect>
        );
    }
}

export default App;
