import React, {Component} from 'react';
import ShapeTypes from "../../designtool/tool/functions/ShapeTypes";
import {Circle, Ellipse, Rectangle, Triangle} from 'react-shapes';

class Shape extends Component {
    render() {
        const divStyle = {
            position: 'absolute',
            left: this.props.resize(this.props.layout.left) + 'px',
            top: this.props.resize(this.props.layout.top) + 'px',
            zIndex: this.props.layout.zindex
        };
        if (this.props.layout.rotation !== undefined) {
            divStyle.transform = "rotate(" + this.props.layout.rotation + "deg)"
        }
        let shape = (function (shape, layout, index, resize, photo) {

            switch (shape) {
                case ShapeTypes.RECTANGLE:
                    return (<Rectangle width={resize(layout.width)} height={resize(layout.height)}
                                       fill={{color: layout.backgroundColor, alpha: layout.alpha * 100}} id={index}/>);
                case ShapeTypes.CIRCLE:
                    return <Circle r={resize(Math.max(layout.width, layout.height) / 2)}
                                   fill={{color: layout.backgroundColor, alpha: layout.alpha * 100}}
                                   id={index}/>;
                case ShapeTypes.TRIANGLE:
                    return <Triangle width={resize(layout.width)} height={resize(layout.height)}
                                     fill={{color: layout.backgroundColor, alpha: layout.alpha * 100}} id={index}/>;
                case ShapeTypes.ELLIPSE:
                    return <Ellipse rx={resize(layout.width / 2)} ry={resize(layout.height / 2)}
                                    fill={{color: layout.backgroundColor, alpha: layout.alpha * 100}} id={index}/>;
                case ShapeTypes.PHOTO:
                    if (photo) {
                        let base64 = "data:image/png;base64, ";
                        try {
                            window.atob(photo);
                        } catch (e) {
                            base64 = "";
                        }
                        return <img src={base64 + photo} alt={"profile2"}
                                    style={{
                                        objectFit: "contain",
                                        width: resize(layout.width),
                                        height: resize(layout.height),
                                        borderRadius: layout.ronding
                                    }}/>;
                    }
                    return "";
                default:
                    return '';
            }
        })(this.props.layout.type, this.props.layout, this.props.index, this.props.resize, this.props.photo);
        return (
            <div style={divStyle}>
                {shape}
            </div>
        )
    }
}

export default Shape;
