import React, {Component} from 'react'
import vCardsJS from "vcards-js";

class Image extends Component {
    render() {
        const divStyle = {
            position: 'absolute',
            left: this.props.resize(this.props.layout.left) + 'px',
            top: this.props.resize(this.props.layout.top) + 'px',
            zIndex: this.props.layout.zindex,
            width: this.props.resize(this.props.layout.width),
            height: this.props.resize(this.props.layout.height),
            maxWidth: 'none'
        };
        if (this.props.layout.rotation !== undefined) {
            divStyle.transform = "rotate(" + this.props.layout.rotation + "deg)"
        }
        if (this.props.layout.image.includes("http://")) {
            this.props.layout.image = this.props.layout.image.replace("http://", "https://");
        }
        let link_object
        if (this.props.link) {

            if (this.props.link && this.props.link.includes("vcard", 0)) {
                // eslint-disable-next-line
                link_object= (
                    <a onClick={() => {
                        let person = this.props.person;
                        const vCard = vCardsJS();
                        // vCard.isOrganization = true;
                        vCard.firstName = person.firstname;
                        let lastname = person.fullname
                        lastname = lastname.replace(person.firstname,'')
                        lastname = lastname.trim()
                        vCard.lastName = lastname;
                        vCard.photo.attachFromUrl(person.pasfoto, 'image/png');
                        vCard.title = person.jobtitle;
                        vCard.workPhone = person.tel;
                        vCard.organization = person.companyname;
                        vCard.workAddress.label = 'Werk adres'
                        vCard.workAddress.street = person.adresveld
                        vCard.cellPhone = person.mobile;
                        vCard.workEmail = person.email;
                        vCard.socialUrls['facebook'] = person.facebook;
                        vCard.socialUrls['linkedin'] = person.linkedin;
                        vCard.socialUrls['twitter'] = person.twitter;
                        vCard.socialUrls['instagram'] = person.instagram;
                        vCard.socialUrls['pinterest'] = person.pinterest;
                        vCard.url = person.website;
                        vCard.note = window.location.href;
                        var responseString = vCard.getFormattedString()
                        // we need this replace because the latest release (2.10.0) of https://github.com/enesser/vCards-js does not yet contain commit https://github.com/enesser/vCards-js/commit/574c9ea4b77ca95b85872fe0c1e5f97e1a67be98
                        // because of this, the social profiles only get CHARSET=UTF-8 filled in on ios/macos, don't bother checking if it works on Android. Android doesn't support x-socialprofile.
                        while(responseString.includes("X-SOCIALPROFILE;CHARSET=UTF-8;")){
                            responseString = responseString.replace("X-SOCIALPROFILE;CHARSET=UTF-8;","X-SOCIALPROFILE;");
                        }

                        const url = window.URL.createObjectURL(new Blob([responseString],{type:'text/x-vcard',name:person.fullname+".vcf"}));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', person.fullname+ `.vcf`);
                        // 3. Append to html page
                        document.body.appendChild(link);
                        // 4. Force download
                        link.click();
                        // 5. Clean up and remove the link
                        link.parentNode.removeChild(link);
                    }} ><img alt={"loading"} src={this.props.layout.image} style={divStyle}/>
                    </a>
                );
            }
            else if(this.props.layout.link === "delen"){
                link_object= (
                    <a href={this.props.link}>
                        <img alt={"loading"} src={this.props.layout.image} id={this.props.id} style={divStyle}/>
                    </a>
                )
            }
            else {
                link_object= (
                    <a target="_blank" rel="noopener noreferrer" href={this.props.link}>
                        <img src={this.props.layout.image} id={this.props.id} style={divStyle} alt={"loading"}/>
                    </a>
                )
            }
        } else {
            link_object= (
                <img alt={"loading"} src={this.props.layout.image} id={this.props.id} style={divStyle}/>
            )
        }
        if (!this.props.shouldBeClickable){
            link_object= (
                <img alt={"loading"} src={this.props.layout.image} id={this.props.id} style={divStyle}/>
            )
        }
        return link_object
    }
}

export default Image;
