import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {connect} from 'react-redux';
import bizzerdLogo from '../../assets/img/bizzerd_logo.png'
import {sendUpdate} from "../../actions/registerActions";


class PaymentInfo extends Component{
    constructor(props){
        super(props);
        this.state = {
            payment: {},
            id: ''
        };
    }

    componentDidMount(){
        //url.pop();
        //this.props.initiatePaymentSession(url.join('/'), window.location.href + '/complete', '0,5')
        this.props.sendUpdate();
        if(this.props.auth.id !== undefined){
            this.setState({
                id: this.props.auth.id,
                email: this.props.auth.email
            })
        }
    }

    render(){
        return(
            <div className="wrapper">
                <div className="card">
                    <h2>Nu of later betalen</h2>
                    <p>Bizzerd is de eerste maand gratis daarna betaal je het normale tarief per gebruiker per maand (excl. maandelijkse transactiekosten &#8364;0,25 en BTW).<br/><br/>Dit tarief is te vinden op <a href="https://www.bizzerd.com/pricing" target="_blank" rel="noopener noreferrer">bizzerd.com/pricing</a><br/><br/> Heb je na de eerste maand nog geen betaalgegevens doorgegeven? Dan wordt je kaartje automatisch op inactief gezet totdat een betaling voltooid is. Wil je dit voorkomen? Geef dan gelijk je betaalgegevens door.<br/></p>
                    <p></p>
                    <Link to={'/dashboard/ontwerpen/kiestemplate'} className={'button button--primary'}>1 maand gratis</Link>
                    <Link to={'/payment'}  className={'button button--text'}>Nu betalen</Link>

                </div>
                <div className="wrapper--footer">
                    <p>Versie: 2.0. Ondervind je problemen? Neem dan contact op met <a href="mailto:support@bizzerd.com" target="_blank" rel="noopener noreferrer">support@bizzerd.com</a>.</p>
                </div>
                <div className="wrapper--logo">
                    <a href="https://www.bizzerd.com" target="_blank" rel="noopener noreferrer">
                        <img src={bizzerdLogo} alt="Klik hier om naar de homepage te gaan"/>
                    </a>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    auth: state.auth
});
export default connect(mapStateToProps, {sendUpdate})(PaymentInfo);
