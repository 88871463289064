import React, {Component} from 'react';
import isEmpty from "../../validation/is-empty";
import {emailShare} from '../../actions/bizzerdActions';
import {connect} from 'react-redux';
import QRCode from 'qrcode.react';
import backButton from '../../assets/img/Arrow.png'
import mailImg from '../../assets/img/mail.svg'
import messageImg from '../../assets/img/SMS.svg'
import '../../assets/scss/styles.scss'

class DisplaySharePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            name: '',
            subject: '',
            message: '',
            opened: -1
        };
    }

    resize = (value) => {
        let realWidth = 640;
        if (this.props.realWidth !== undefined) {
            realWidth = this.props.realWidth;
        }
        return value * realWidth / 640;
    };
    
    getSMS = () => {
        const url = this.getURL();
        let ua = this.getMobileOperatingSystem()
        // console.log(ua)
        if (ua === "Android") {
            return 'sms:?body=Bekijk%20de%20bizzerd%20van%20' + this.props.personContent.fullname + '%3A%20' + url + '%2F'
        }else{
            return 'sms:&body=Bekijk%20de%20bizzerd%20van%20' + this.props.personContent.fullname + '%3A%20' + url + '%2F'
        }

    };

    getMobileOperatingSystem = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }

        if (/android/i.test(userAgent)) {
            return "Android";
        }
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }

        return "unknown";
    }

    getURL = () => {
        const urlArray = window.location.pathname.split('/');
        urlArray.pop();
        return window.location.origin + urlArray.join('/');
    };

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    onSubmitEmail = (e) => {
        e.preventDefault();
        this.opendiv(e, 3);
        const emailObject = {
            email: this.state.email,
            name: this.state.name,
            subject: this.state.subject,
            message: this.state.message,
            link: this.getURL()
        };
        this.props.emailShare(emailObject);
    };
    opendiv = (e, div) => {
        this.setState({
            opened: (this.state.opened === div ? -1 : div)
        })
    };
    interceptClick = (e) => {
        //makes sure clicks won't go through a div
        e.stopPropagation();
    };
    render() {
        const style = {};
        const linkStyle = {
            fontSize: this.resize(24),
            margin: this.resize(10),
            height: this.resize(200),
            paddingTop: this.resize(50)
        };
        const headStyle = {
            height: this.resize(200)
        };
        const shareIcon = {
            height: this.resize(70),
            width: this.resize(70)
        }
        const shareContainer = {
            paddingTop: this.resize(10)
        }
        const headTextStyle = {
            fontSize: this.resize(40),
            bottom: 0,
            left: this.resize(20)
        }
        const homeButton = {
            position: 'absolute',
            top: this.resize(30),
            left: this.resize(20),
            width: this.resize(35),
            height: this.resize(35)
        };
        const sharePopOver = {
            width: this.resize(500)
        }
        const footerStyle = {
            height: this.resize(300)
        }
        if (!isEmpty(this.props.bizzerdLayout) && this.props.bizzerdLayout.background.length > 0) {
            style.minHeight = this.resize(this.props.bizzerdLayout.background[0].height) + 'px';
            style.width = this.resize(this.props.bizzerdLayout.background[0].width) + "px";
            // style.backgroundColor = this.props.bizzerdLayout.background[0].backgroundColor;
        }
        /*
        <ShareEmail/>
                <ShareSMS/>
                <ShareWhatsapp/>
                <ShareQR/>
         */
        return (
            <div style={style} className="share--page">
                <div style={headStyle} className="share--header">
                    <a href={this.getURL()}>
                        <img alt={"back button"} src={backButton} style={homeButton} className="share--back-buton"/>
                    </a>
                    <span style={headTextStyle}>Share:</span>
                </div>
                <div style={shareContainer} className="share--container">
                    <a style={linkStyle} className="share--card" href={this.getSMS()} target={"_parent"}>
                        <img alt={"share icon"} style={shareIcon} src={messageImg}/>
                        <span>SMS</span>
                    </a>
                    <a style={linkStyle} className="share--card" onClick={(e) => this.opendiv(e, 2)} href={"#/"}>
                        <QRCode bgColor="#676767" fgColor="#ffffff" size={this.resize(70)} value={this.getURL() + "?mode=qr"} />
                        <span>QR-code</span>
                    </a>
                    <a style={linkStyle} className="share--card" onClick={(e) => this.opendiv(e, 3)} href={"#/"}>
                        <img style={shareIcon}  alt={"share icon"} src={mailImg}/>
                        <span>e-mail</span>
                    </a>
                </div>
                <div style={footerStyle} className="share--footer"/>
                <div className="share-pop-over-container" onClick={(e) => this.opendiv(e, 2)} style={{ margin: '0 auto', display: (this.state.opened === 2 ? 'block' : 'none') }}>
                    <div style={sharePopOver} onClick={e => this.interceptClick(e)} className="share-pop-over">
                        <QRCode size={this.resize(350)} value={this.getURL() + "?mode=qr"} />
                    </div>
                </div>
                <div className="share-pop-over-container" onClick={(e) => this.opendiv(e, 3)} style={{ margin: '0 auto', display: (this.state.opened === 3 ? 'block' : 'none') }}>
                    <div style={sharePopOver} onClick={e => this.interceptClick(e)} className="share-pop-over">
                        <form onSubmit={this.onSubmitEmail}>
                            <label>E-mail recipient</label>
                            <input value={this.state.email} name={"email"} onChange={this.onChange} />
                            <label>Name recipient</label>
                            <input value={this.state.name} name={"name"} onChange={this.onChange} />
                            <label>Subject</label>
                            <input value={this.state.subject} name={"subject"} onChange={this.onChange} />
                            <label>Message</label>
                            <textarea value={this.state.message} name={"message"} onChange={this.onChange}>
                            </textarea>
                            <button type={"submit"}>Send</button>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    errors: state.errors
});

export default connect(mapStateToProps, { emailShare })(DisplaySharePage);