export const shortcuts = (event, undo, redo, deleteFunction) => {
    if (event.keyCode === 90 && event.ctrlKey) {
        undo();
        return true;
    }
    else if(event.key === 'y' && event.ctrlKey){
        redo();
        return true;
    }
    else if(event.keyCode === 46){
        deleteFunction(event);
        return true
    }
    else{
        return false;
    }
};