import axios from "axios/index";
import {ADD_TRANSLATION, CLEAR_ERRORS, GET_ERRORS, GET_TRANSLATION, UPDATE_TRANSLATION,} from "./types";

export const addTranslation = (person, name, abbreviation) => dispatch => {
    axios.post(`/api/translation/addtranslation/${name}/${abbreviation}`, person)
        .then(res => {
            dispatch({
                type: ADD_TRANSLATION,
                payload: res.data
            })
        })
        .catch(err => dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        }))
};

export const getTranslation = (id) => dispatch => {
    axios.get(`/api/translation/gettranslation/${id}`)
        .then(res => {
            dispatch({
                type: GET_TRANSLATION,
                payload: res.data
            })
        })
        .catch(err => dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        }))
};

export const updateTranslation = (translation) => dispatch => {
    axios.post(`/api/translation/changetranslation`, translation)
        .then(res => {
            dispatch({
                type: UPDATE_TRANSLATION,
                payload: res.data
            })
        })
        .catch(err => dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        }))
};


//Person loading
export const clearErrors = () => {
    return {
        type: CLEAR_ERRORS
    }
};
