import axios from 'axios';
import {setBizzerdLoading} from "./bizzerdActions";
import {GET_TEMPLATES} from "./types";

export const getAllTemplates = () => dispatch => {
    dispatch(setBizzerdLoading());
    axios.get(`/api/template/all/`)
        .then( res => {
            dispatch({
                type: GET_TEMPLATES,
                payload: res.data
            })
        })
        .catch(err => dispatch({
            type: GET_TEMPLATES,
            payload: null
        }))
};