import objectExamples from "./StandardObjects";
import BizzerdTypes from "../../BizzerdTypes";
import {fromJS} from "immutable";

export function addObjectOnClick(state, props, type, link){
    const currentBizzerd = fromJS(state.bizzerd);
    const newHistory = state.history.push(currentBizzerd);
    const newArray = state.bizzerd[type].slice();
    const newObject = JSON.parse(JSON.stringify(objectExamples[type]));
    newObject.type = link;
    const length = newArray.push(newObject);
    return {
        ...state,
        history: newHistory,
        bizzerd:{
            ...state.bizzerd,
            [type] : newArray
        },
        item: [
            {
                name: length - 1,
                type: type,
                startingPosition: {
                    x: objectExamples[type].left,
                    y: objectExamples[type].top,
                    width: objectExamples[type].width,
                    height: objectExamples[type].height
                }
            }
        ]

    }
}

export function addObjectDragging(state, props, e, type) {
    e.preventDefault();
    const newArray = state.bizzerd[type].slice();
    const length = newArray.push(JSON.parse(JSON.stringify(objectExamples[type])));
    newArray[length - 1].name = length - 1;

    return {
        ...state,
        bizzerd: {
            ...state.bizzerd,
            [type]: newArray
        },
        item: [
            {
                name: length - 1,
                type: type,
                startingPosition: {
                    x: objectExamples[type].left,
                    y: objectExamples[type].top,
                    width: objectExamples[type].width,
                    height: objectExamples[type].height
                },
            }
        ]
    }
}

export function addImageOnClick(state, props, source, width, height){
    const newArray = state.bizzerd[BizzerdTypes.IMAGE].slice();
    const length = newArray.push(JSON.parse(JSON.stringify(objectExamples[BizzerdTypes.IMAGE])));
    newArray[length-1].name = length-1;
    newArray[length-1].image = source;
    newArray[length-1].width = width;
    newArray[length-1].height = height;
    return {
        ...state,
        bizzerd:{
            ...state.bizzerd,
            [BizzerdTypes.IMAGE] : newArray
        }
    }
}

export function addShapeOnClick(state, props, type){
    const newArray = state.bizzerd[BizzerdTypes.SHAPE].slice();
    const length = newArray.push(JSON.parse(JSON.stringify(objectExamples[BizzerdTypes.SHAPE]))[type]);
    newArray[length-1].name = length-1;
    return {
        ...state,
        bizzerd:{
            ...state.bizzerd,
            [BizzerdTypes.SHAPE] : newArray
        }
    }
}