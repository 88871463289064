import React, {Component} from 'react';
import {connect} from 'react-redux';
import {inviteViaEmail} from "../../actions/inviteActions";
import PropTypes from 'prop-types';
import ColleagueHeader from "./ColleagueHeader";

class InviteColleagues extends Component {
    constructor(props) {
        super(props);
        this.state={
            fields: 5,
            input: [],
            name: [],
            oldInput: [],
            success: false
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.invite.success){
            const newInput = [];
            const newNames = [];
            for(let i = 0; i < this.state.fields; i++){
                newInput.push("");
                newNames.push("");
            }
            this.setState({
                success: true,
                input: newInput,
                name: newNames,
                oldInput:this.state.input
            })
        }
    }

    addFields = (number) => {
        const newInput = this.state.input;
        const newNames = this.state.name;
        for(let i = 0; i < number; i++){
            newInput.push("");
            newNames.push("");
        }
        this.setState({
            input: newInput,
            name: newNames,
            fields: this.state.fields + number
        }, () => console.log("NEW STATE" + this.state.input));
    };

    onChangeInput = (e, index) => {
        const newInput = this.state.input.slice();
        newInput[index] = e.target.value;
        this.setState({
            success: false,
            input : newInput
        });
    };

    onChangeName = (e, index) => {
        const newNames = this.state.name.slice();
        newNames[index] = e.target.value;
        this.setState({
            name : newNames
        });
    };

    validateEmail = (email) => {
        // eslint-disable-next-line
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    onSubmit = (e) => {
        const newInputs = this.state.input.filter(input => this.validateEmail(input));
        const newNames = this.state.name.filter((name, index) => this.validateEmail(this.state.input[index]));
        this.props.inviteViaEmail(newInputs, newNames);
    };

    render(){
        const input = [];
        let success;
        for(let i = 0; i < this.state.fields; i++){
            input.push(
                <div className="invitemail--container">
                    <input name="email" value={this.state.input[i]} onChange={(e) => this.onChangeInput(e,i)}
                           placeholder="...@....com"/>
                    <input name="firstname" value={this.state.name[i]} onChange={(e) => this.onChangeName(e,i)}
                           placeholder="Optioneel"/>
                </div>)
        }
        if(this.state.success){
            const text = "Er is een uitnodiging verzonden naar " + this.state.oldInput.filter(input => input!=='').join(', ') + ".";
            success =
                (<div className="alert--succes">
                    <svg version="1.1" className="has-solid " viewBox="0 0 36 36"
                         preserveAspectRatio="xMidYMid meet" focusable="false" aria-hidden="true"
                         role="img" width="32"
                         height="32" fill="currentColor">
                        <path className="clr-i-outline clr-i-outline-path-1"
                              d="M18,2A16,16,0,1,0,34,18,16,16,0,0,0,18,2Zm0,30A14,14,0,1,1,32,18,14,14,0,0,1,18,32Z"/>
                        <path className="clr-i-outline clr-i-outline-path-2"
                              d="M28,12.1a1,1,0,0,0-1.41,0L15.49,23.15l-6-6A1,1,0,0,0,8,18.53L15.49,26,28,13.52A1,1,0,0,0,28,12.1Z"/>
                        <path className="clr-i-solid clr-i-solid-path-1"
                              d="M18,2A16,16,0,1,0,34,18,16,16,0,0,0,18,2ZM28.45,12.63,15.31,25.76,7.55,18a1.4,1.4,0,0,1,2-2l5.78,5.78L26.47,10.65a1.4,1.4,0,1,1,2,2Z"
                              style={{display:"none"}}/>
                    </svg>
                    <span className="c-alert__message">{text}</span>
                </div>)
        }
        return(
            <div className="dashboard--main">
                <div className="dashboard--main-content">
                    <ColleagueHeader/>
                    <div className="container">
                        <div className="cards--wrapper">
                            <div className="card dashboard--card element--card">
                                <div className="card--header">
                                    <p>Vul hier de e-mailadressen in van je collega's.</p>
                                </div>
                                <div className="card--main">
                                    <div className="invitemail--container">
                                        <label htmlFor="test">E-mailadres</label>
                                        <label htmlFor="test" className="label--second">Naam (optioneel)</label>
                                    </div>
                                    {input}
                                </div>
                                <div className="card--footer">
                                    <button type="" onClick={this.onSubmit} className="button button--primary">
                                        Uitnodiging verzenden
                                    </button>
                                </div>
                                {success}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
InviteColleagues.propTypes = {
    invite: PropTypes.object.isRequired,
    inviteViaEmail: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
    return ({
        invite: state.invite
    })
};

export default connect(mapStateToProps, { inviteViaEmail })(InviteColleagues);