import React, {Component} from 'react'
import TextFieldGroup from '../common/TextFieldGroup';
import {Link} from 'react-router-dom'
import {resetPassword} from "../../actions/userActions";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {ErrorMessage} from "../common/AuthErrorComponent";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            success: false
        }
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({errors: nextProps.errors.errors});
        }
        if(nextProps.auth){
            if(nextProps.auth.requestSuccess){
                this.setState({
                    success: true
                });
            }
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    };

    onSubmit = (e) => {
        e.preventDefault();
        this.props.resetPassword({email: this.state.email});
    };

    render() {
        const {errors} = this.state;
        let error;
        if (errors !== undefined) {
            error = <ErrorMessage errors={errors}/>

        }
        let content;
        if(!this.props.auth.requestSuccess){
            content = (
                <div className="login--left">
                    <h1 className="login--title">Wachtwoord vergeten?</h1>
                    <p className="login--text">Vul hieronder je e-mailadres in waarmee je een nieuw wachtwoord kunt
                        instellen.</p>
                    <form onSubmit={this.onSubmit} className={'form-group'}>
                        <TextFieldGroup
                            labeltop={"E-mailadres"}
                            placeholder={"Je e-mailadres"}
                            autofocus={"autofocus"}
                            name={'email'}
                            type={'email'}
                            value={this.state.email}
                            onChange={this.onChange}
                            error={errors}/>
                        {error}
                        <div className="form-group__footer">
                            <button type="submit" className="button button--primary">
                                Verzenden
                            </button>
                        </div>
                        <div className="login__forgot-password">
                        <span>
                            <Link to="/login">← Terug naar inloggen</Link>
                        </span>
                        </div>
                    </form>
                </div>)
        }
        else{
            content = (
                <div className="login--left">
                    <h1 className="login--title">Wachtwoord reset link is verzonden</h1>
                    <p className="login--text">Check je email voor een link om je wachtwoord aan te passen.</p>
                </div>);
        }
        return content;
    }
}
Login.propTypes = {
    resetPassword: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(mapStateToProps, {resetPassword})(Login);