import React from 'react'
import PropTypes from 'prop-types';

const TextFieldGroup = ({
        name,
        placeholder,
        value,
        label,
        labeltop,
        type,
        onChange,
        disabled,
        autofocus
    }) => {
    return (
            <div className="input-field">
                <div className="input-field__label">
                    <span>{labeltop}</span>
                </div>
                <input type={type} className={"input-field__input"}
                       placeholder={placeholder} name={name} value={value} autoFocus={autofocus} disabled={disabled} onChange={onChange}/>
            </div>
    )
};

TextFieldGroup.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string.isRequired,
    autofocus: PropTypes.string,
    label: PropTypes.string,
    labeltop: PropTypes.string,
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.string,
};

TextFieldGroup.defaultProps = {
    type: 'text'
};

export default TextFieldGroup