import React, {Component} from 'react';
import EditIcon from "./bizzerdComponents/EditIcon";
import EditTextLine from "./bizzerdComponents/EditTextLine";
import EditButton from "./bizzerdComponents/EditButton";
import EditShape from "./bizzerdComponents/EditShape";
import BizzerdTypes from "../designtool/BizzerdTypes";
import SelectionSquare from '../designtool/tool/functions/SelectionSquare';
import EditImage from "./bizzerdComponents/EditImage";


class EditBizzerd extends Component{
    isActive = (type,name) => {
        return this.props.active.filter(item => item.name===name && item.type===type).length > 0;
    };
    render() {
        let icons = this.props.bizzerd[BizzerdTypes.ICON].map((value, index) => {
            return <EditIcon id={index} key={index} index={index} onResizeStart={this.props.onResizeStart}
                             onResize={this.props.onResize} onResizeStop={this.props.onResizeStop} layout={value}
                             active={this.isActive(BizzerdTypes.ICON, index)} onChange={this.props.onSelectItem}
                             link={'https://www.linkedin.com'} onStartRotation={this.props.onStartRotation}
                             isGroup={this.props.active.length > 1} iconset={this.props.bizzerd.iconset}/>
        });
        let texts = this.props.bizzerd[BizzerdTypes.TEXT].map((value, index) => {
            return <EditTextLine person={this.props.person} id={index} key={index} index={index} onResizeStart={this.props.onResizeStart}
                                 onResize={this.props.onResize} onResizeStop={this.props.onResizeStop} layout={value}
                                 company={this.props.company}
                                 active={this.isActive(BizzerdTypes.TEXT, index)} onChange={this.props.onSelectItem}
                                 onStartRotation={this.props.onStartRotation} isGroup={this.props.active.length > 1}/>
        });
        let buttons = this.props.bizzerd[BizzerdTypes.BUTTON].map((value, index) => {
            return <EditButton id={index} key={index} index={index} onResizeStart={this.props.onResizeStart}
                               onResize={this.props.onResize} onResizeStop={this.props.onResizeStop} layout={value}
                               active={this.isActive(BizzerdTypes.BUTTON, index)} onChange={this.props.onSelectItem}
                               onStartRotation={this.props.onStartRotation} isGroup={this.props.active.length > 1}/>
        });
        let images = this.props.bizzerd[BizzerdTypes.IMAGE].map((value, index) => {
            return <EditImage id={index} key={index} index={index} onResizeStart={this.props.onResizeStart}
                              onResize={this.props.onResize} onResizeStop={this.props.onResizeStop} layout={value}
                              active={this.isActive(BizzerdTypes.IMAGE, index)} onChange={this.props.onSelectItem}
                              onStartRotation={this.props.onStartRotation} isGroup={this.props.active.length > 1}/>
        });
        let shapes = this.props.bizzerd[BizzerdTypes.SHAPE].map((value, index) => {
            return <EditShape id={index} key={index} index={index} onResizeStart={this.props.onResizeStart}
                              onResize={this.props.onResize} onResizeStop={this.props.onResizeStop} layout={value}
                              active={this.isActive(BizzerdTypes.SHAPE, index)} onChange={this.props.onSelectItem}
                              onStartRotation={this.props.onStartRotation} isGroup={this.props.active.length > 1}/>
        });
        return (
            <div className={'bizzerd'}>
                {texts}
                {icons}
                {buttons}
                {images}
                {shapes}
                { this.props.selectionSquare.dragging ? <SelectionSquare selectionSquare={this.props.selectionSquare}/> : ''}
            </div>
        )
    }
}

export default EditBizzerd
