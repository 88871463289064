import React, {Component} from 'react';
import {Circle, Ellipse, Rectangle, Triangle} from 'react-shapes';
import ShapeTypes from "../tool/functions/ShapeTypes";

class AddObjectComponent extends Component {
    render() {
        return (
            <div key={"container"} className="content--pane fade show active" id="nav-home" role="tabpanel"
                 aria-labelledby="nav-home-tab">
                <div key={"rectangle"} className={'pane--item sidebar--item'} onClick={() => {
                    this.props.addShape(ShapeTypes.RECTANGLE)
                }}>
                    <Rectangle key={'rect'} width={50} height={25} fill={{color: "#fff"}}/>
                </div>
                <div key={"circle"} className={'pane--item sidebar--item'} onClick={() => {
                    this.props.addShape(ShapeTypes.CIRCLE)
                }}>
                    <Circle r={25} fill={{color: "#fff"}}/>
                </div>
                <div key={"ellipse"} className={'pane--item sidebar--item'}>
                    <Ellipse key={"ell"} rx={25} ry={10} fill={{color: "#fff"}} onClick={() => {
                        this.props.addShape(ShapeTypes.ELLIPSE)
                    }}/>
                </div>
                <div key={"triangle"} className={'pane--item sidebar--item'} onClick={() => {
                    this.props.addShape(ShapeTypes.TRIANGLE)
                }}>
                    <Triangle key={"tri"} width={50} height={50} fill={{color: "#fff"}}/>
                </div>
                <div key={"profilepic"} className={'pane--item sidebar--item'} onClick={() => {
                    this.props.addShape(ShapeTypes.PHOTO)
                }}>
                    <div key={"inner_container"} width={50} height={50} fill={{color: "#fff"}}>Pasfoto</div>
                </div>
            </div>
        )
    }
}

export default AddObjectComponent;
