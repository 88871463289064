import React, {Component} from 'react';
import Resizable from 're-resizable'
import BizzerdTypes from "../../designtool/BizzerdTypes";
import {TextTypes} from "../../common/FieldTypes";

class EditTextLine extends Component {
    getValueFromPersonAndType = (person, type, company) => {
        switch (type) {
            case "firstname":
                return person.firstname !== "" ? person.firstname : Object.values(TextTypes).filter(element => element.type === type)[0].placeholder;

            case "lastname":
                return person.lastname !== "" ? person.lastname : Object.values(TextTypes).filter(element => element.type === type)[0].placeholder;

            case "fullname":
                return person.fullname !== "" ? person.fullname : Object.values(TextTypes).filter(element => element.type === type)[0].placeholder;

            case "email":
                return person.email !== "" ? person.email : Object.values(TextTypes).filter(element => element.type === type)[0].placeholder;

            case "tel":
                return person.tel !== "" ? person.tel : Object.values(TextTypes).filter(element => element.type === type)[0].placeholder;

            case "mobile":
                return person.mobile !== "" ? person.mobile : Object.values(TextTypes).filter(element => element.type === type)[0].placeholder;

            case "jobtitle":
                return person.jobtitle !== "" ? person.jobtitle : Object.values(TextTypes).filter(element => element.type === type)[0].placeholder;

            case "website":
                return person.website !== "" ? person.website : Object.values(TextTypes).filter(element => element.type === type)[0].placeholder;

            case "adresveld":
                return person.adresveld !== "" ? person.adresveld : Object.values(TextTypes).filter(element => element.type === type)[0].placeholder;
            case "companyname":
                return company.name !== "" ? company.name : Object.values(TextTypes).filter(element => element.type === type)[0].placeholder;
            default:
                return Object.values(TextTypes).filter(element => element.type === type)[0].placeholder;
        }
    };

    render() {
        const divStyle = {
            position: 'absolute',
            left: this.props.layout.left,
            top: this.props.layout.top,
            width: this.props.layout.width,
            height: this.props.layout.height,
            zIndex: this.props.layout.zindex,
        };
        if (this.props.active) {
            divStyle.outline = '2px solid #000000';
        }
        if (this.props.layout.rotation !== undefined) {
            divStyle.transform = "rotate(" + this.props.layout.rotation + "deg)"
        }
        const textStyle = {
            width: this.props.layout.width,
            height: this.props.layout.height,
            overflow: 'hidden',
            wordWrap: 'break-word',
            lineHeight: '1.1em',
            color: this.props.layout.color,
            fontSize: this.props.layout.fontSize,
            fontWeight: (this.props.layout.bold ? "bold" : "normal"),
            fontStyle: (this.props.layout.italic ? "italic" : "normal"),
            fontFamily: this.props.layout.font,
            textAlign: this.props.layout.textAlign

        };
        let rotationelement;
        if (this.props.active && !this.props.isGroup) {
            rotationelement = <div onMouseDown={() => {
                this.props.onStartRotation()
            }}
                                   style={{
                                       height: '4px', width: '4px', top: '-10px', left: this.props.layout.width / 2 - 2,
                                       position: 'absolute', backgroundColor: '#00ff00'
                                   }}/>
        }
        let placeholder;

        if (this.props.layout.type === 'extratext') {
            placeholder = this.props.layout.text;
        } else if (this.props.person) {
            placeholder = this.getValueFromPersonAndType(this.props.person, this.props.layout.type, this.props.company)
        } else {
            placeholder = Object.values(TextTypes).filter(element => element.type === this.props.layout.type)[0].placeholder;

        }
        if (placeholder && placeholder.includes('\n')) {
            let newText = placeholder.split('\n').map((item, i) => {
                if (item === "") {
                    return <br/>
                }
                return (<span id={this.props.id} key={i}>{item}<br/></span>);
            });

            placeholder = <span style={textStyle}>{newText}</span>
        }

        return (
            <Resizable enable={{
                top: true,
                right: true,
                bottom: true,
                left: true,
                topRight: false,
                bottomRight: false,
                bottomLeft: false,
                topLeft: false
            }}
                       onResizeStart={() =>
                           this.props.onResizeStart(this.props.index, BizzerdTypes.TEXT)}
                       onResize={(e, direction, ref, d) =>
                           this.props.onResize(e, direction, ref, d, this.props.index, BizzerdTypes.TEXT)}
                       onResizeStop={(e, direction, ref, d) =>
                           this.props.onResizeStop(e, direction, ref, d, this.props.index, BizzerdTypes.TEXT)}
                       onMouseDown={(e) => this.props.onChange(e, this.props.index, BizzerdTypes.TEXT)}
                       style={divStyle}>
                <div style={{position: 'relative'}}>
                    <p id={this.props.id} style={textStyle}>{placeholder}</p>
                    {rotationelement}
                </div>
            </Resizable>
        )
    }
}

export default EditTextLine;
