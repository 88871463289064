import React, {Component} from 'react';
import {initiatePaymentSession} from "../../actions/paymentActions";
import {connect} from 'react-redux';
import bizzerdLogo from '../../assets/img/bizzerd_logo.png'


class Payment extends Component{
    constructor(props){
        super(props);
        this.state = {
            payment: {},
            id: ''
        };
    }

    componentDidMount(){
        //url.pop();
        //this.props.initiatePaymentSession(url.join('/'), window.location.href + '/complete', '0,5')
        if(this.props.auth.id !== undefined){
            this.setState({
                id: this.props.auth.id,
                email: this.props.auth.email
            })
        }
    }

    componentWillReceiveProps(nextProps){
        // console.log(nextProps.payment);
        if(this.state.payment !== nextProps.payment){
            this.setState({
                payment: nextProps.payment
            });
        }
        if(nextProps.payment.session._links !== undefined){
            // console.log(nextProps.payment.session._links);
            window.location.replace(nextProps.payment.session._links.checkout.href);
        }
    }

    startPayment = () => {
        let email;
        if(this.state.email){
            email = this.state.email;
        }
        const description = "First Payment " + this.state.id;
        const url = window.location.href.split('/');
        const value = url.pop;
        if(value !== 'payment'){
            url.pop();
        }
        const nakedUrl = url.join('/');
        const returnUrl = nakedUrl + '/payment/result';
        const webhook = nakedUrl + '/api/payment/webhook';
        this.props.initiatePaymentSession(email, description, returnUrl, webhook);
    };

    render(){
        return(
            <div className="wrapper">
                <div className="card">
                    <h2>Wat kost bizzerd?</h2>
                    <p>Probeer bizzerd eerst een maand voor &#8364;0,01, daarna betaal je het normale tarief per gebruiker per maand (excl. maandelijkse transactiekosten &#8364;0,25 en BTW).
                        <br/><br/>
                        Dit tarief is te vinden op <a href="https://www.bizzerd.com/pricing" target="_blank" rel="noopener noreferrer">bizzerd.com/pricing</a><br/><br/>
                    </p>
                    <form className={'form-group'} onSubmit={(e) => { e.preventDefault(); this.startPayment()}}>
                        <div className={"form-group__footer"}>
                            <button className="button button--primary">Betaal</button>
                        </div>
                    </form>
                </div>
                <div className="wrapper--footer">
                    <p>Versie: 2.0. Ondervind je problemen? Neem dan contact op met <a href="mailto:support@bizzerd.com" target="_blank" rel="noopener noreferrer">support@bizzerd.com</a>.</p>                </div>
                <div className="wrapper--logo">
                    <a href="https://www.bizzerd.com" target="_blank" rel="noopener noreferrer">
                        <img src={bizzerdLogo} alt="Klik hier om naar de homepage te gaan"/>
                    </a>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    payment: state.payment,
    auth: state.auth
});

export default connect(mapStateToProps, {initiatePaymentSession})(Payment);
