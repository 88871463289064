import React from 'react';
import {FontFamily, FontSize, LinkOptions, TooltipItems} from "./ToolbarOptions";
import {SketchPicker} from 'react-color';
import {TextTypes} from "../../common/FieldTypes";
import isEmpty from "../../../validation/is-empty";
import {number} from "prop-types";

export const FontFamilyDropDown = (props) => (

    <button className="tool--button tooltip--item" onClick={(e) => {
        props.onClick(TooltipItems.FONTFAMILY);
    }}>
        <div>{isEmpty(props.font) ? "OpenSans" : props.font}</div>
        <div className="dropdown--arrow">
            <svg width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                 strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                 className="feather feather-arrow-down">
                <polyline points="19 12 12 19 5 12"/>
            </svg>
        </div>
        <div className={props.getClass(TooltipItems.FONTFAMILY)}>
            <ul>
                {FontFamily.map(font => <li key={font} className={(font === props.font ? 'active' : '')}
                                            onClick={(e) => {
                                                props.onFieldChange("font", font);
                                                e.stopPropagation()
                                            }}>{font}</li>)}
            </ul>
        </div>
    </button>
);

export const FontSizeDropDown = (props) => (
    <button className="tool--button tooltip--item" onClick={(e) => {
        props.onClick(TooltipItems.FONTSIZE);
    }}>
        <div>{props.fontSize}</div>
        <div className="dropdown--arrow">

        </div>
        <div className={props.getClass(TooltipItems.FONTSIZE)}>

            <ul>
                <li>Size: <input type={"number"} value={props.fontSize}
                                 onChange={(e) => props.onFieldChange("fontSize", e.target.value)}
                                 onClick={(e) => {
                                     e.stopPropagation()
                                 }}/></li>
            </ul>
        </div>
    </button>
);

export const TextColorButton = (props) => (
    <button className="tool--button tool--button-textcolor tooltip--item"
            onClick={(e) => {
                props.onClick(TooltipItems.FONTCOLOR);
            }}>
        <svg version="1.1" id="Laag_1" focusable="false" x="0px" y="0px" viewBox="0 0 32 25"
             style={{enableBackground: "new 0 0 32 32"}}>
            <path d="M17.3,7.9C17.1,7.3,16.6,7,16.1,7H16c-0.6,0-1.1,0.3-1.3,0.9L7.5,23.6c-0.1,0.2-0.1,0.3-0.1,0.5c0,0.5,0.4,0.9,0.9,0.9
            	c0.4,0,0.8-0.3,1-0.7l1.8-4.1h9.8l1.8,4.1c0.1,0.4,0.5,0.7,1,0.7c0.5,0,1-0.4,1-1c0-0.2-0.1-0.3-0.1-0.5L17.3,7.9z M11.9,18.4
            	L16,9.3l4.1,9.1H11.9z"/>
        </svg>
        <div className="toolbar--color graphic" style={{
            background: "repeating-linear-gradient(to right, "
                + props.color + " 0%, " + props.color + " 100%)"
        }} onClick={(e) => e.stopPropagation()}>
        </div>
        <div className={props.getClass(TooltipItems.FONTCOLOR)} onClick={(e) => e.stopPropagation()}>
            <SketchPicker color={props.color} onChangeComplete={(color) => props.onFieldChange("color",
                color.hex)} disableAlpha={true}/>
        </div>
    </button>
);

export const BoldButton = (props) => (
    <button className={"tool--button" + (props.active ? " active" : '')}
            onClick={() => props.onFieldChange("bold", !props.active)}>
        <svg version="1.1" viewBox="0 0 36 36" preserveAspectRatio="xMidYMid meet"
             focusable="false" aria-hidden="true" role="img" width="32" height="32" fill="">
            <path
                d="M22.43,17.54a4.67,4.67,0,0,0,2.8-4.37v-.06a4.43,4.43,0,0,0-1.31-3.25,7.09,7.09,0,0,0-5.13-1.73h-7A1.71,1.71,0,0,0,10,9.86V26a1.72,1.72,0,0,0,1.74,1.74h7.33c4.37,0,7.25-1.88,7.25-5.38V22.3C26.32,19.64,24.73,18.32,22.43,17.54ZM13.68,11.4h4.54c2,0,3.15.89,3.15,2.33v.06c0,1.68-1.36,2.49-3.38,2.49H13.68ZM22.37,22c0,1.59-1.31,2.43-3.46,2.43H13.68V19.62h5c2.49,0,3.69.88,3.69,2.37Z"
                className="clr-i-outline clr-i-outline-path-1"/>
        </svg>
    </button>
);

export const ItalicButton = (props) => (
    <button className={"tool--button" + (props.active ? " active" : '')}
            onClick={() => props.onFieldChange("italic", !props.active)}>
        <svg version="1.1" viewBox="0 0 36 36" preserveAspectRatio="xMidYMid meet"
             focusable="false" aria-hidden="true" role="img" width="32" height="32" fill="">
            <path
                d="M24.42,8H17.1a1.1,1.1,0,1,0,0,2.19h2.13L13.11,25.55H10.47a1.1,1.1,0,1,0,0,2.19H17.8a1.1,1.1,0,1,0,0-2.19H15.51l6.13-15.36h2.78a1.1,1.1,0,1,0,0-2.19Z"
                className="clr-i-outline clr-i-outline-path-1"/>
        </svg>
    </button>
);

export const TextAlignButton = (props) => (
    <button className={"tool--button" + (props.active ? " active" : '')}
            onClick={() => {
                let options = ["left", "center", "right"]
                let index = options.indexOf(props.textAlign)
                index += 1
                if (index === 3) {
                    index = 0
                }
                props.onFieldChange("textAlign", options[index])
            }}>
        <svg version="1.1" viewBox="0 0 36 36" preserveAspectRatio="xMidYMid meet"
             focusable="false" aria-hidden="true" role="img" width="32" height="32" fill="">
            <path d="M20.25,26H6v2.2H20.25a1.1,1.1,0,0,0,0-2.2Z"
                  className="clr-i-outline clr-i-outline-path-1"/>
            <path d="M28,20H6v2.2H28A1.1,1.1,0,0,0,28,20Z" className="clr-i-outline clr-i-outline-path-2"/>
            <path d="M22.6,15.1A1.1,1.1,0,0,0,21.5,14H6v2.2H21.5A1.1,1.1,0,0,0,22.6,15.1Z"
                  className="clr-i-outline clr-i-outline-path-3"/>
            <path d="M29.25,8H6v2.2H29.25a1.1,1.1,0,1,0,0-2.2Z"
                  className="clr-i-outline clr-i-outline-path-4"/>
        </svg>
    </button>
);

export const TextTransformButton = (props) => (
    <button className="tool--button">
        <svg version="1.1" viewBox="0 0 36 36" preserveAspectRatio="xMidYMid meet" focusable="false"
             aria-hidden="true" role="img" width="32" height="32" fill="">
            <path
                d="M12.19,8.84a1.45,1.45,0,0,0-1.4-1h-.12a1.46,1.46,0,0,0-1.42,1L1.14,26.56a1.29,1.29,0,0,0-.14.59,1,1,0,0,0,1,1,1.12,1.12,0,0,0,1.08-.77l2.08-4.65h11l2.08,4.59a1.24,1.24,0,0,0,1.12.83,1.08,1.08,0,0,0,1.08-1.08,1.64,1.64,0,0,0-.14-.57ZM6.08,20.71l4.59-10.22,4.6,10.22Z"
                className="clr-i-outline clr-i-outline-path-1"/>
            <path
                d="M32.24,14.78A6.35,6.35,0,0,0,27.6,13.2a11.36,11.36,0,0,0-4.7,1,1,1,0,0,0-.58.89,1,1,0,0,0,.94.92,1.23,1.23,0,0,0,.39-.08,8.87,8.87,0,0,1,3.72-.81c2.7,0,4.28,1.33,4.28,3.92v.5a15.29,15.29,0,0,0-4.42-.61c-3.64,0-6.14,1.61-6.14,4.64v.05c0,2.95,2.7,4.48,5.37,4.48a6.29,6.29,0,0,0,5.19-2.48V26.9a1,1,0,0,0,1,1,1,1,0,0,0,1-1.06V19A5.71,5.71,0,0,0,32.24,14.78Zm-.56,7.7c0,2.28-2.17,3.89-4.81,3.89-1.94,0-3.61-1.06-3.61-2.86v-.06c0-1.8,1.5-3,4.2-3a15.2,15.2,0,0,1,4.22.61Z"
                className="clr-i-outline clr-i-outline-path-2"/>
        </svg>
    </button>
)

export const FillColorButton = (props) => {
    let color = props.backgroundColor
    if (props.backgroundColor) {
        let rgbArray = props.backgroundColor.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
            , (m, r, g, b) => '#' + r + r + g + g + b + b)
            .substring(1).match(/.{2}/g)
            .map(x => parseInt(x, 16))
        let alpha = props.alpha ? props.alpha : 1
        console.log(props)
        // let alpha = 0.3
        color = "(" + rgbArray[0] + "," + rgbArray[1] + ',' + rgbArray[2] + ',' + alpha + ')'
    }
    return (
        <button href={"3"} className="toolbar--color tool--color-button tooltip--item" style={
            {
                background: "repeating-linear-gradient(to right, " + props.backgroundColor + " 0%, "
                    + props.backgroundColor + " 100%)", border: "1px solid #a0a0a0"
            }}
                onClick={(e) => {
                    e.preventDefault();
                    props.onClick(TooltipItems.BACKGROUNDCOLOR);
                }}>
            <div className={props.getClass(TooltipItems.BACKGROUNDCOLOR) + props.right}
                 onClick={(e) => e.stopPropagation()}>
                <SketchPicker color={"rgba" + color} onChange={(color) => {
                    console.log(color)
                    props.onFieldChange("alpha", color.rgb.a)
                    props.onFieldChange("backgroundColor", color.hex)
                }}/>
            </div>
        </button>
    )
};

export const DeleteButton = (props) => (
    <button className="tool--button" onClick={props.deleteSelected}>
        <svg version="1.1" className="has-solid " viewBox="0 0 36 36"
             preserveAspectRatio="xMidYMid meet" focusable="false" aria-hidden="true" role="img"
             width="32" height="32"
             fill="">
            <path className="clr-i-outline clr-i-outline-path-1"
                  d="M27.14,34H8.86A2.93,2.93,0,0,1,6,31V11.23H8V31a.93.93,0,0,0,.86,1H27.14A.93.93,0,0,0,28,31V11.23h2V31A2.93,2.93,0,0,1,27.14,34Z"/>
            <path className="clr-i-outline clr-i-outline-path-2"
                  d="M30.78,9H5A1,1,0,0,1,5,7H30.78a1,1,0,0,1,0,2Z"/>
            <path className="clr-i-outline clr-i-outline-path-5"
                  d="M23,5.86H21.1V4H14.9V5.86H13V4a2,2,0,0,1,1.9-2h6.2A2,2,0,0,1,23,4Z"/>
            <path className="clr-i-solid clr-i-solid-path-1"
                  d="M6,9V31a2.93,2.93,0,0,0,2.86,3H27.09A2.93,2.93,0,0,0,30,31V9Zm9,20H13V14h2Zm8,0H21V14h2Z"
                  style={{display: "none"}}/>
            <path className="clr-i-solid clr-i-solid-path-2"
                  d="M30.73,5H23V4A2,2,0,0,0,21,2h-6.2A2,2,0,0,0,13,4V5H5A1,1,0,1,0,5,7H30.73a1,1,0,0,0,0-2Z"
                  style={{display: "none"}}/>
            <rect className="clr-i-outline clr-i-outline-path-3" x="21" y="13" width="2" height="15"/>
            <rect className="clr-i-outline clr-i-outline-path-4" x="13" y="13" width="2" height="15"/>
        </svg>
    </button>
);

export const DuplicateButton = (props) => (
    <button className="tool--button" onClick={props.duplicate}>Dupliceren</button>
);


export const GroupButton = (props) => (
    <button className="tool--button" onClick={props.onGroup}>{props.isGroup ? "Degroeperen" : "Groeperen"}</button>
);

export const TypeButton = (props) => (
    <button className="tool--button tooltip--item" onClick={() => props.onClick(TooltipItems.TYPE)}>Type
        <div className={props.getClass(TooltipItems.TYPE) + " right"}>
            <ul>
                {Object.keys(TextTypes).map(type => <li key={type} style={{
                    backgroundColor: (TextTypes[type].type === props.type ?
                        "#a0a0a0" : "#fff")
                }} onClick={() => props.onFieldChange("type", TextTypes[type].type)}>
                    {type}</li>)}
            </ul>
        </div>
    </button>
);

export const LinkButton = (props) => (
    <button className="tool--button tooltip--item" onClick={() => props.onClick(TooltipItems.LINK)}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-3 -3 24 24" width="32" height="32"
             preserveAspectRatio="xMinYMin" className="jam jam-link" fill="">
            <path
                d="M3.19 9.345a.97.97 0 0 1 1.37 0 .966.966 0 0 1 0 1.367l-2.055 2.052a1.932 1.932 0 0 0 0 2.735 1.94 1.94 0 0 0 2.74 0l4.794-4.787a.966.966 0 0 0 0-1.367.966.966 0 0 1 0-1.368.97.97 0 0 1 1.37 0 2.898 2.898 0 0 1 0 4.103l-4.795 4.787a3.879 3.879 0 0 1-5.48 0 3.864 3.864 0 0 1 0-5.47L3.19 9.344zm11.62-.69a.97.97 0 0 1-1.37 0 .966.966 0 0 1 0-1.367l2.055-2.052a1.932 1.932 0 0 0 0-2.735 1.94 1.94 0 0 0-2.74 0L7.962 7.288a.966.966 0 0 0 0 1.367.966.966 0 0 1 0 1.368.97.97 0 0 1-1.37 0 2.898 2.898 0 0 1 0-4.103l4.795-4.787a3.879 3.879 0 0 1 5.48 0 3.864 3.864 0 0 1 0 5.47L14.81 8.656z"/>
        </svg>
        <div className={props.getClass(TooltipItems.LINK) + " right"}>
            <ul>
                {Object.keys(LinkOptions).map(link => <li key={link} style={{
                    backgroundColor: (LinkOptions[link] === props.link ?
                        "#a0a0a0" : "#fff")
                }} onClick={() => {
                    props.onFieldChange("link", LinkOptions[link])
                }}>
                    {link}</li>)}
                <li><input value={props.link} onChange={(e) => props.onFieldChange("link", e.target.value)}
                           onClick={(e) => {
                               e.stopPropagation()
                           }}/></li>
            </ul>
        </div>
    </button>
);

export const DotsButton = (props) => (
    <button className="tool--button tooltip--item" onClick={() => props.setActive(TooltipItems.DOTS)}>
        <svg width="18" height="18" viewBox="0 -2 24 24" fill="none"
             stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
             className="feather feather-more-horizontal">
            <circle cx="12" cy="12" r="1"></circle>
            <circle cx="19" cy="12" r="1"></circle>
            <circle cx="5" cy="12" r="1"></circle>
        </svg>
        <div className={props.getClass(TooltipItems.DOTS) + " right"}>
            <ul>
                <li><DuplicateButton duplicate={props.duplicate}/></li>
                {/*<li><RoundButton roundImage={this.props.roundImage}/></li>*/}

                <li><DeleteButton deleteSelected={props.deleteSelected}/></li>
            </ul>
        </div>
    </button>
);

export const RoundButton = (props) => (
    <button className="tool--button tooltip--item" onClick={(e) => props.onClick(TooltipItems.ROUND)}>Ronding
        <div className={props.getClass(TooltipItems.ROUND) + " right"}>
            <ul>
                <li>Ronding: <input type={"number"} value={props.ronding}
                                    onChange={(e) => props.onFieldChange("ronding", e.target.value)}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                    }}/></li>
            </ul>
        </div>
    </button>
);

export const PositionButton = (props) => (
    <button className="tool--button tooltip--item" onClick={(e) => {
        props.onClick(TooltipItems.POSITION)
    }}>Positie
        <div className={props.getClass(TooltipItems.POSITION) + " right"}>
            <ul>
                <li>Top: <input type={"number"} value={props.top}
                                onChange={(e) => props.onFieldChange("top", parseInt(e.target.value))}
                                onClick={(e) => {
                                    e.stopPropagation()
                                }}/></li>
                <li>Left: <input type={"number"} value={props.left}
                                 onChange={(e) => props.onFieldChange("left", parseInt(e.target.value))}
                                 onClick={(e) => {
                                     e.stopPropagation()
                                 }}/></li>
                <li>Width: <input type={"number"} value={props.width}
                                  onChange={(e) => props.onFieldChange("width", parseInt(e.target.value))}
                                  onClick={(e) => {
                                      e.stopPropagation()
                                  }}/></li>
                <li>Height: <input type={"number"} value={props.height}
                                   onChange={(e) => props.onFieldChange("height", parseInt(e.target.value))}
                                   onClick={(e) => {
                                       e.stopPropagation()
                                   }}/></li>
                <li><a href="#/" onClick={(e) => {
                    e.preventDefault();
                    props.onFieldChange("zindex", +1);
                    e.stopPropagation()
                }}>
                    Naar voren
                </a></li>
                <li><a href="#/" onClick={(e) => {
                    e.preventDefault();
                    props.onFieldChange("zindex", -1);
                    e.stopPropagation()
                }}>
                    Naar achteren
                </a></li>
            </ul>
        </div>
    </button>
);

export const SaveChangesButton = (props) => (
    <button className="tool--button" onClick={props.saveChanges}>Opslaan</button>
);

export const UndoButton = (props) => (
    <button onClick={props.undo} className="tool--button">
        <svg viewBox="-0.5 -2 24 24" width="32" height="32"
             preserveAspectRatio="xMinYMin" className="jam jam-undo" fill="">
            <path
                d="M5.308 7.612l1.352-.923a.981.981 0 0 1 1.372.27 1.008 1.008 0 0 1-.266 1.388l-3.277 2.237a.981.981 0 0 1-1.372-.27L.907 6.998a1.007 1.007 0 0 1 .266-1.389.981.981 0 0 1 1.372.27l.839 1.259C4.6 3.01 8.38 0 12.855 0c5.458 0 9.882 4.477 9.882 10s-4.424 10-9.882 10a.994.994 0 0 1-.988-1c0-.552.443-1 .988-1 4.366 0 7.906-3.582 7.906-8s-3.54-8-7.906-8C9.311 2 6.312 4.36 5.308 7.612z"/>
        </svg>
    </button>
);

export const RedoButton = (props) => (
    <button onClick={props.redo} className="tool--button">
        <svg viewBox="-1 -2 24 24" width="32" height="32"
             preserveAspectRatio="xMinYMin" className="jam jam-redo" fill="">
            <path
                d="M19.347 7.24l.847-1.266a.984.984 0 0 1 1.375-.259c.456.31.58.93.277 1.383L19.65 10.38a.984.984 0 0 1-1.375.259L14.97 8.393a1.002 1.002 0 0 1-.277-1.382.984.984 0 0 1 1.375-.26l1.344.915C16.428 4.386 13.42 2 9.863 2c-4.357 0-7.89 3.582-7.89 8s3.533 8 7.89 8c.545 0 .987.448.987 1s-.442 1-.987 1C4.416 20 0 15.523 0 10S4.416 0 9.863 0c4.504 0 8.302 3.06 9.484 7.24z"/>
        </svg>
    </button>
);

export const HelpButton = (props) => (
    <a href={"https://www.youtube.com/watch?v=t13kt5p7Vtc"} target={"_blank"} className="tool--button"
       rel="noopener noreferrer">
        <img src={"https://bizzerd2.bizzerd.com/uploads/question.png"} alt={"Question mark"}
             style={{width: 26, height: 26}}/>
    </a>
);

export const SaveStatus = (props) => {
    let text;
    if (props.status) {
        text = "Opgeslagen";
    } else {
        text = "Niet opgeslagen";
    }
    return (
        <button className="tool--button save--status">{text}</button>
    )
}
