import {EXCEL_SUCCESS, GET_COMPANY} from "../actions/types";

const initialState = {
    company: {},
    loading: false
};

export default function(state=initialState, action){
    switch(action.type){
        case GET_COMPANY:
            return{
                ...state,
                company: action.payload
            };
        case EXCEL_SUCCESS:
            return{
                ...state,
                data: action.payload,
                excelSuccess: true
            };
        default:
            return state;
    }
}
