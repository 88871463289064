import {Component} from 'react';
import {IconSets} from '../common/IconSets'

class IconsetDisplay extends Component{
    render(){
        let iconsets = Object.keys(IconSets()).map(iconset => {
            return Object.keys(IconSets()[iconset]).map(icon => {
                return IconSets({width:'55px', height:'55px'})[iconset][icon];
            })
        });
        return iconsets;
    }
}
export default IconsetDisplay