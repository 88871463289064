import {INVITE_SENT, LINK_GENERATED} from "../actions/types";
import isEmpty from '../validation/is-empty'

const initialState={
    success: false,
    link: ''
};

export default function(state=initialState, action) {
    switch(action.type){
        case INVITE_SENT:
            return {
                ...state,
                success: !isEmpty(action.payload)
            };
        case LINK_GENERATED:
            return {
                ...state,
                success: action.payload.success,
                link: action.payload.link
            };
        default:
            return state;
    }
}