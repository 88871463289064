import React, {Component} from 'react';

class TableInputField extends Component{
    render(){
        let {
            name,
            value,
            onChange,
            editMode,
            label,
            info
        } = this.props;

        let field;
        if(editMode){
            if (!value){
                value = ""
            }
            field = <input name={name} onChange={onChange} value={value} />
            if(name==="adresveld"){
                field = <textarea name={name} onChange={onChange} value={value}/>
            }
        }
        else{
            field = <dd className={!value ? 'table--placeholder' : ''}>{value ? value : "Niet ingevuld" }</dd>
        }
        let labelElement;
        if(info){
            labelElement = <span data-wenk-length="fit" data-wenk={info}><dt>{label}</dt></span>
        }
        else{
            labelElement = <dt>{label}</dt>
        }

        return(
            <dl>
                {labelElement}
                {field}
            </dl>
        )
    }
}

export default TableInputField;