import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import bizzerdLogo from '../../assets/img/bizzerd_logo.png'

import {logoutUser} from '../../actions/userActions'

class Sidebar extends Component{
    onLogoutClick = (e) => {
        e.preventDefault();
        this.props.logoutUser();
    };
    render(){
        let content;
        if(this.props.superuser){
            content =
                (<div className="dashboard--sidebar">
                    <Link to={'/dashboard/collegas'} className="sidebar--logo">
                        <img src={bizzerdLogo} alt="Klik hier om naar home te gaan"/>
                    </Link>
                    <Link className={this.props.page.includes('collegas') ? "sidebar--item selected" : "sidebar--item collegas"}
                          to="/dashboard/collegas">
                        <span>Visitekaartjes beheren</span>
                    </Link>
                    <Link className={this.props.page.includes('ontwerp') ? "sidebar--item selected" : "sidebar--item ontwerpjoy"}
                          to="/dashboard/ontwerpen/kiesdesign">
                        <span>Ontwerp wijzigen</span>
                    </Link>
                    {/*<Link className={this.props.page.includes('koppeling') ? "sidebar--item selected" : "sidebar--item"}*/}
                    {/*      to="/dashboard/koppeling/">*/}
                    {/*    <span>Koppeling</span>*/}
                    {/*</Link>*/}
                    <Link className={this.props.page.includes('instellingen') ? "sidebar--item selected" : "sidebar--item"}
                          to="/dashboard/instellingen/bedrijf">
                        <span>Instellingen</span>
                    </Link>
                    {/*<Link className={this.props.page.includes('statistics') ? "sidebar--item selected" : "sidebar--item"}*/}
                          {/*to="/dashboard/statistics/">*/}
                        {/*<span>Statistieken</span>*/}
                    {/*</Link>*/}

                    <div className={'sidebar--second'}>
                        <a href={'#/'} onClick={this.onLogoutClick} className="button button--primary">Uitloggen</a>
                    </div>
                </div>)
        }
        else{
            content = (
                <div className="dashboard--sidebar">
                    <Link to={'/dashboard/collegas'} className="sidebar--logo">
                        <img src={bizzerdLogo} alt="Klik hier om naar home te gaan"/>
                    </Link>
                    <Link className={this.props.page.includes('collegas') ? "sidebar--item selected" : "sidebar--item"}
                          to="/dashboard/collegas/toevoegen">
                        <span>Visitekaartjes beheren</span>
                    </Link>
                    <div className={'sidebar--second'}>
                        <a href={'#/'} onClick={this.onLogoutClick} className="button button--primary">Uitloggen</a>
                    </div>
                </div>);
        }
        return content
    }
}


Sidebar.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps, { logoutUser })(Sidebar);
