import React, {Component} from 'react'
import {connect} from 'react-redux'
import {deleteUpload, getUploads} from "../../../actions/userActions";
import PropTypes from 'prop-types'

class ItemUploads extends Component {
    constructor() {
        super();
        this.state = {
            hiddenUploads: []
        }
    }

    componentDidMount() {
        if (this.props.uploads.uploads.length === 0) {
            this.props.getUploads();
        }
    }

    deleteUpload = (id) => {
        const result = window.confirm("Weet je zeker dat je deze upload wil verwijderen?");
        if (result) {
            this.props.deleteUpload(id);
            this.props.getUploads();
            let hidden = this.state.hiddenUploads
            hidden.push(id)
            this.setState({hiddenUploads: hidden})
        }
    };

    render() {
        let uploads;
        if (this.props.uploads.uploads.length > 0) {
            this.props.uploads.uploads.reverse()
            uploads = this.props.uploads.uploads.map(upload => {
                if (!this.state.hiddenUploads.includes(upload._id))
                    return (
                        <div className="pane--item sidebar--item">

                            <img
                                onClick={() => this.props.addImageOnClick(upload.source, upload.width, upload.height)}
                                /*onMouseDown={(e) => this.props.moveNewImage(e, BizzerdTypes.IMAGE, upload.source)}*/
                                alt={"upload"} src={upload.source} style={{maxWidth: "100px", maxHeight: "100px"}}/>
                            <button style={{zIndex: 100}} className="button--hover" onClick={(e) => {
                                e.stopPropagation();
                                this.deleteUpload(upload._id)
                            }}>
                                <svg version="1.1" className="has-solid " viewBox="0 0 36 36"
                                     preserveAspectRatio="xMidYMid meet" color="#FFFFFF" focusable="false"
                                     aria-hidden="true" role="img" width="32" height="32" fill="#FFFFFF">
                                    <path className="clr-i-outline clr-i-outline-path-1"
                                          d="M27.14,34H8.86A2.93,2.93,0,0,1,6,31V11.23H8V31a.93.93,0,0,0,.86,1H27.14A.93.93,0,0,0,28,31V11.23h2V31A2.93,2.93,0,0,1,27.14,34Z"/>
                                    <path className="clr-i-outline clr-i-outline-path-2"
                                          d="M30.78,9H5A1,1,0,0,1,5,7H30.78a1,1,0,0,1,0,2Z"/>
                                    <path className="clr-i-outline clr-i-outline-path-5"
                                          d="M23,5.86H21.1V4H14.9V5.86H13V4a2,2,0,0,1,1.9-2h6.2A2,2,0,0,1,23,4Z"/>
                                    <path className="clr-i-solid clr-i-solid-path-1"
                                          d="M6,9V31a2.93,2.93,0,0,0,2.86,3H27.09A2.93,2.93,0,0,0,30,31V9Zm9,20H13V14h2Zm8,0H21V14h2Z"
                                          style={{display: 'none'}}/>
                                    <path className="clr-i-solid clr-i-solid-path-2"
                                          d="M30.73,5H23V4A2,2,0,0,0,21,2h-6.2A2,2,0,0,0,13,4V5H5A1,1,0,1,0,5,7H30.73a1,1,0,0,0,0-2Z"
                                          style={{display: 'none'}}/>
                                    <rect className="clr-i-outline clr-i-outline-path-3" x="21" y="13" width="2"
                                          height="15"/>
                                    <rect className="clr-i-outline clr-i-outline-path-4" x="13" y="13" width="2"
                                          height="15"/>
                                </svg>
                            </button>
                        </div>)
            })
        }
        return (
            <div className="content--pane fade show active" id="nav-home" role="tabpanel"
                 aria-labelledby="nav-home-tab">
                <input className="" type={'file'} name={'file'} multiple={true} onChange={this.props.onImageUpload}/>
                {uploads}
            </div>
        )
    }
}

ItemUploads.propTypes = {
    uploads: PropTypes.object.isRequired,
    getUploads: PropTypes.func.isRequired,
    deleteUpload: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    uploads: state.uploads
});

export default connect(mapStateToProps, {getUploads, deleteUpload})(ItemUploads)