import React, {Component} from 'react';

class WaitForConfirmation extends Component{
    render(){
        return(
            <div className="register">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 m-auto">
                            <h1 className="display-4 text-center">Bedankt voor het aanmaken van je bizzerd.</h1>
                            <p className="lead text-center">Open je e-mail om een wachtwoord aan te maken.</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default WaitForConfirmation;