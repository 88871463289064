import React, {Component} from 'react';
import AddObjectComponent from "./AddObjectComponent";
import AddShapesComponent from "./AddShapesComponent";
import ItemUploads from "./ItemUploads";
import IconSetComponent from "./IconSetComponent";

class ObjectSidebar extends Component{
    constructor(props){
        super(props);
        this.state={
            page: 1
        }
    }

    getClass = (page) => {
        let className = 'sidebar--item nav--tabs-button ';
        if(page - this.state.page === -1){
            className += 'up';
        }
        else if(page - this.state.page === 0){
            className += 'active';
        }
        else if(page - this.state.page === 1){
            className += 'bottom';
        }
        return className;
    };

    setPage = (e, page) => {
        e.preventDefault();
        this.setState({
            page: page
        });
    };

    render() {
        let activeField;
       /* if(this.state.page === 0){
            activeField = <ChooseTemplates selectTemplate={this.props.selectTemplate}/>
        }
        else*/ if(this.state.page === 1){
            activeField = <AddObjectComponent onOpenModal={this.props.onOpenModal}
                                              moveNewObject={this.props.moveNewObject}
                                              addObject={this.props.addObject}/>
        }
        else if(this.state.page === 2){
            activeField = <ItemUploads onImageUpload={this.props.onImageUpload} addImageOnClick={this.props.addImageOnClick}/>
        }
        else if(this.state.page === 3){
            activeField = <AddShapesComponent addShape={this.props.addShape}/>
        }
        else if(this.state.page === 4){
            activeField = <IconSetComponent setIconSet={this.props.setIconSet}/>
        }
        return (
            <div className="toolbar--right">
                <div className="right--tab-content" id="nav-tabContent">
                    {activeField}
                </div>
                <nav className="right--tab-nav">
                    <div className="nav--tabs" id="nav--tab" role="tablist">
                        {/*<a className={this.getClass(0) + " templates"} href={"#/"} onClick={(e) => this.setPage(e, 0)}>Templates</a>*/}
                        <a className={this.getClass(1)+ " objects"} href={"#/"} onClick={(e) => this.setPage(e, 1)}>Objects</a>
                        <a className={this.getClass(2)+ " uploads"} href={"#/"} onClick={(e) => this.setPage(e, 2)}>Uploads</a>
                        <a className={this.getClass(3)+ " shapes"} href={"#/"} onClick={(e) => this.setPage(e, 3)}>Shapes</a>
                        <a className={this.getClass(4)+ " iconsets"} href={"#/"} onClick={(e) => this.setPage(e, 4)}>Iconsets</a>
                        {/*<a href={"#/"} className="nav--tabs-button filler"/>*/}
                    </div>
                </nav>
            </div>
        )
    }
}

export default ObjectSidebar