import React from 'react';

export const NewWordModal = (props) =>
    {
        return (
         <div className="modal">
            <h1>Extra tekst toevoegen</h1>
            <form onSubmit={props.onSubmit}>
                <input type={'text'} onChange={props.onChange} value={props.text} name={props.name}/>
                <input type={'submit'} value={'Submit'}/>
            </form>
        </div>);
};