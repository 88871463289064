import axios from "axios/index";
import {EXCEL_SUCCESS, GET_BIZZERDS, GET_COMPANY, GET_ERRORS, GET_USER} from "./types";
import {logoutUser} from "./userActions";
import {setBizzerdLoading} from "./bizzerdActions";


//Submit the name of the new company to the server.
export const submitCompany = (company, history) => dispatch => {
    axios.post(`/api/user/createcompany`, company)
        .then( res => {
            dispatch({
                type: GET_COMPANY,
                payload: res.data.company
            });
            dispatch({
                type: GET_USER,
                payload: res.data.user
            });
            history.push('/companyurl')
        })
        .catch(err => dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        }))
};

//Submit the domain for this user, as entered in the registration process
export const submitCompanyURL = (url, id, history) => dispatch => {
    axios.post(`/api/company/${id}`,url)
        .then( res => {
            history.push('/addperson/register');
        })
        .catch( err => dispatch(
            {
                type: GET_ERRORS,
                payload:err.response.data
            }
        ))

};

export const uploadExcellijst = (file, id) => dispatch => {
    axios.post(`/api/company/excellijst/${id}`, file, {
        headers: {
            'Content-Type' : 'multipart/form-data'
        }
    })
        .then(res => {
            dispatch({
                type: EXCEL_SUCCESS,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        })
};

//Get the data from the company of the current user.
export const getCurrentCompany = () => dispatch => {
    axios.get('/api/company/current')
        .then( res => {
            dispatch({
                type: GET_COMPANY,
                payload: res.data
            });
        })
        .catch( err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        });
};

//Get the data from the company by tid.
export const getCompanyByTid = (tid,email) => dispatch => {
    axios.get('/api/company/tid/'+tid+"/"+email)
        .then( res => {
            dispatch({
                type: GET_COMPANY,
                payload: res.data
            });
        })
        .catch( err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        });
};

export const getBizzerds = () => dispatch => {
    dispatch(setBizzerdLoading());
    axios.get(`/api/bizzerd/company`)
        .then( res => {
            dispatch({
                type: GET_BIZZERDS,
                payload:res.data
            })
        })
        .catch(err => dispatch({
            type: GET_BIZZERDS,
            payload:[]
        }))
};

export const updateCompany = (company) => dispatch => {
    axios.post(`/api/company/${company._id}`, company)
        .then( res => {
            dispatch({
                type: GET_COMPANY,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err
            })
        })
};

export const deleteCompany = () => dispatch => {
    axios.delete(`/api/company`)
        .then(() => {
            dispatch(
                logoutUser()
            );
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload:err.response
            })
        })
};
