import React, {Component} from 'react'
import TextFieldGroup from '../common/TextFieldGroup';
import {submitPasswordFromKey, submitPasswordNewUser, submitTOS} from "../../actions/registerActions";
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import bizzerdLogo from '../../assets/img/bizzerd_logo.png'
import {ErrorMessage} from "../common/AuthErrorComponent";

//TODO: MAYBE ADD TOS ACCEPT HERE

class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            password: '',
            displayModal: false
        }
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({errors: nextProps.errors.errors});
        }
        if (nextProps.auth.user.password) {
            this.setState({displayModal: true});
        }
        if (nextProps.auth.isAuthenticated && !nextProps.auth.user.superuser){
            this.setState({displayModal: true});
        }
        if (nextProps.auth.user.tosaccepted) {
            if (nextProps.auth.user.company !== undefined) {
                this.props.history.push('/dashboard/collegas/toevoegen');
            } else {
                this.props.history.push('/createcompany');
            }
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    };
    onSubmit = (e) => {
        e.preventDefault();
        if (this.props.match.params.key === undefined) {
            //Submit the password for users that have already been through earlier steps.
            this.props.submitPasswordNewUser(this.state.password);
        } else {
            //Submit the password for users that only started at this point with an invite email.
            this.props.submitPasswordFromKey(this.state.password, this.props.match.params.key);
        }

    };

    onSubmitTOS = (e) => {
        e.preventDefault();
        this.props.submitTOS();
    };

    render() {
        const {errors} = this.state;
        let error;
        if (errors !== undefined) {
            error = <ErrorMessage errors={errors}/>
        }
        let modal;
        if (this.state.displayModal) {
            modal =
                <div className="modal--mask">
                    <div className="modal">
                        <h2>View our conditions</h2>
                        <p>Read our <a className={"underline"}
                                        href="https://www.bizzerd.com/wp-content/uploads/2016/02/2016_01_19-bizzerd-Alg.voorwaarden_links1.pdf">terms of use
                            </a> and <a className={"underline"}
                                                  href="https://www.bizzerd.com/wp-content/uploads/2018/01/bizzerd_Privacy_Statement.pdf">privacy
                            statement</a>.</p>
                        <button onClick={(e) => this.onSubmitTOS(e)} className="button button--primary">Accept</button>
                    </div>
                </div>;
        }
        let submitbutton = <button type="submit" className="button button--primary">
            {this.props.user.user.company !== undefined || this.props.match.params.key !== undefined
                ? "Continue" : "Naar bedrijfsnaam"} &#8594;
        </button>;
        if (this.props.user && this.props.user.user) {
            if (!this.props.user.user.superuser) {
                submitbutton = <button type="submit" className="button button--primary">
                    {this.props.user.user.company !== undefined || this.props.match.params.key !== undefined
                        ? "Continue" : "Continue"} &#8594;
                </button>;
            }
        }
        return (
            <div className="wrapper">
                <div className="card">
                    <h2>Choose your password</h2>
                    <p>Please enter a password containing 8 characters with at least 1 number and 1 letter.</p>
                    <form onSubmit={this.onSubmit} className="form-group">
                        <TextFieldGroup
                            labeltop={"Password"}
                            placeholder={"Password"}
                            type={'password'}
                            name={'password'}
                            value={this.state.password}
                            onChange={this.onChange}
                            error={errors}/>
                        <div className="form-group__footer">
                            {submitbutton}

                        </div>
                        {error}
                    </form>
                </div>
                <div className="wrapper--footer">
                    <p>Versie: 0.1.1 BETA</p>
                </div>
                <div className="wrapper--logo">
                    <a href="https://www.bizzerd.com" target="_blank" rel="noopener noreferrer">
                        <img src={bizzerdLogo} alt="Klik hier om naar de homepage te gaan"/>
                    </a>
                </div>
                {modal}
            </div>
        );
    }
}

Signup.propTypes = {
    submitPasswordNewUser: PropTypes.func.isRequired,
    submitPasswordFromKey: PropTypes.func.isRequired,
    submitTOS: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired

};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
    user: state.auth
});

export default connect(mapStateToProps, {submitPasswordNewUser, submitPasswordFromKey, submitTOS})(Signup);