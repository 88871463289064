import React, {Component} from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {getPaymentData} from "../../actions/paymentActions";
import {connect} from 'react-redux';
import bizzerdLogo from '../../assets/img/bizzerd_logo.png';
import Spinner from '../../assets/img/Spinner.svg';


class Invoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            payment: {}
        }
    }

    componentDidMount() {
        this.props.getPaymentData(this.props.match.params.id);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.payment.payment && (!this.state.payment.id || nextProps.payment._id === this.state.payment.id)) {
            this.setState({
                payment: nextProps.payment.payment
            })
        }
    }

    printDocument = () => {
        const input = document.getElementById('divToPrint');
        html2canvas(input,{scale: 1})
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                pdf.addImage(imgData, 'JPEG', 0, 0);
                // pdf.save("download.pdf");
                var out = pdf.output();
                var url = 'data:application/pdf;base64,' + btoa(out);
                const downloadLink = document.createElement("a");
                const date = new Date(this.state.payment.date);
                let date_string = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()
                const fileName = "factuur"+ date_string + ".pdf";
                downloadLink.href = url;
                downloadLink.download = fileName;
                downloadLink.click();
            })
        ;
    };

    render() {
        let content;
        if (this.state.payment && this.state.payment.date) {
            const date = new Date(this.state.payment.date);
            let number = 0
            let omschrijving = "Eerste betaling"
            if (this.state.payment.amount !== 0.25) {
                omschrijving = "Bizzerd MKB"
                number = ((this.state.payment.amount / 1.21 - 0.25) / 0.49).toFixed(0);
            }

            let price = 0.49;
            if (Math.round((date.getTime() - 1577833200000)) > 0) {
                number = this.state.payment.persons;
                if (this.state.payment.amount===0.25){
                    price = 0.00
                    number = 0
                }
                else if (number > 200) {
                    price = 0.49
                } else if (number > 100 && number <= 200) {
                    price = 0.59
                } else if (number > 30 && number <= 100) {
                    price = 0.69
                } else if (number <= 30) {
                    price = 0.79
                }
            }

            const paymentPrice = 0.25;
            if (this.state.payment._id) {
                content = (
                    <div className="dashboard--main-content">
                        <div>
                            <button  onClick={this.printDocument}>Print</button>
                        </div>
                        <div id="divToPrint" className="mt4" style={{
                            backgroundColor: '#f5f5f5',
                            width: '800px',
                            height: "842px",
                            minHeight: '297mm',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            padding: '10px',
                            fontSize: '16px',
                            position: 'relative',
                            display: "flex",
                            // justifyContent: "center",
                            alignContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                        }}>
                            <img src={bizzerdLogo} alt={"logo"}
                                 style={{width: '160px', margin: '0 auto', display: 'block'}}/>
                            <p style={{
                                position: 'relative',
                                top: '50px',
                                // left: '60px',
                                width: '200px',
                                textAlign: 'center',
                                fontWeight: 'bold'
                            }}>bizzerd B.V. | Rembrandtlaan 24 | 3723 BJ | BILTHOVEN
                                088 227 22 22 | facturen@bizzerd.com
                            </p>
                            <p style={{position: 'relative', top: '80px', fontSize: '20px'}}>FACTUUR</p>
                            <p style={{
                                position: 'relative',
                                top: '110px',
                                fontSize: '16px'
                            }}>{this.state.payment.company.name}</p>
                            <p style={{
                                position: 'relative',
                                top: '118px',
                                fontSize: '16px'
                            }}>{this.state.payment.company.paymentDetails ? this.state.payment.company.paymentDetails.address : ""}</p>
                            <p style={{
                                position: 'relative',
                                top: '126px',
                                fontSize: '16px'
                            }}>{this.state.payment.company.paymentDetails ? this.state.payment.company.paymentDetails.email : ""}</p>
                            <p style={{
                                position: 'relative',
                                top: '142px',
                                fontSize: '16px'
                            }}>Factuurnummer: {this.state.payment.orderNr}</p>
                            <p style={{
                                position: 'relative',
                                top: '150px',
                                fontSize: '16px'
                            }}>Factuurdatum: {date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()}</p>
                            <p style={{position: 'relative', top: '158px', left: '20px', fontSize: '16px'}}>Betreft:
                                Bizzerd | Het
                                digitale visitekaartje</p>
                            <hr style={{
                                position: 'relative',
                                margin: '0px',
                                top: '180px',
                                width: '500px',
                                fontSize: '10px'
                            }}/>
                            <div
                                style={{display: "flex", top: '188px', justifyContent: "space-between", width: "500px",position:"relative"}}>
                                <p style={{position: 'relative', fontSize: '16px'}}>Omschrijving</p>
                                <p style={{position: 'relative', fontSize: '16px'}}>Prijs</p>
                                <p style={{position: 'relative', fontSize: '16px'}}>Aantal</p>
                                <p style={{position: 'relative', fontSize: '16px'}}>Maand</p>
                                <p style={{position: 'relative', fontSize: '16px'}}>Totaal</p>
                            </div>
                            <hr style={{
                                position: 'relative',
                                margin: '0px',
                                top: '195px',
                                width: '500px',
                                fontSize: '10px'
                            }}/>
                            <div
                                style={{display: "flex", top: '201px', justifyContent: "space-between", width: "500px",position:"relative"}}>
                                <p style={{position: 'relative', fontSize: '16px'}}>{omschrijving}</p>
                                <p style={{position: 'relative', fontSize: '16px'}}>{price}</p>
                                <p style={{position: 'relative', fontSize: '16px'}}>{number}</p>
                                <p style={{
                                    position: 'relative',
                                    fontSize: '16px'
                                }}>{date.getMonth() + 1}</p>
                                <p style={{
                                    position: 'relative',
                                    fontSize: '16px'
                                }}>{isNaN(price * number)?0:(price*number)}</p>

                            </div>
                            <div
                                style={{display: "flex", top: '201px', justifyContent: "space-between", width: "500px",position:"relative"}}>
                            <p style={{
                                position: 'relative',
                                fontSize: '16px'
                            }}>Betaalmethode </p>
                            <p style={{
                                position: 'relative',
                                fontSize: '16px'
                            }}>{paymentPrice}</p>
                            </div>
                            <hr style={{
                                position: 'relative',
                                margin: '0px',
                                top: '300px',
                                width: '500px',
                                fontSize: '10px'
                            }}/>
                            <p style={{
                                position: 'relative',
                                top: '305px',
                                left: '190px',
                                fontSize: '16px'
                            }}>Subtotaal: {price * number + paymentPrice}</p>
                            <p style={{position: 'relative', top: '315px', left: '190px', fontSize: '16px'}}>BTW
                                21%: {((price * number + paymentPrice) * 0.21).toFixed(2)}</p>
                            <hr style={{
                                position: 'relative',
                                margin: '0px',
                                top: '330px',
                                width: '500px',
                                fontSize: '10px'
                            }}/>
                            <p style={{
                                position: 'relative',
                                top: '340px',
                                left: '190px',
                                fontSize: '16px',
                                fontWeight: "bold"
                            }}>Totaal: {this.state.payment.amount.toFixed(2)}</p>
                            <p style={{
                                position: 'relative',
                                width: '500px',
                                top: '360px',
                                textAlign: 'center',
                                fontSize: '18px',
                                fontWeight: "bold"
                            }}>Bovenstaand bedrag wordt automatisch afgeschreven.</p>
                            <p style={{
                                position: 'relative',
                                width: '500px',
                                top: '380px',
                                textAlign: 'center',
                                fontSize: '16px'
                            }}>IBAN: NL43INGB0006915443 | BIC: INGBNL2A | BTW nr: NL855341555B01 | KvK nr: 63663449</p>

                        </div>
                    </div>
                )
            }
        } else {
            content = (
                <div className="dashboard--main-content">
                    <div className="placeholder--container">
                        <h3>Visitekaartjes ophalen.</h3>
                        <div className="card--graphic">
                            <img alt={"spinnner"} src={Spinner}/>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="dashboard--main">
                {content}
            </div>
        )

    }
}

const mapStateToProps = (state) => ({
    payment: state.payment,
    errors: state.errors
});

export default connect(mapStateToProps, {getPaymentData})(Invoice);