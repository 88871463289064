import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import jwt_decode from 'jwt-decode'
import {startAuth} from "../../actions/registerActions";
import * as qs from 'query-string';
import {getCompanyByTid} from "../../actions/companyActions";
import {getOrCreateUserByEmail, loginSSO} from "../../actions/userActions";
import {ErrorMessage} from "../common/AuthErrorComponent";

class SsoAuth extends Component{
    constructor(props){
        super(props);
        this.state = {
            state_id:"1987",
            nonce: this.uuidv4(),
            id_token:undefined,
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.company&&nextProps.company.company && !this.state.company){
            this.setState({company:nextProps.company.company});
            if (!this.state.user)
                this.props.getOrCreateUserByEmail(this.state.decoded.email.toLowerCase(),nextProps.company.company._id,this.state.decoded.oid,this.state.decoded.sub)
        }
        if (nextProps.users && nextProps.users.users && !this.state.user){
            this.setState({user:nextProps.users.users});
            this.props.loginSSO(nextProps.users.users)
        }
        if(nextProps.errors){
            this.setState({
                errors: nextProps.errors,
            });
        }
        if(nextProps.auth.isAuthenticated){
            this.props.history.push('/dashboard/collegas');
        }else{
            this.setState({errors:"Er is wat mis gegaan"})
        }
    }
    componentDidMount() {
        if (window.location.href.includes('#')){
            // window.location.href = window.location.href.split('#')[0]
            let parsed_url = qs.parse(window.location.href.split('#')[1]);
            if (parsed_url.state === "1998"){
                window.location.href = 'https://app.bizzerd.com/s_save.php?sso=1&id_token=' + parsed_url.id_token
            }
            if (parsed_url.state === this.state.state_id){
                this.setState({id_token:parsed_url.id_token});
                const decoded = jwt_decode(parsed_url.id_token);
                this.setState({decoded:decoded});
                this.props.getCompanyByTid(decoded.tid,decoded.email.toLowerCase());
            }

        }
    }
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    };

    onSubmit = (event) => {

    };

    uuidv4 = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            // eslint-disable-next-line
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    };

    render(){
        const {errors} = this.state;
        let error;
        if(errors !== undefined){
            error = "It looks like SSO hasn't been setup yet for your company.\n Contact bizzerd at sso@bizzerd.com";
            // error =
            return (<div><ErrorMessage errors={error}/>
                <button onClick={()=>{
                    window.location.href = window.location.href.split('/ssoauth')[0]
                }

                }> Return to bizzerd</button></div>)
        }
        return(
            <div>
                {/*<h1>Test auth</h1>*/}
                {/*<button onClick={()=>{*/}
                {/*    window.location.href = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?' +*/}
                {/*        'client_id=f27d1da7-6f71-4ae7-bcd3-1d7f00139923&' +*/}
                {/*        'response_type=id_token&' +*/}
                {/*        'redirect_uri=https://app2.bizzerd.com/testauth&' +*/}
                {/*        'response_mode=fragment&' +*/}
                {/*        'scope=email+openid&' +*/}
                {/*        'state=' + this.state.state_id + '&' +*/}
                {/*        'nonce=' + this.state.nonce;*/}
                {/*}*/}

                {/*}> Click me</button>*/}
            </div>
        )
    }
}




SsoAuth.propTypes = {
    errors: PropTypes.object.isRequired,
    getCompanyByTid: PropTypes.func.isRequired,
    getOrCreateUserByEmail: PropTypes.func.isRequired,
    loginSSO: PropTypes.func.isRequired,
    company: PropTypes.object.isRequired,
    users: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    errors: state.errors,
    company: state.company,
    users: state.users,
    auth: state.auth,
});

export default connect(mapStateToProps, {getCompanyByTid,getOrCreateUserByEmail,loginSSO})(SsoAuth);
