import React, {Component} from 'react';
import {connect} from "react-redux";
import {checkPaymentSession} from "../../actions/paymentActions";
import credit_card from '../../assets/img/credit_card.svg';
import bizzerdLogo from '../../assets/img/bizzerd_logo.png';
import {Link} from 'react-router-dom';

class PaymentResult extends Component{
    constructor(props){
        super(props);
        this.state = {
            payment: this.props.payment,
            teller: 0
        };
    }

    componentDidMount(){
        // console.log(this.props.match.params.payload);
        this.props.checkPaymentSession(this.props.match.params.payload);
    }

    componentWillReceiveProps(nextProps){
        if(this.state.payment !== nextProps.payment){
            this.setState({payment: nextProps.payment});
        }
    }

    render(){
        let paymentResult;
        let redirectURL;
        if(this.state.payment.status !== undefined){
            if(this.state.payment.payload.status === 'paid'){
                paymentResult = (
                    <div>
                        <h2>Betaling gelukt!</h2>
                        <p>Je betaling is gelukt. De registratie is afgerond.</p>
                    </div>
                );
                redirectURL =(
                    <Link to={'/dashboard/ontwerpen/kiestemplate'} className="button button--primary">
                        Afronden
                    </Link>);
            }
            else if(this.state.payment.status==='result' && this.state.payment.payload.status !== 'paid'){
                paymentResult = (
                    <div>
                        <h2>Betaling is mislukt</h2>
                        <p>Klik hier om terug te gaan en het opnieuw te proberen.</p>
                    </div>
                );
                redirectURL = (
                    <Link to={'/payment'} className="button button--primary">
                         Terug
                    </Link>);
            }
            else{
                if( this.state.teller < 5) {
                    this.props.checkPaymentSession(this.props.match.params.payload);
                    this.setState({
                        teller: this.state.teller + 1
                    });
                }
                paymentResult = (
                    <div>
                        <h2>Betaling verwerken...</h2>
                    </div>
                )
            }
        }
        return(
            <div className="wrapper">
                <div className="card">
                    <div id={"payment-div"}>
                        {paymentResult}
                        <div className="card--graphic">
                            <img alt={"credit card"} src={credit_card}/>
                        </div>
                    </div>
                    <div className="form-group__footer">
                        {redirectURL}
                    </div>
                </div>
                <div className="wrapper--footer">
                    <p>Versie: 2.0. Ondervind je problemen? Neem dan contact op met <a href="mailto:support@bizzerd.com" target="_blank" rel="noopener noreferrer">support@bizzerd.com</a>.</p>
                </div>
                <div className="wrapper--logo">
                    <a href="https://www.bizzerd.com" target="_blank" rel="noopener noreferrer">
                        <img src={bizzerdLogo} alt="Klik hier om naar de homepage te gaan"/>
                    </a>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    payment: state.payment
});
export default connect(mapStateToProps, {checkPaymentSession})(PaymentResult);