import React, {Component} from 'react'
import TextFieldGroup from '../common/TextFieldGroup';
import {registerWithKey, submitNewUser} from "../../actions/registerActions";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import createAsset from '../../assets/img/create.svg';
import bizzerdLogo from '../../assets/img/bizzerd_logo.png';
import {ErrorMessage} from "../common/AuthErrorComponent";

class Register extends Component{
    constructor(props){
        super(props);
        this.state = {
            email: ''
        }
    };

    componentWillReceiveProps(nextProps){
        if(nextProps.errors){
            this.setState({errors: nextProps.errors.errors});
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    };

    onSubmit = (e) => {
        e.preventDefault();
        //If the user was not invited submit just the email
        if(this.props.match.params.key === undefined){
            this.props.submitNewUser({email: this.state.email}, this.props.history);
        }
        //If the user was invited: Submit email + invite code.
        else{
            this.props.registerWithKey({email:this.state.email, key: this.props.match.params.key}, this.props.history)
        }
    };

    render(){
        const {errors} = this.state;
        let error;
        if(errors !== undefined){
            error = <ErrorMessage errors={errors}/>
        }
        return(
            <div>
                {/* <OldWebshopHeader/> */}
                <div className="wrapper">
                    <div className="card">
                        <h2>Aan de slag met bizzerd</h2>
                        <p>Vul hier het e-mailadres in waarmee je jouw bizzerd-account wilt maken.</p>
                        <form onSubmit={this.onSubmit} noValidate className={'form-group'}>
                            <TextFieldGroup
                                placeholder={"E-mailadres"}
                                name={'email'}
                                value={this.state.email}
                                onChange={this.onChange}
                                error={errors}/>
                            <div className="form-group__footer">
                                <button type="submit" className="button button--primary">
                                    Bevestigen
                                </button>
                            </div>
                            {error}
                        </form>
                        <div className="card--graphic">
                            <img src={createAsset} alt="Klik hier om terug te gaan"/>
                        </div>
                    </div>
                    <div className="wrapper--footer">
                        <p>Versie: 2.0. Ondervind je problemen? Neem dan contact op met <a href="mailto:support@bizzerd.com" target="_blank" rel="noopener noreferrer">support@bizzerd.com</a>.</p>                    </div>
                    <div className="wrapper--logo">
                        <a href="https://www.bizzerd.com" target="_blank" rel="noopener noreferrer">
                            <img src={bizzerdLogo} alt="Klik hier om naar de homepage te gaan"/>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

Register.propTypes = {
    errors: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    errors: state.errors
});

export default connect(mapStateToProps, {submitNewUser, registerWithKey})(Register);