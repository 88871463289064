import BizzerdTypes from "../../BizzerdTypes";
import ShapeTypes from "./ShapeTypes";

const objectExamples = {
    [BizzerdTypes.TEXT]: {
        left: 100,
        top: 100,
        width: 200,
        height: 50,
        fontSize: 25,
        zindex:4,
        color: '#ff0000',
        text: 'Hallo, Hallo, Hallo',
        type: 'firstname'
    },
    [BizzerdTypes.ICON]: {
        left: 100,
        top: 100,
        width: 55,
        height: 55,
        zindex:4,
        image: ''
    },
    [BizzerdTypes.BUTTON]: {
        left: 100,
        top: 100,
        width: 200,
        height: 50,
        zindex:3,
        backgroundColor: '#ff0000'
    },
    [BizzerdTypes.IMAGE] : {
        left: 100,
        top: 100,
        width: 200,
        height: 50,
        zindex:2
    },
    [BizzerdTypes.SHAPE] : {
        [ShapeTypes.RECTANGLE] : {
            width:100,
            height: 100,
            fill: "#000000",
            type: ShapeTypes.RECTANGLE,
            left: 100,
            top: 100,
            zindex: 2
        },
        [ShapeTypes.CIRCLE]: {
            fill: "#000000",
            width:50,
            height:50,
            type: ShapeTypes.CIRCLE,
            left: 100,
            top: 100,
            zindex: 2
        },
        [ShapeTypes.TRIANGLE]: {
            fill: "#000000",
            width:100,
            height:100,
            type: ShapeTypes.TRIANGLE,
            left: 100,
            top: 100,
            zindex: 2
        },
        [ShapeTypes.ELLIPSE]: {
            fill: "#000000",
            width:100,
            height:50,
            type: ShapeTypes.ELLIPSE,
            left: 100,
            top: 100,
            zindex: 2
        },
        [ShapeTypes.PHOTO]: {
            width:100,
            height:50,
            type: ShapeTypes.PHOTO,
            left: 100,
            top: 100,
            zindex: 2
        }
    }
};
export default objectExamples;