import axios from "axios/index";
import {GET_ERRORS, INVITE_SENT, LINK_GENERATED} from "./types";

//Send invites to emails in emails
export const inviteViaEmail = (emails, names, bizzerdname=undefined) => dispatch => {
    axios.post(`/api/invite/emails`, {emails: emails, names: names,bizzerdName:bizzerdname})
        .then( res => {
            dispatch({
                type: INVITE_SENT,
                payload: res.data
            })
        })
        .catch(err => dispatch({
            type: GET_ERRORS,
            payload: null
        }))
};

//Generate an invite link that can be shared with expiration time exp (based on an index)
export const generateLink = (exp) => dispatch => {
    axios.post(`/api/invite/link`, {expIndex: exp})
        .then(res => {
            dispatch({
                type: LINK_GENERATED,
                payload:res.data
            })
        })
        .catch( err => dispatch({
            type: GET_ERRORS,
            payload: null
        }));
};