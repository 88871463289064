import setAuthToken from "../utils/setAuthToken";
import {setCurrentUser} from "./userActions";
import axios from "axios/index";
import {GET_ERRORS} from "./types";
import jwt_decode from "jwt-decode";


//Send the email of a new User to the server.
export const submitNewUser = (email, history) => dispatch => {
    axios.post(`/api/user/register`, email)
        .then( res => {
            //If successful, go to the registrationkey page.
            history.push(`/registrationkey/${email.email}`)
        })
        .catch(err => {
            //If unsuccessful, send errors.
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        });
};

export const submitPasswordNewUser = (password) => dispatch => {
    axios.post(`/api/user/password`, {'password':password})
        .then( res => {
            dispatch(setCurrentUser(res.data));
        })
        .catch(err => dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        }))
};

//Submit a password that was submitted from an invite email
export const submitPasswordFromKey = (password, key) => dispatch => {
    axios.post(`/api/user/password/${key}`, {'password':password})
        .then( res => {
            //Save to local storage
            const { token } = res.data;
            //Set token to local storage
            localStorage.setItem('jwtToken', token);
            //Set token to auth header
            setAuthToken(token);
            //Decode token to get user data
            const decoded = jwt_decode(token);
            dispatch(setCurrentUser(decoded));
        })
        .catch(err => dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        }))
};

//Register a new user that came from an invite link. Post has email + invite key.
export const registerWithKey = (post, history) => dispatch => {
    axios.post(`/api/user/registerwithkey`, post)
        .then(res => {
            history.push(`/registrationkey/${post.email}`)
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        })
};

//Submit the Registration Key to the server, with the emailaddress for verification.
export const submitRegistrationKey = (key, email, history) => dispatch => {
    axios.post(`/api/user/submitkey`, {key: key, email: email})
        .then(res => {
            //Save to local storage
            const { token } = res.data;
            //Set token to local storage
            localStorage.setItem('jwtToken', token);
            //Set token to auth header
            setAuthToken(token);
            //Decode token to get user data
            const decoded = jwt_decode(token);
            //Set current user
            dispatch(setCurrentUser(decoded));

            history.push('/signup')
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        })
};


export const submitTOS = () => dispatch => {
    axios.get('/api/user/accepttos')
        .then(res => {
            dispatch(setCurrentUser(res.data));
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        })
};



export const sendUpdate = () => dispatch => {
    axios.get('/api/user/sendupdate')
        .then(res => {
            dispatch(setCurrentUser(res.data));
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        })
};
