import {POST_STAT} from "../actions/types";
import isEmpty from '../validation/is-empty'

const initialState = {
    success: false,
    data: ''
};

export default function (state = initialState, action) {
    switch (action.type) {
        case POST_STAT:
            return {
                ...state,
                success: !isEmpty(action.payload)
            };
        default:
            return state;
    }
}