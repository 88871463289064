import React, {Component} from 'react';
import BizzerdTypes from "../BizzerdTypes";
import {
    BoldButton,
    DeleteButton,
    DotsButton,
    DuplicateButton,
    FillColorButton,
    FontFamilyDropDown,
    FontSizeDropDown,
    HelpButton,
    ItalicButton,
    LinkButton,
    PositionButton,
    RedoButton, RoundButton,
    SaveChangesButton,
    SaveStatus,
    TextAlignButton,
    TextColorButton,
    TextTransformButton,
    TypeButton,
    UndoButton
} from "./ToolbarItems";
import {TooltipItems} from "./ToolbarOptions";

class TopToolbar extends Component{
    constructor(props){
        super(props);
        this.state= {
            tooltipActive: {
                [TooltipItems.FONTSIZE]: false,
                [TooltipItems.FONTFAMILY]: false,
                [TooltipItems.FONTCOLOR]: false,
                [TooltipItems.BACKGROUNDCOLOR]: false,
                [TooltipItems.LINK] : false,
                [TooltipItems.TYPE] : false,
                [TooltipItems.POSITION] : true,
                [TooltipItems.DOTS] : false
            }
        }
    }

    componentWillReceiveProps(nextProps){
        if(this.props.items !== nextProps.items){
            this.setState({
                tooltipActive:{
                    [TooltipItems.FONTSIZE]: false,
                    [TooltipItems.FONTFAMILY]: false,
                    [TooltipItems.FONTCOLOR]: false,
                    [TooltipItems.BACKGROUNDCOLOR]: false,
                    [TooltipItems.LINK] : false,
                    [TooltipItems.TYPE] : false,
                    [TooltipItems.POSITION] : false,
                    [TooltipItems.DOTS] : false,
                }
            })
        }
    }

    getClass = (type) => {
        return ("tooltip" + (this.state.tooltipActive[type] ? " active": ""));
    };

    setActive = (element) => {
        // console.log(element);
        const tooltipItems = {};
        Object.values(TooltipItems).forEach((item) => {
            tooltipItems[item] = false
        });
        tooltipItems[element] = !this.state.tooltipActive[element];

        this.setState({
            tooltipActive: tooltipItems
        })
    };
    render(){
        const leftBar = (
            <div className={"toolbar--tools"}>
                <SaveChangesButton stored={this.props.stored} saveChanges={this.props.saveBizzerd}/>
                <SaveStatus status={this.props.stored}/>
                <UndoButton undo={this.props.undo}/>
                <RedoButton redo={this.props.redo}/>
                <HelpButton/>

            </div>
        )
        let centerBar;
        let rightBar;
        if(this.props.items.length === 1){
            if(this.props.items[0].type=== BizzerdTypes.TEXT){
                centerBar = (
                    <div className="toolbar--tools">
                        <FontFamilyDropDown onFieldChange={this.props.onFieldChange} font={this.props.selected[0].font}
                                            onClick={this.setActive} getClass={this.getClass}/>

                        <FontSizeDropDown  onFieldChange={this.props.onFieldChange} onClick={this.setActive}
                                           fontSize={this.props.selected[0].fontSize} getClass={this.getClass}/>
                        <TextColorButton onFieldChange={this.props.onFieldChange} color={this.props.selected[0].color}
                                         getClass={this.getClass} onClick={this.setActive}/>
                        <BoldButton active={this.props.selected[0].bold} onFieldChange={this.props.onFieldChange}/>
                        <ItalicButton active={this.props.selected[0].italic} onFieldChange={this.props.onFieldChange}/>
                        <TextAlignButton active={this.props.selected[0].textAlign} textAlign={this.props.selected[0].textAlign} onFieldChange={this.props.onFieldChange}/>
                        <TextTransformButton/>
                    </div>);
                rightBar = (
                    <div className={"toolbar--tools"}>
                        <LinkButton getClass={this.getClass} onClick={this.setActive}
                                    onFieldChange={this.props.onFieldChange} alignIndex={this.props.alignIndex} link={this.props.selected[0].link}/>
                        <TypeButton getClass={this.getClass} onClick={this.setActive}
                                    onFieldChange={this.props.onFieldChange} type={this.props.selected[0].type}/>
                        <PositionButton left={this.props.selected[0].left} top={this.props.selected[0].top}
                                        width={this.props.selected[0].width} height={this.props.selected[0].height}
                                        onFieldChange={this.props.onFieldChange} onClick={this.setActive}
                                        getClass={this.getClass}/>
                        <DotsButton getClass={this.getClass} setActive={this.setActive}
                                    onFieldChange={this.props.onFieldChange} selected={this.props.selected}
                                    duplicate={this.props.duplicate} deleteSelected={this.props.deleteSelected}/>
                    </div>
                )
            }
            else if(this.props.items[0].type === BizzerdTypes.BUTTON || this.props.items[0].type === BizzerdTypes.ICON){
                centerBar = (
                    <div className={"toolbar--tools"}>
                        <FillColorButton onFieldChange={this.props.onFieldChange}
                                         backgroundColor={this.props.selected[0].backgroundColor}
                                         alpha={this.props.selected[0].alpha}
                                         getClass={this.getClass} onClick={this.setActive} right={''}/>
                    </div>
                )
                rightBar = (
                    <div className={"toolbar--tools"}>
                        <DuplicateButton duplicate={this.props.duplicate}/>
                        <LinkButton getClass={this.getClass} onClick={this.setActive}
                                    onFieldChange={this.props.onFieldChange} link={this.props.selected[0].link}/>
                        <PositionButton left={this.props.selected[0].left} top={this.props.selected[0].top}
                                        width={this.props.selected[0].width} height={this.props.selected[0].height}
                                        onFieldChange={this.props.onFieldChange} onClick={this.setActive}
                                        getClass={this.getClass}/>
                        <DeleteButton deleteSelected={this.props.deleteSelected}/>
                    </div>
                )
            }
            else if(this.props.items[0].type === BizzerdTypes.IMAGE){
                rightBar = (
                    <div className={"toolbar--tools"}>
                        <DuplicateButton duplicate={this.props.duplicate}/>
                        <LinkButton getClass={this.getClass} onClick={this.setActive}
                                    onFieldChange={this.props.onFieldChange} link={this.props.selected[0].link}/>
                        <PositionButton left={this.props.selected[0].left} top={this.props.selected[0].top}
                                        width={this.props.selected[0].width} height={this.props.selected[0].height}
                                        onFieldChange={this.props.onFieldChange} onClick={this.setActive}
                                        getClass={this.getClass}/>
                        <DeleteButton deleteSelected={this.props.deleteSelected}/>
                    </div>
                )
            }
            else if(this.props.items[0].type === "background"){
                rightBar = (
                    <div className={"toolbar--tools"}>
                        <FillColorButton onFieldChange={this.props.onFieldChange}
                                         backgroundColor={this.props.selected[0].backgroundColor}
                                         alpha={this.props.selected[0].alpha}
                                         getClass={this.getClass} onClick={this.setActive} right={" right"}/>
                    </div>
                    )
            }
            else if(this.props.items[0].type === BizzerdTypes.SHAPE){
                centerBar = (
                    <div className={"toolbar--tools"}>
                        <FillColorButton onFieldChange={this.props.onFieldChange}
                                         alpha={this.props.selected[0].alpha}
                                         backgroundColor={this.props.selected[0].backgroundColor}
                                         getClass={this.getClass} onClick={this.setActive} right={''}/>

                    </div>
                )
                rightBar = (
                    <div className={"toolbar--tools"}>
                        <DuplicateButton  duplicate={this.props.duplicate}/>
                        <RoundButton roundImage={this.props.roundImage} ronding={this.props.selected[0].ronding} selected={this.props.selected[0]} onFieldChange={this.props.onFieldChange} getClass={this.getClass} onClick={this.setActive}/>
                        <LinkButton getClass={this.getClass} onClick={this.setActive}
                                    onFieldChange={this.props.onFieldChange} link={this.props.selected[0].link}/>
                        <PositionButton left={this.props.selected[0].left} top={this.props.selected[0].top}
                                        width={this.props.selected[0].width} height={this.props.selected[0].height}
                                        onFieldChange={this.props.onFieldChange} onClick={this.setActive}
                                        getClass={this.getClass}/>
                        <DeleteButton deleteSelected={this.props.deleteSelected}/>
                    </div>
                )
            }
        }
        else if(this.props.items.length > 1){
            centerBar = (
                <FillColorButton onFieldChange={this.props.onFieldChange}
                                 alpha={this.props.selected[0].alpha}
                                 backgroundColor={this.props.selected[0].backgroundColor}
                                 getClass={this.getClass} onClick={this.setActive}  right={''}/>
            )
            rightBar = (
                <div class="toolbar--tools">
                    <DuplicateButton  duplicate={this.props.duplicate}/>
                    {/*<RoundButton roundImage={this.props.roundImage}/>*/}
                    <LinkButton getClass={this.getClass} onClick={this.setActive}
                                onFieldChange={this.props.onFieldChange} link={this.props.selected[0].link}/>
                    <PositionButton left={this.props.selected[0].left} top={this.props.selected[0].top}
                                    width={this.props.selected[0].width} height={this.props.selected[0].height}
                                    onFieldChange={this.props.onFieldChange} onClick={this.setActive}
                                    getClass={this.getClass}/>
                    <DeleteButton deleteSelected={this.props.deleteSelected}/>
                </div>
            )
        }


        return(
            <div className="toolbar--top" style={{zIndex:30}}>
                {leftBar}
                {centerBar}
                {rightBar}
            </div>
        )
    }
}
export default TopToolbar;
