import React, {Component} from 'react';
import SettingsHeader from './SettingsHeader';
import {connect} from 'react-redux';
import {deleteCompany, getCurrentCompany, updateCompany} from "../../actions/companyActions";
import TableInputField from "../common/TableInputField";

class CompanySettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            company: {},
            editMode: false,
            aanvraag: false
        };
    }

    componentDidMount() {
        if (!this.props.company.name) {
            this.props.getCurrentCompany();
        } else {
            this.setState({
                company: this.props.company.company
            })
        }
    }

    deleteCompany = () => {
        this.props.deleteCompany();
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.company.company !== this.state.company) {
            this.setState({
                company: nextProps.company.company
            })
        }
    }

    handleCancel = (e) => {
        e.preventDefault();
        this.setState({
            company: this.props.company.company,
            editMode: false,
            editMode2: false
        })
    };

    onChange = (e) => {
        this.setState({
            company: {
                ...this.state.company,
                [e.target.name]: e.target.value
            }
        })
    }

    switchEditMode = () => {
        if (this.state.editMode) {
            this.props.updateCompany(this.state.company);
        }
        this.setState({
            editMode: !this.state.editMode
        })
    };

    switchEditMode2 = () => {

        if(this.state.editMode2){
            this.setState({customDomain:true})
            this.props.updateCompany(this.state.company);
        }
        this.setState({
            editMode2: !this.state.editMode2
        })
    };


    render() {

        let cancelButton;
        if (this.state.editMode) {
            cancelButton = (<a href="#/" onClick={(e) => {
                this.handleCancel(e)
            }} className="button button--try">
                Annuleren</a>)
        }
        return (
            <div className="dashboard--main">
                <div className="dashboard--main-content">
                    <SettingsHeader/>
                    <div className="container">
                        <div className="cards--wrapper">
                            <div className="card dashboard--card">
                                <div className="card--header">
                                    <p>Wijzig je bedrijfsnaam of je domeinnaam.</p>
                                </div>
                                <div className="card--main">
                                    <div className="table--body">
                                        <TableInputField editMode={this.state.editMode} label={'Bedrijfsnaam'}
                                                         value={this.state.company.name} name={'name'}
                                                         onChange={this.onChange}/>
                                        <TableInputField editMode={this.state.editMode} label={'Domeinnaam'}
                                                         value={this.state.company.domain} name={'domain'}
                                                         onChange={this.onChange}/>
                                    </div>
                                </div>
                                <div className="card--footer collega--footer">
                                    <button type="" className="button button--primary"
                                            onClick={() => {
                                                this.switchEditMode()
                                            }}>
                                        {!this.state.editMode ? 'Wijzigen' : 'Opslaan'}
                                    </button>
                                    {cancelButton}
                                </div>
                            </div>
                            <div className="card dashboard--card">
                                <div className="card--header">
                                    <p>Verwijder je bedrijf. Als je bedrijf verwijderd is, kunnen wij het niet meer
                                        terugdraaien. Wees er dus zeker van dat je dit wilt.</p>
                                </div>
                                <div className="card--main">
                                </div>
                                <div className="card--footer collega--footer">
                                    <button onClick={this.deleteCompany} className="button button--danger">
                                        Bedrijf verwijderen
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="cards--wrapper">
                            <div className="card dashboard--card">
                                <div className="card--header">
                                    <p>Eigen domeinnaam</p>
                                </div>
                                <div className="card--main">
                                    <div className="table--body">
                                        <TableInputField editMode={this.state.editMode2} label={'Eigen Domein'}
                                                         value={this.state.company.customDomainName}
                                                         name={'customDomainName'}
                                                         onChange={this.onChange}/>
                                    </div>
                                </div>
                                <div className="card--footer collega--footer">
                                    <button onClick={() => {
                                        if (!this.state.aanvraag) {
                                            alert("Er is een aanvraag verstuurd");

                                            //send aanvraag

                                            this.setState({
                                                aanvraag: !this.state.aanvraag
                                            })
                                        }else{
                                            alert("Er is al een aanvraag verstuurd")
                                        }
                                    }} className="button button--primary">
                                        Aanvragen
                                    </button>
                                    <button type="" className="button button--primary"
                                            onClick={() => {
                                                this.switchEditMode2()
                                            }}>
                                        {!this.state.editMode2 ? 'Wijzigen' : 'Opslaan'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    company: state.company
});

export default connect(mapStateToProps, {getCurrentCompany, updateCompany, deleteCompany})(CompanySettings);
