import React, {Component} from 'react';
import EditBizzerd from '../../editbizzerddisplay/EditBizzerd'
import PropTypes from 'prop-types'
import {getBizzerdById, updateBizzerd} from '../../../actions/bizzerdActions';
import {getCurrentCompany} from '../../../actions/companyActions';
import {getCurrentUser, updateTutorial, uploadImage} from '../../../actions/userActions';
import {addPerson, getPersonByUser} from '../../../actions/personActions';
import {connect} from 'react-redux';
import isEmpty from '../../../validation/is-empty';
import {fromJS, List} from 'immutable';
import {shortcuts} from "./functions/Shortcuts";
import {addImageOnClick, addObjectDragging, addObjectOnClick, addShapeOnClick} from './functions/AddElementUtils';
import {dragSquare, onStartSquare, selectFromSquare} from './functions/SelectionSquareUtils';
import {onDragItem, onMouseUp, onSelectSingleItem} from "./functions/MoveObjectUtils";
import {resize, startResize, stopResize} from "./functions/ResizeObjectUtils";
import BizzerdTypes from "../BizzerdTypes";
import TopToolbar from "../sidebartop/TopToolbar";
import ObjectSidebar from "../sidebarright/ObjectSidebar";
import {onRotation, onStartRotation} from "./functions/RotationUtils";
import {NewWordModal} from "../NewWordModal";
import {ScreenSizeModal} from "../ScreenSizeModal";
import objectExamples from "./functions/StandardObjects";

class Designtool extends Component {
    componentDidMount() {
        this.props.getCurrentUser();
        this.props.getCurrentCompany();
        this.props.getPersonByUser();
        document.addEventListener('keydown', this.handleKeyDown);
        if (this.state.bizzerd !== {} && this.props.match !== undefined) {
            if (this.props.match.params.id !== "new") {
                this.props.getBizzerdById(this.props.match.params.id);
            }
        }
        setInterval(() => {
            this.timoutSaveBizzerd();
        }, 20000);
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            // console.log(nextProps.errors);
        }
        if (nextProps.bizzerd.bizzerd !== this.props.bizzerd.bizzerd) {
            this.setState({
                bizzerd: nextProps.bizzerd.bizzerd
            })
        }
        if (nextProps.person && nextProps.person.person) {
            if (!isEmpty(nextProps.person.person)) {
                this.setState({
                    person: nextProps.person.person
                })
            }
        }

        if (nextProps.company && nextProps.company.company) {
            this.setState({
                company: nextProps.company.company
            });
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            steps: [
                {
                    target: '.design--tool-canvas',
                    content: 'Hier zie je je bizzerd. Deze kun je helemaal zelf ontwerpen! De verschillende velden worden automatisch ingevuld met de goede informatie.',
                    disableBeacon: true
                }, {
                    target: '.objects',
                    content: 'Klik hier om teksten, iconen en knoppen toe te kunnen voegen.',
                }, {
                    target: '.uploads',
                    content: 'Klik hier om zelf afbeeldingen of gifjes toe te voegen.',
                }, {
                    target: '.shapes',
                    content: 'Klik hier om vormen of een pasfoto toe te voegen.',
                }, {
                    target: '.iconsets',
                    content: 'Klik hier om uit verschillende iconensetten te kiezen.',
                }, {
                    target: '.toolbar--tools',
                    content: 'Gebruik deze knoppen om acties ongedaan/gedaan te maken en om je bizzerd op te slaan PS. je bizzerd wordt elke 20 seconden automatisch opgeslagen.',
                }, {
                    target: '.toolbar--tools',
                    content: 'Kom je er niet uit met ontwerpen? Wij kunnen ook het ontwerp van jouw bizzerd maken. Onze designers doen dat graag voor een vast bedrag van € 49,- excl. btw. Dat is inclusief 1 correctieronde. Stuur een mail naar maakmijnontwerp@bizzerd.com',
                }],
            item: [],
            added: false,
            history: List(),
            future: List(),
            stored: true,
            company: {},
            actionstatus: {
                dragging: false,
                resizing: false,
                rotation: false,
                startingPosition: {}
            },
            modal:
                {
                    active: false,
                    newText: ""
                },
            screenSizeModal: false,
            screenSize: {
                width: 0,
                height: 0
            },
            user: '',
            selectionSquare: {}
        }
        document.addEventListener('keydown', this.handleKeyPress)
    };

    handleKeyPress = (event) => {
        if (this.state.item.length === 1) {
            const newBizzerd = this.state.bizzerd;
            switch (event.key) {
                case "ArrowLeft":
                    newBizzerd[this.state.item[0].type][this.state.item[0].name].left -= 1
                    break
                case "ArrowUp":
                    newBizzerd[this.state.item[0].type][this.state.item[0].name].top -= 1
                    break
                case "ArrowRight":
                    newBizzerd[this.state.item[0].type][this.state.item[0].name].left += 1
                    break
                case "ArrowDown":
                    newBizzerd[this.state.item[0].type][this.state.item[0].name].top += 1
                    break
                default:
                    break
            }
            this.updateBizzerd(newBizzerd)
        }
    }
    onTutorialFinished = (stateJoy) => {
        if (stateJoy.status === "finished") {
            const newTutorial = this.props.user.user.tutorial[0];
            newTutorial.ontwerp_visited = true;
            this.props.updateTutorial(newTutorial)
        }

    };

    addObject = (type, link) => {
        this.setState((state, props) => addObjectOnClick(state, props, type, link));
    };

    addImageOnClick = (source, width, height) => {
        this.setState((state, props) => addImageOnClick(state, props, source, width, height), () => {
            this.props.updateBizzerd(this.state.bizzerd._id, this.state.bizzerd)
        });
    };

    addShapeOnClick = (type) => {
        this.setState((state, props) => addShapeOnClick(state, props, type), () => {
            this.props.updateBizzerd(this.state.bizzerd._id, this.state.bizzerd)
        });
    };

    deleteSelected = (e) => {
        e.preventDefault();
        const newBizzerd = this.state.bizzerd;
        Object.keys(BizzerdTypes).forEach(key => {
            const type = BizzerdTypes[key];
            newBizzerd[type] = newBizzerd[type].filter((element, index) => {
                let isItem = false;
                this.state.item.forEach(item => {
                    if (item.name === index && item.type === type) {
                        isItem = true;
                    }
                });
                return !isItem
            });
        });
        this.setState({
            ...this.state,
            bizzerd: newBizzerd,
            item: []
        }, () => this.props.updateBizzerd(this.state.bizzerd._id, this.state.bizzerd))
    };

    roundImage = (e) => {
        e.preventDefault()
        // console.log(e)
    }

    duplicate = (e) => {
        e.preventDefault();
        const newBizzerd = this.state.bizzerd;
        this.state.item.forEach(item => {
            const newItem = JSON.parse(JSON.stringify(this.state.bizzerd[item.type][item.name]));
            newItem.left = newItem.left + 5;
            newItem.top = newItem.top + 5;
            newBizzerd[item.type].push(newItem)
        });
        this.setState({
            ...this.state,
            bizzerd: newBizzerd
        }, () => this.props.updateBizzerd(this.state.bizzerd._id, this.state.bizzerd));
    };

    handleKeyDown = event => {
        shortcuts(event, this.undo, this.redo, this.deleteSelected)
    };

    onSelectItem = (event, name, type,prep) => {

        event.persist();
        event.stopPropagation();
        event.preventDefault();
        this.setState((state, props) => onSelectSingleItem(state, props, event, name, type,prep));
        this.updateBizzerd(this.state.bizzerd);
    };

    onFieldChange = (field, newValue) => {
        const newBizzerd = this.state.bizzerd;
        console.log(field,newValue)
        this.state.item.forEach(item => {
            if (!isNaN(newValue) && newValue !== true && newValue !== false && field!=="alpha")
                newValue = parseInt(newValue)
            if (field === "zindex"){

                if(newBizzerd[item.type][item.name][field] === "1" && newValue < 0){
                    newValue = 0;
                }
                newBizzerd[item.type][item.name][field] = (parseInt(newBizzerd[item.type][item.name][field]) + newValue).toString()
            }else {
                newBizzerd[item.type][item.name][field] = newValue
            }
        });
        this.updateBizzerd(newBizzerd);
    };

    onOpenModal = (e) => {
        this.setState({
            modal: {
                newText: "",
                active: true
            }
        });
    };

    onChangeModal = (e) => {
        this.setState({
            modal: {
                ...this.state.modal,
                newText: e.target.value
            }
        });
    };

    onSubmitModal = (e) => {
        const newArray = this.state.bizzerd[BizzerdTypes.TEXT].slice();
        const newObject = objectExamples[BizzerdTypes.TEXT];
        newObject.type = 'extratext';
        newObject.text = this.state.modal.newText;
        const length = newArray.push(newObject);
        newArray[length - 1].name = length - 1;
        const newBizzerd = this.state.bizzerd;
        newBizzerd[BizzerdTypes.TEXT] = newArray;
        updateBizzerd(newBizzerd);
        this.setState({
            modal: {
                active: false,
                newText: ""
            }
        });
    };

    onResizeStart = (name, type) => {
        this.setState(
            (state, props) => startResize(state, props, name, type)
        );
    };

    onResize = (e, direction, ref, d, name, type) => {
        this.setState((state, props) => resize(state, props, e, direction, ref, d, name, type));
    };

    onResizeStop = (e, direction, ref, d, name, type) => {
        this.setState((state, props) => stopResize(state, props, e, direction, ref, d, name, type), () => {
            this.props.updateBizzerd(this.state.bizzerd._id, this.state.bizzerd)
        });
    };

    onRotationStart = () => {
        this.setState((state, props) => onStartRotation(state, props));
        this.updateBizzerd(this.state.bizzerd);
    };

    onMouseMove = (event) => {
        event.persist();
        if (this.state.selectionSquare.dragging) {
            this.setState((state) => dragSquare(state, event));
        } else if (this.state.actionstatus.dragging) {
            this.setState((state, props) => onDragItem(state, props, event));
        } else if (this.state.actionstatus.rotating) {
            this.setState((state, props) => onRotation(state, props, event));
        }
    };

    onMouseUp = (e) => {
        e.preventDefault();
        e.persist();
        if (this.state.selectionSquare.dragging) {
            this.setState((state, props) => selectFromSquare(state, props, e));
        } else if (this.state.actionstatus.dragging) {
            this.setState((state, props) => onMouseUp(state, props, e));
        } else if (this.state.actionstatus.rotating) {
            this.setState((state, props) => onMouseUp(state, props, e));
        }
    };

    moveNewObject = (e, type) => {
        this.setState((state, props) => addObjectDragging(state, props, e, type), () => {
            this.props.updateBizzerd(this.state.bizzerd._id, this.state.bizzerd)
        });
    };

    resetItems = () => {
        this.setState({
            item: []
        });
    };

    selectMultiple = (e) => {
        e.persist();
        e.preventDefault();
        this.setState((state, props) => onStartSquare(state, props, e));
    };

    onImageUpload = (file) => {
        for (const f in file.target.files) {
            const formData = new FormData();
            formData.append("file", file.target.files[f]);
            this.props.uploadImage(formData);
        }
    };

    setIconSet = (iconset) => {
        const newBizzerd = this.state.bizzerd;
        newBizzerd.iconset = iconset;
        this.updateBizzerd(newBizzerd);
    };

    setTemplate = (template) => {
        const newBizzerd = this.state.bizzerd;
        newBizzerd.background = template.background;
        Object.keys(BizzerdTypes).forEach(key => {
            const type = BizzerdTypes[key];
            newBizzerd[type] = template[type];
        });
        this.setState({
            bizzerd: template
        }, () => this.props.updateBizzerd(this.state.bizzerd._id, this.state.bizzerd));
    };

    timoutSaveBizzerd = () => {
        if (this.state.bizzerd !== undefined && !this.state.stored) {
            this.props.updateBizzerd(this.state.bizzerd._id, this.state.bizzerd);
            this.setState({
                stored: true
            });
        }
    };

    redo = () => {
        if (this.state.history && this.state.history.last()) {

            const newBizzerd = this.state.future.last().toJS();
            const future = this.state.future.pop();
            const history = this.state.history.push(fromJS(this.state.bizzerd));
            this.setState({
                history: history,
                bizzerd: newBizzerd,
                future: future,
                stored: false
            })
        }
    };

    undo = () => {
        if (this.state.history && this.state.history.last()) {
            const newBizzerd = this.state.history.last().toJS();
            const history = this.state.history.pop();
            const future = this.state.future.push(fromJS(this.state.bizzerd));
            this.setState({
                history: history,
                bizzerd: newBizzerd,
                future: future,
                stored: false,
                item: []
            })
        }
    };

    updateWindowDimensions = () => {
        if (window.innerWidth < 1024 && !this.state.screenSizeModal) {
            this.setState({
                screenSizeModal: true
            })
        } else if (window.innerWidth > 1024 && this.state.screenSizeModal) {
            this.setState({
                screenSizeModal: false
            })
        }
    };

    updateBizzerd = (newBizzerd) => {
        const currentBizzerd = fromJS(this.state.bizzerd);
        const newHistory = this.state.history.push(currentBizzerd);
        this.setState({
            history: newHistory,
            future: List(),
            bizzerd: newBizzerd,
            stored: false
        });
        if (!isEmpty(this.state.person)) {
            let per = this.state.person
            per.bizzerd = this.state.bizzerd._id
            this.setState({person: per})
            this.props.addPerson(this.state.person);
        }
    };

    saveBizzerd = () => {
        if (!isEmpty(this.state.person)) {
            let per = this.state.person
            per.bizzerd = this.state.bizzerd._id
            this.setState({person: per})
            this.props.addPerson(this.state.person);
            this.props.addPerson(this.state.person);
        }
        this.props.updateBizzerd(this.state.bizzerd._id, this.state.bizzerd);
        this.setState({
            stored: true
        });
    };

    updateTutorial = () => {
        this.setState({tutorial: this.props.user.user.tutorial})
    }

    render() {
        let backgroundColor;
        const {loading,} = this.props.bizzerd;
        let stored = this.state.stored && this.props.bizzerd.stored;
        let bizzerdObj;
        if (isEmpty(this.state.bizzerd) || loading) {
            bizzerdObj = <h4>Loading...</h4>;
        } else {
            if (this.state.bizzerd.background.length > 0 && this.state.bizzerd.background[0].backgroundColor !== undefined) {
                backgroundColor = this.state.bizzerd.background[0].backgroundColor;
            }
            if (this.state.person && this.state.company) {
                bizzerdObj = <EditBizzerd selectMultiple={this.selectMultiple}
                                          bizzerd={this.state.bizzerd} company={this.state.company}
                                          onResizeStart={this.onResizeStart} onResize={this.onResize}
                                          onResizeStop={this.onResizeStop} active={this.state.item}
                                          onMouseMove={this.onMouseMove} person={this.state.person}
                                          onSelectItem={this.onSelectItem} selectionSquare={this.state.selectionSquare}
                                          onStartRotation={this.onRotationStart}
                                          actionstatus={this.state.actionstatus}/>;
            } else {
                bizzerdObj = <EditBizzerd selectMultiple={this.selectMultiple} bizzerd={this.state.bizzerd}
                                          onResizeStart={this.onResizeStart} onResize={this.onResize}
                                          onResizeStop={this.onResizeStop} active={this.state.item}
                                          onMouseMove={this.onMouseMove} person={this.state.person}
                                          onSelectItem={this.onSelectItem} selectionSquare={this.state.selectionSquare}
                                          onStartRotation={this.onRotationStart}
                                          actionstatus={this.state.actionstatus}/>;
            }
        }
        let elements;
        if (this.state.item.length >= 1) {
            elements = this.state.item.map(item => this.state.bizzerd[item.type][item.name]);
        } else {
            elements = [];
        }
        let modal;
        if (this.state.modal.active) {
            modal = (
                <div className="modal--mask">
                    <NewWordModal onChange={this.onChangeModal} onSubmit={this.onSubmitModal}/>
                </div>)
        }
        if (this.state.screenSizeModal) {
            if (!this.state.added && this.state.bizzerd && this.state.bizzerd._id && this.state.person && this.state.person.bizzerdName) {
                this.setState({
                    added: true
                });
                this.saveBizzerd()
            }

            modal = (<div className={'modal--mask-black'}>
                <ScreenSizeModal
                    link={"https://app2.bizzerd.com/show/" + this.state.company.domain + "/" + this.state.person.bizzerdName}/>
            </div>)
        }

        return (
            <div className="dashboard--main">
                {modal}
                <TopToolbar undo={this.undo} redo={this.redo} roundImage={this.roundImage} duplicate={this.duplicate}
                            selected={elements}
                            deleteSelected={this.deleteSelected} items={this.state.item} saveBizzerd={this.saveBizzerd}
                            stored={stored} onFieldChange={this.onFieldChange}
                            isGroup={this.state.actionstatus.group} onGroup={this.onGroup}
                />
                <div className="design--tool-content" onMouseDown={this.selectMultiple} id={"main"}

                     onClick={this.resetItems} onMouseUp={this.onMouseUp}>
                    <div id={"scrollable"} className="design--tool-scrollable">
                        <div id={"background"} className="design--tool-canvas"
                             style={{position: "relative", backgroundColor: backgroundColor, overflow: "hidden"}}
                             onClick={(e) => e.stopPropagation()} onMouseDown={this.selectMultiple}
                             onMouseMove={this.onMouseMove}>
                            {bizzerdObj}
                        </div>
                    </div>
                </div>
                <ObjectSidebar addImageOnClick={this.addImageOnClick}
                               onImageUpload={this.onImageUpload} selectTemplate={this.setTemplate}
                               moveNewObject={this.moveNewObject} addObject={this.addObject}
                               addShape={this.addShapeOnClick} setIconSet={this.setIconSet}
                               onOpenModal={this.onOpenModal}
                />
            </div>
        )
    }

}

Designtool.propTypes = {
    bizzerd: PropTypes.object.isRequired,
    getBizzerdById: PropTypes.func.isRequired,
    updateBizzerd: PropTypes.func.isRequired,
    uploadImage: PropTypes.func.isRequired,
    getCurrentUser: PropTypes.func.isRequired,
    getPersonByUser: PropTypes.func.isRequired,
    getCurrentCompany: PropTypes.func.isRequired,
    addPerson: PropTypes.func.isRequired,
    updateTutorial: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    bizzerd: state.bizzerd,
    user: state.users,
    person: state.persons,
    errors: state.errors,
    company: state.company
});

export default connect(mapStateToProps, {
    getBizzerdById,
    getCurrentCompany,
    updateBizzerd,
    uploadImage,
    updateTutorial,
    getPersonByUser,
    addPerson,
    getCurrentUser
})(Designtool);
