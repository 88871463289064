import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {getAllTemplates} from "../../../actions/templateActions";
import {newBizzerdFromTemplate} from "../../../actions/bizzerdActions";
import {getCurrentUser, updateTutorial} from "../../../actions/userActions"
import DisplayBizzerd from '../../bizzerddisplay/DisplayBizzerd';
import DesignHeader from './DesignHeader';

class ChooseTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            steps: [
                {
                    target: '.basis2',
                    content: 'Klik hier om te beginnen met het ontwerpen van een bizzerd',
                    disableBeacon: true
                }],
            templates: [],
            user: ''
        }
    }

    onTutorialFinished = (stateJoy) => {
        if (stateJoy.status === "finished") {
            const newTutorial = this.props.user.user.tutorial[0];
            newTutorial.template_visited = true;
            this.props.updateTutorial(newTutorial)
        }

    };

    componentDidMount() {
        this.props.getCurrentUser();
        if (this.props.templates.templates.length === 0) {
            this.props.getAllTemplates();
        } else {
            this.setState({
                templates: this.props.templates.templates
            })
        }

    }

    componentWillReceiveProps(nextProps) {
        if (this.props.bizzerd.bizzerd !== nextProps.bizzerd.bizzerd) {
            this.props.history.push(`/dashboard/ontwerpen/tool/${nextProps.bizzerd.bizzerd._id}`)
        }
        if (nextProps.templates.templates !== this.state.templates) {
            this.setState({
                templates: nextProps.templates.templates
            });
        }
    }

    selectTemplate = (e, template) => {
        e.preventDefault();
        delete template._id;
        this.props.newBizzerdFromTemplate('', template);

    };

    updateTutorial = () => {
        this.setState({tutorial: this.props.user.user.tutorial});

    }
    //TODO:
    // GET TEMPLATES FROM DB     --> Is een template gewoon een bizzerd?
    // DISPLAY TEMPLATES IN BAR
    // SET TEMPLATE ON CLICK
    render() {
        // console.log(this.props);
        let field;
        if (this.state.templates.length === 0) {
            field = <h4>Loading...</h4>;
        } else {
            field = this.props.templates.templates.map(template => {
                return (<a href={'#/'} key={template._id} onClick={(e) => {
                    this.selectTemplate(e, template)
                    if (this.props.user.user.tutorial && this.props.user.user.tutorial[0]) {
                        if (!this.props.user.user.tutorial[0].template_visited) {
                            this.onTutorialFinished({status: "finished"})
                        }
                    }
                }}>
                    <div className={'bizzerd--wrapper ' + template.name}>
                        <div className={"bizzerd"}>
                            <DisplayBizzerd bizzerdLayout={template} shouldBeClickable={false} realWidth={160}/>
                        </div>
                        {template.name}
                    </div>
                </a>);
            });

        }
        return (
            <div className={"dashboard--main"}>
                <div className="dashboard--main-content">
                    <DesignHeader/>

                    <div className="container">
                        <div className="cards--wrapper">
                            <div className="card dashboard--card element--card">
                                <div className="card--header">
                                    <p>Klik op een template om deze te gebruiken.</p>
                                </div>
                                <div className="card--main">
                                    <div className="bizzerd--cards-wrapper">
                                        {field}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ChooseTemplate.propTypes = {
    templates: PropTypes.object.isRequired,
    getAllTemplates: PropTypes.func.isRequired,
    updateTutorial: PropTypes.func.isRequired,
    newBizzerdFromTemplate: PropTypes.func.isRequired,
    getCurrentUser: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    templates: state.templates,
    user: state.users,
    bizzerd: state.bizzerd
});

export default connect(mapStateToProps, {
    getAllTemplates,
    updateTutorial,
    newBizzerdFromTemplate,
    getCurrentUser
})(ChooseTemplate);
