import React, {Component} from 'react';
import Resizable from 're-resizable';
import BizzerdTypes from "../../designtool/BizzerdTypes";
import ShapeTypes from "../../designtool/tool/functions/ShapeTypes";
import {Circle, Ellipse, Rectangle, Triangle} from 'react-shapes';

class EditShape extends Component {

    getSize = (layout, shape) => {
        if (shape === ShapeTypes.CIRCLE) {
            return {
                width: Math.max(layout.width, layout.height),
                height: Math.max(layout.width, layout.height)
            };
        } else {
            return {
                borderRadius: layout.ronding,
                width: layout.width,
                height: layout.height
            };
        }
    };

    render() {
        const resizeStyle = {
            position: 'absolute',
            left: this.props.layout.left + 'px',
            top: this.props.layout.top + 'px',
            zIndex: this.props.layout.zindex
        };
        if (this.props.active) {
            resizeStyle.outline = '2px solid #000'
        }
        if (this.props.layout.rotation !== undefined) {
            resizeStyle.transform = "rotate(" + this.props.layout.rotation + "deg)"
        }
        let shape = (function (shape, layout, index, getSize) {
            switch (shape) {
                case ShapeTypes.RECTANGLE:
                    return (
                        <Rectangle width={layout.width} height={layout.height} fill={{color: layout.backgroundColor,alpha: layout.alpha*100}}
                                   id={index}/>);
                case ShapeTypes.CIRCLE:
                    return <Circle r={Math.max(layout.width, layout.height) / 2} fill={{color: layout.backgroundColor,alpha: layout.alpha*100}}
                                   id={index}/>;
                case ShapeTypes.TRIANGLE:
                    return <Triangle width={layout.width} height={layout.height} fill={{color: layout.backgroundColor,alpha: layout.alpha*100}}
                                     id={index}/>;
                case ShapeTypes.ELLIPSE:
                    return <Ellipse rx={layout.width / 2} ry={layout.height / 2} fill={{color: layout.backgroundColor,alpha: layout.alpha*100}}
                                    id={index}/>;
                case ShapeTypes.PHOTO:
                    return <div id={index} style={{...getSize(layout, ShapeTypes.PHOTO), border: "1px solid #000"}}><p
                        style={{
                            position: "absolute",
                            fontSize: 14    ,
                            left: "50%",
                            top: "50%",
                            transform: "translate(-50%, -50%)"
                        }}>Pasfoto {Math.round(layout.width) + "x" + Math.round(layout.height)}</p></div>;
                default:
                    return '';
            }
        })(this.props.layout.type, this.props.layout, this.props.index, this.getSize);
        let rotationelement;
        if (this.props.active && !this.props.isGroup) {
            rotationelement = <div onMouseDown={() => {
                this.props.onStartRotation()
            }}
                                   style={{
                                       height: '4px', width: '4px', top: '-10px', left: this.props.layout.width / 2 - 2,
                                       position: 'absolute', backgroundColor: '#00ff00'
                                   }}/>
        }

        return (
            <Resizable enable={{ top:true, right:true, bottom:true, left:true, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
                onResizeStart={() => this.props.onResizeStart(this.props.index, BizzerdTypes.SHAPE)}
                       onResize={(e, direction, ref, d) =>
                           this.props.onResize(e, direction, ref, d, this.props.index, BizzerdTypes.SHAPE)}
                       onResizeStop={(e, direction, ref, d) =>
                           this.props.onResizeStop(e, direction, ref, d, this.props.index, BizzerdTypes.SHAPE)}
                       id={this.props.id} style={resizeStyle}
                       size={this.getSize(this.props.layout, this.props.layout.type)}
                       onMouseDown={(e) => {
                           e.stopPropagation();
                           this.props.onChange(e, this.props.index, BizzerdTypes.SHAPE)
                       }}
            >
                <div style={{position: 'relative'}}>
                    {shape}
                    {rotationelement}
                </div>
            </Resizable>
        )
    }
}

export default EditShape;