import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {submitRegistrationKey} from "../../actions/registerActions";
import registrationKeyImg from '../../assets/img/confirmemail.svg'
import bizzerdLogo from '../../assets/img/bizzerd_logo.png'
import {ErrorMessage} from "../common/AuthErrorComponent";


class EnterRegistrationKey extends Component{
    constructor(props){
        super(props);
        this.state={
            registrationkey: ['','','','','','']
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.errors){
            this.setState({errors: nextProps.errors.errors});
        }
    }

    onChange = (e, index) => {
        e.preventDefault();
        const regKey = this.state.registrationkey.slice();
        regKey[index] = e.target.value;
        //Update state of registrationkey and auto-submit if it is 6 numbers long.
        this.setState({
            registrationkey: regKey
        }, () => {
            if(this.state.registrationkey.filter(char => char === '').length === 0){
                this.submitKey()
            }

        });
        if(e.target.value.length > 0){
            document.getElementById((parseInt(e.target.id) + 1) % 6).focus();
        }
    };

    submitKey = () => {
        const email = this.props.match.params.email;
        const regKey = this.state.registrationkey.join('');
        this.props.submitRegistrationKey(regKey, email, this.props.history)
    };

    render(){
        const {errors} = this.state;
        let error;
        if(errors !== undefined){
            error = <ErrorMessage errors={errors}/>
        }
        return(
            <div className="wrapper">
                <div className="card">
                    <h2>Bevestigen</h2>
                    <p>We hebben een e-mail gestuurd naar <b>{this.props.match.params.email}</b>. Vergeet niet om ook in je spambox te
                        kijken.</p>
                    <form className="form-group" onSubmit={this.submitKey}>
                        <div className="confirmation-code--wrapper">
                            <div className="confirmation-code--group">
                                <input id="0" name="username" type="email" maxLength="1" autoFocus="autofocus"
                                       className="input-field__input confirmation__input" value={this.state.registrationkey[0]}
                                       onChange={(e) => this.onChange(e,0)}/>
                                <input id="1" name="username" type="email" maxLength="1"
                                       className="input-field__input confirmation__input" value={this.state.registrationkey[1]}
                                       onChange={(e) => this.onChange(e,1)}/>
                                <input id="2" name="username" type="email" maxLength="1"
                                       className="input-field__input confirmation__input" value={this.state.registrationkey[2]}
                                       onChange={(e) => this.onChange(e,2)}/>
                            </div>
                            <div className="confirmation-code--stripe">-</div>
                            <div className="confirmation-code--group">
                                <input id="3" name="username" type="email" maxLength="1"
                                       className="input-field__input confirmation__input" value={this.state.registrationkey[3]}
                                       onChange={(e) => this.onChange(e,3)}/>
                                <input id="4" name="username" type="email" maxLength="1"
                                       className="input-field__input confirmation__input" value={this.state.registrationkey[4]}
                                       onChange={(e) => this.onChange(e,4)}/>
                                <input id="5" name="username" type="email" maxLength="1"
                                       className="input-field__input confirmation__input" value={this.state.registrationkey[5]}
                                       onChange={(e) => this.onChange(e,5)}/>
                            </div>
                        </div>
                        {error}
                    </form>
                    <div className="card--graphic">
                        <img src={registrationKeyImg} alt="Klik hier om terug te gaan"/>
                    </div>
                </div>
                <div className="wrapper--footer">
                    <p>Versie: 2.0. Ondervind je problemen? Neem dan contact op met <a href="mailto:support@bizzerd.com" target="_blank" rel="noopener noreferrer">support@bizzerd.com</a>.</p>
                </div>
                <div className="wrapper--logo">
                    <a href="https://www.bizzerd.com" target="_blank" rel="noopener noreferrer">
                        <img src={bizzerdLogo} alt="Klik hier om naar de homepage te gaan"/>
                    </a>
                </div>
            </div>
        )
    }
}

EnterRegistrationKey.propTypes = {
    submitRegistrationKey: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(mapStateToProps, {submitRegistrationKey})(EnterRegistrationKey)