import {GET_PERSON, GET_PERSONS, PERSON_LOADING, PHOTO_SUCCESS} from "../actions/types";

const initialState = {
    person: {},
    persons: [],
    loading: false,
    photoSuccess: false
};

export default function(state=initialState, action){
    switch(action.type){
        case PERSON_LOADING:
            return{
                ...state,
                loading: true,
                photoSuccess: false
            };
        case GET_PERSONS:
            return{
                ...state,
                persons: action.payload,
                loading: false
            };
        case GET_PERSON:
            return{
                ...state,
                person: action.payload,
                loading: false,
                photoSuccess: false
            };
        case PHOTO_SUCCESS:
            return{
                ...state,
                person: action.payload,
                photoSuccess: true
            };
        default:
            return state;
    }
}
