import React, {Component} from 'react';
import Resizable from 're-resizable';
import fieldTypes from "../../designtool/tool/functions/FieldTypes";
import BizzerdTypes from "../../designtool/BizzerdTypes";
import {IconSets} from "../../common/IconSets";

class EditIcon extends Component {

    render() {
        const style = {
            position: 'absolute',
            left: this.props.layout.left,
            top: this.props.layout.top,
            backgroundSize: 'contain',
            zIndex: this.props.layout.zindex
        };
        const imgStyle = {
            width: this.props.layout.width,
            height: this.props.layout.height,
        };
        if (this.props.layout.backgroundColor !== undefined) {
            imgStyle.fill = this.props.layout.backgroundColor;
        }
        if (this.props.layout.color !== undefined) {
            imgStyle.fill = this.props.layout.color;
        }
        if (this.props.layout.rotation !== undefined) {
            style.transform = "rotate(" + this.props.layout.rotation + "deg)"
        }
        if (this.props.active) {
            imgStyle.outline = "2px solid #000000";
        }
        let image;
        if (this.props.layout.image === undefined || this.props.layout.image === '') {
            if (this.props.iconset) {

                image = IconSets(imgStyle, this.props.index, (e) => {
                    console.log("Haha")
                })[this.props.iconset][this.props.layout.type];
            } else {
                if (fieldTypes[BizzerdTypes.ICON].filter(element => element.type === this.props.layout.type).length > 0) {
                    image = <img style={imgStyle} alt={"icon"} src={fieldTypes[BizzerdTypes.ICON].filter(element =>
                        element.type === this.props.layout.type)[0].placeholder} id={this.props.id}/>;
                }
            }
        } else {
            image = <img style={imgStyle} alt={"icon"} src={this.props.layout.image} id={this.props.id}/>
        }
        let rotationelement;
        if (this.props.active && !this.props.isGroup) {
            rotationelement = <div onMouseDown={() => {
                this.props.onStartRotation()
            }}
                                   style={{
                                       height: '4px', width: '4px', top: '-10px', left: this.props.layout.width / 2 - 2,
                                       position: 'absolute', backgroundColor: '#00ff00'
                                   }}/>
        }
        return (
            <Resizable enable={{
                top: true,
                right: true,
                bottom: true,
                left: true,
                topRight: false,
                bottomRight: false,
                bottomLeft: false,
                topLeft: false
            }}
                       onResizeStart={() => this.props.onResizeStart(this.props.index, BizzerdTypes.ICON)}
                       onResize={(e, direction, ref, d) => this.props.onResize(e, direction, ref, d, this.props.index,
                           BizzerdTypes.ICON)}
                       onResizeStop={(e, direction, ref, d) => this.props.onResizeStop(e, direction, ref, d, this.props.index,
                           BizzerdTypes.ICON)}
                       onMouseDown={(e) => {
                           this.props.onChange(e, this.props.id, BizzerdTypes.ICON,e.currentTarget)
                       }} style={style}
            >
                <div style={{position: "relative"}}>
                    {image}
                    {rotationelement}
                </div>
            </Resizable>
        )
    }
}

export default EditIcon;