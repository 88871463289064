import {getPos} from "../../Utils";
import BizzerdTypes from "../../BizzerdTypes";

export function onSelectSingleItem(state, props, event, name, type,prep){
    //TODO Fix this; this is ugly (onSelectItem conflicts with on reSizeStart, checking target.id is quick fix)
    // event.preventDefault();
    // event.stopPropagation();
    console.log(prep)
    console.log(event.target.parentElement)
    let boolean_thingy = prep!==undefined?event.target.parentElement.id!=="":event.target.id!==""
    if(boolean_thingy){
        if(state.item.filter(item => {return item.name===name && item.type===type}).length === 0) {
            if(state.bizzerd[type][name].group === undefined){
                return {
                    ...state,
                    item: [{
                        name: name,
                        type: type,
                        startingPosition: {
                            x: state.bizzerd[type][name].left,
                            y: state.bizzerd[type][name].top,
                            width: state.bizzerd[type][name].width,
                            height: state.bizzerd[type][name].height
                        }
                    }],
                    actionstatus: {
                        startingPosition: getPos(document.getElementById('main'),
                            document.getElementById('background'),event.pageX,event.pageY),
                        dragging: true,
                        resizing: false,
                        group: false
                    }
                };
            }
            else{
                //TODO: FIX FOR MORE THAN ONE GROUP
                let itemArray = [];
                Object.values(BizzerdTypes).forEach(type => {
                    state.bizzerd[type].forEach((item,index) => {
                        if(item.group === 1){
                            itemArray.push({
                                name: index,
                                type: type,
                                startingPosition: {
                                    x: item.left,
                                    y: item.top,
                                    width: item.width,
                                    height: item.height
                                }
                            })
                        }
                    })
                });

                return {
                    ...state,
                    item: itemArray,
                    actionstatus: {
                        startingPosition: getPos(document.getElementById('main'),
                            document.getElementById('background'),event.pageX,event.pageY),
                        dragging: true,
                        resizing: false,
                        group : true
                    }
                }
            }
        }
        else{
            return {
                ...state,
                actionstatus:{
                    startingPosition: getPos(document.getElementById('main'),
                        document.getElementById('background'),event.pageX,event.pageY),
                    dragging: true,
                    resizing: false
                }
            }
        }
    }
    else{
        return null;
    }
}

export function onDragItem(state, props, event){
    //Update DB
    event.stopPropagation();
    event.preventDefault();
    const newState = state;
    if(state.actionstatus.dragging && !state.actionstatus.resizing){
        const pos = getPos(document.getElementById('main'),
            document.getElementById('background'),event.pageX,event.pageY);
        const posDif = {
            x : pos.x - state.actionstatus.startingPosition.x,
            y : pos.y - state.actionstatus.startingPosition.y
        };
        //Todo: Map transform with posdif
        state.item.forEach(item => {
            const snapOn = snapToOtherObject({x: item.startingPosition.x + posDif.x,
                y: item.startingPosition.y + posDif.y}, state.bizzerd, state.item);
            const newArray = state.bizzerd[item.type].slice();
            newArray[item.name].left= (snapOn.x !== undefined ? snapOn.x : item.startingPosition.x + posDif.x);
            newArray[item.name].top= (snapOn.y !== undefined ? snapOn.y : item.startingPosition.y + posDif.y);
            newState.bizzerd[item.type] = newArray;
        });
        return newState;
    }
    else{
        return null;
    }
}

export function onMouseUp(state, props, event) {
    const newState = state;
    const newItemArray = state.item.map(item =>
        {
            return{
                name: item.name,
                type: item.type,
                startingPosition:{
                    x:state.bizzerd[item.type][item.name].left,
                    y: state.bizzerd[item.type][item.name].top,
                    width: state.bizzerd[item.type][item.name].width,
                    height: state.bizzerd[item.type][item.name].height
                }
            }
        }
    );
    newState.item = newItemArray;
    newState.actionstatus = {
        ...state.actionstatus,
        dragging: false,
        rotating: false,
        resizing: false
    };
    return newState;
}

export function snapToOtherObject(location, bizzerd, items){
    const snapOn = {};
    for (const key in BizzerdTypes) {
        if (BizzerdTypes.hasOwnProperty(key)) {
            const type = BizzerdTypes[key];
            bizzerd[type].forEach((element, index) => {
                if (items.filter(item => item.name === index && item.type === type).length === 0) {
                    if (Math.abs(element.left - location.x) < 10) {
                        snapOn.x = element.left;
                        snapOn.type = type;
                        snapOn.index = index;
                    }
                    if (Math.abs(element.top - location.y) < 10) {
                        snapOn.y = element.top;
                        snapOn.type = type;
                        snapOn.index = index;
                    }
                }
            });
        }
    }
    return (snapOn.type !== undefined ? snapOn : false);
}
