import axios from "axios/index";
import {
    CLEAR_ERRORS,
    GET_ERRORS,
    GET_PERSON,
    GET_PERSONS,
    GET_STATS_BY_COMPANY,
    PERSON_LOADING,
    PHOTO_SUCCESS
} from "./types";

export const getPersonsByCompany = () => dispatch => {
    dispatch(setPersonLoading());
    axios.get(`/api/person/company`)
        .then(res => {
            dispatch({
                type: GET_PERSONS,
                payload: res.data
            })
        })
        .catch(err => dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        }))
};

export const getPersonById = (id) => dispatch => {
    dispatch(setPersonLoading());
    axios.get(`/api/person/${id}`)
        .then(res => {
            dispatch(clearErrors());
            dispatch({
                type: GET_PERSON,
                payload: res.data
            })
        })
        .catch(err => dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        }))
};

export const getPersonByName = (company, name) => dispatch => {
    dispatch(setPersonLoading());
    axios.get(`/api/person/name/${company}/${name}`)
        .then(res => {
            dispatch({
                type: GET_PERSON,
                payload: res.data
            })
        })
        .catch(err => dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        }))
};

export const getPersonByUser = () => dispatch => {
    axios.get(`/api/person`)
        .then(res => {
            dispatch({
                type: GET_PERSON,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        })
};

export const getVcardByPerson = (person) => dispatch => {
    axios.get(`/api/person/vcard/${person}`)
        .then(res => {

            dispatch({
                type: GET_STATS_BY_COMPANY,
                payload: res
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        })
};


export const checkPersonAndContinue = (history) => dispatch => {
    history.push('/paymentinfo');
};

export const setBizzerdForPerson = (personId, bizzerdId, history) => dispatch => {
    axios.post(`/api/person/bizzerd/${personId}`, {id: bizzerdId})
        .then(res => {
            history.push('/dashboard/collegas')
        })
        .catch(err => dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        }))
};

export const uploadPassportPhoto = (photo, id) => dispatch => {
    axios.post(`/api/person/photo/${id}`, photo, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
        .then(res => {
            dispatch({
                type: PHOTO_SUCCESS,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        })
};

export const addPerson = (person) => dispatch => {
    dispatch(setPersonLoading());
    dispatch(clearErrors());
    axios.post(`/api/person/`, person)
        .then(res => {
            dispatch({
                type: GET_PERSON,
                payload: res.data
            })
        })
        .catch(err => {
                if (err && err.response && err.response.data)
                    dispatch({
                        type: GET_ERRORS,
                        payload: err.response.data
                    })
            }
        )
};

export const addPersonExcellijst = (person_list) => dispatch => {
    dispatch(clearErrors());
    axios.post(`/api/person/addExcellijstPersons`, person_list)
        .then(res => {
            dispatch({
                type: GET_PERSON,
                payload: res.data
            })
        })
        .catch(err => dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        }))
};

export const deletePerson = (id) => dispatch => {
    axios.delete(`/api/person/${id}`)
        .then(res => {
            dispatch({
                type: GET_PERSONS,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        });
};

//Person loading
export const setPersonLoading = () => {
    return {
        type: PERSON_LOADING
    }
};

//Person loading
export const clearErrors = () => {
    return {
        type: CLEAR_ERRORS
    }
};
