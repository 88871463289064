import React, {Component} from 'react'
import {getPersonsByCompany} from "../../actions/personActions";
import {getCurrentUser} from "../../actions/userActions"
import {inviteViaEmail} from "../../actions/inviteActions"
import {connect} from "react-redux";
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types';
import ColleagueHeader from "./ColleagueHeader";
import rg4js from 'raygun4js'

class Colleagues extends Component {
    constructor(props) {
        super(props);
        this.state = {
            steps: [
                {
                    target: '.wijzigen',
                    content: 'Hier kun je toegevoegde collega\'s wijzigen en/of verwijderen',
                    disableBeacon: true
                }, {
                    target: '.handmatig',
                    content: 'Hier kun je je collega\'s handmatig toevoegen. PS. vergeet niet jezelf toe te voegen',
                }, {
                    target: '.uitnodigen',
                    content: 'Hier kun je je collega\'s uitnodigen om een bizzerd aan te maken.',
                }, {
                    target: '.registratielink',
                    content: 'Hier kun je je een registratielink genereren die collega\'s kunnen gebruiken om een bizzerd aan te maken',
                }],
            persons: [],
            input: ['', '', ''],
            newPerson: {
                firstname: '',
                lastname: ''
            },
            tutorial: ''
        }
    }

    componentDidMount() {
        this.props.getCurrentUser();
        if (this.props &&this.props.history) {
            this.props.history.listen((location, action) => {
                rg4js('trackEvent', {type: 'pageView', path: location.pathname});
            });
        }
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {

        }
        if (this.props.user.user !== nextProps.user.user) {
            this.props.getPersonsByCompany();
            rg4js('setUser', {
                identifier: nextProps.user.user.email,
                isAnonymous: false,
                email: nextProps.user.user.email,
                fullname: nextProps.user.user.id
            });
        }
        if (nextProps.persons.persons) {
            if (nextProps.user.user) {
                rg4js('setUser', {
                    identifier: nextProps.user.user.email,
                    isAnonymous: false,
                    email: nextProps.user.user.email,
                    fullname: nextProps.user.user.id
                });
            }
            this.setState({
                persons: nextProps.persons.persons
            })
        }
    };

    onInputField = (e, index) => {
        const newInput = this.state.input.slice();
        newInput[index] = e.target.value;
        this.setState({
            input: newInput
        });
    };

    onNewPerson = (e) => {
        e.preventDefault();
        this.setState({
            newPerson: {
                ...this.state.newPerson,
                [e.target.name]: e.target.value
            }
        })
    }

    onTutorialFinished = (stateJoy) => {
        if (stateJoy.status === "finished") {
            const newTutorial = this.props.user.user.tutorial[0];
            newTutorial.collega_visited = true;
            this.props.updateTutorial(newTutorial)
        }

    }

    sendInvites = (e) => {
        this.props.inviteViaEmail(this.state.input, this.state.input.map(input => {
            return ''
        }));
    };
    updateTutorial= ()=>{
        this.setState({tutorial:this.props.user.user.tutorial})
    }
    render() {
        let tableContent;
        if (this.state.persons !== undefined) {
            tableContent = this.state.persons.slice(0, 4).map(person => {
                let url = 'https://app2.bizzerd.com/show/' + person.domain + '/' + person.bizzerdName
                return (
                    <tr key={person._id}>
                        <td>
                            {person.firstname}
                        </td>
                        <td>
                            {person.lastname}
                        </td>
                        <td>
                            <a href={url} target={"_black"} rel={"noopener"}
                               onClick={(e) => {
                                   if (person.bizzerd === undefined) {
                                       e.preventDefault();
                                   }
                               }}
                               style={person.bizzerd === undefined ? {color: "#a0a0a0"} : {}}>{url}</a>
                        </td>
                        <td>
                            <Link to={"/dashboard/collegas/wijzigen/id/" + person._id}>Wijzigen</Link>
                        </td>
                    </tr>)
            });
        }
        let success;
        if (this.props.invite.success && this.props.invite.link === '') {
            success =
                (<div className="alert--succes">
                    <svg version="1.1" className="has-solid " viewBox="0 0 36 36"
                         preserveAspectRatio="xMidYMid meet" focusable="false" aria-hidden="true"
                         role="img" width="32"
                         height="32" fill="currentColor">
                        <path className="clr-i-outline clr-i-outline-path-1"
                              d="M18,2A16,16,0,1,0,34,18,16,16,0,0,0,18,2Zm0,30A14,14,0,1,1,32,18,14,14,0,0,1,18,32Z"/>
                        <path className="clr-i-outline clr-i-outline-path-2"
                              d="M28,12.1a1,1,0,0,0-1.41,0L15.49,23.15l-6-6A1,1,0,0,0,8,18.53L15.49,26,28,13.52A1,1,0,0,0,28,12.1Z"/>
                        <path className="clr-i-solid clr-i-solid-path-1"
                              d="M18,2A16,16,0,1,0,34,18,16,16,0,0,0,18,2ZM28.45,12.63,15.31,25.76,7.55,18a1.4,1.4,0,0,1,2-2l5.78,5.78L26.47,10.65a1.4,1.4,0,1,1,2,2Z"
                              style={{display: "none"}}/>
                    </svg>
                    <span
                        className="c-alert__message">{'Je hebt ' + this.state.input.filter(input => (input !== '')).join(', ') + ' uitgenodigd.'}</span>
                </div>)
        }
        if (this.props.user.user.tutorial && this.props.user.user.tutorial[0]&&false) {

        }
        return (
            <div className={"dashboard--main"}>
                <div className="dashboard--main-content">
                    <ColleagueHeader/>
                    <div className="container">
                        <div className="cards--wrapper">
                            <div className="card dashboard--card wijzigen">
                                <div className="card--header">
                                    <h4><Link to={'/dashboard/collegas/overzicht'}>Collega's wijzigen</Link></h4>
                                    <p>Bekijk en wijzig je collega's</p>
                                </div>
                                <div className="card--main">
                                    <table className={'table--body'}>
                                        <thead>
                                        <tr>
                                            <th>Voornaam</th>
                                            <th>Achternaam</th>
                                            <th>Bekijken</th>
                                            <th>Wijzigen</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {tableContent}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card--footer">
                                    <Link to={'/dashboard/collegas/overzicht'}
                                          className="button button--text footer--secondary">
                                        Of klik hier voor alle collega's →</Link>
                                </div>
                            </div>
                            <div className="card dashboard--card handmatig">
                                <div className="card--header">
                                    <h4><Link to={'/dashboard/collegas/toevoegen'}>Een collega toevoegen</Link></h4>
                                    <p>Voeg een nieuwe collega toe</p>
                                </div>
                                <div className="card--main">
                                    <div className="table-body">
                                        <dl>
                                            <dt>Voornaam</dt>
                                            <input name="firstname" value={this.state.newPerson.firstname}
                                                   onChange={this.onNewPerson}/>
                                        </dl>
                                        <dl>
                                            <dt>Achternaam</dt>
                                            <input name="lastname" value={this.state.newPerson.lastname}
                                                   onChange={this.onNewPerson}/>
                                        </dl>
                                    </div>
                                </div>
                                <div className="card--footer">
                                    <Link
                                        to={'/dashboard/collegas/toevoegen/name/' + this.state.newPerson.firstname + '/' + this.state.newPerson.lastname}
                                        className="button button--text footer--secondary">
                                        Verder met overige gegevens →</Link>
                                </div>
                            </div>
                        </div>
                        <div className="cards--wrapper">
                            <div className="card dashboard--card uitnodigen">
                                <div className="card--header">
                                    <h4><Link to={'/dashboard/collegas/uitnodigen'}>Collega's uitnodigen</Link></h4>
                                    <p>Nodig collega's uit via e-mail</p>
                                </div>
                                <div className="card--main">
                                    <div className="table-body">
                                        <dl>
                                            <input name="email0" type={'email'} value={this.state.input[0]}
                                                   onChange={(e) => this.onInputField(e, 0)} placeholder="...@....com"/>
                                        </dl>
                                        <dl>
                                            <input name="email1" type={'email'} value={this.state.input[1]}
                                                   onChange={(e) => this.onInputField(e, 1)} placeholder="...@....com"/>
                                        </dl>
                                        <dl>
                                            <input name="email2" type={'email'} value={this.state.input[2]}
                                                   onChange={(e) => this.onInputField(e, 2)} placeholder="...@....com"/>
                                        </dl>
                                    </div>
                                </div>
                                <div className="card--footer">
                                    <a href="#/" onClick={(e) => {
                                        this.sendInvites(e)
                                    }} className="button button--primary">
                                        Uitnodigen</a>
                                    <Link to={'/dashboard/collegas/uitnodigen'} type=""
                                          className="button button--text footer--secondary">
                                        Of klik hier voor meer opties →</Link>
                                </div>
                                {success}
                            </div>
                            <div className="card dashboard--card registratielink">
                                <div className="card--header">
                                    <h4><Link to={'/dashboard/collegas/registratielink'}>Registratielink
                                        genereren</Link></h4>
                                    <p>Nodig collega's uit via een registratielink.</p>
                                </div>
                                <div className="card--main">
                                    <p>Iedereen met een registratielink kan deelnemen aan bizzerd. Daarnaast kun je ook
                                        instellen hoelang de link geldig is.</p>
                                </div>
                                <div className="card--footer">
                                    <button type="" className="button button--primary">
                                        <Link style={{color:"white"}} to={'/dashboard/collegas/registratielink'}>Genereren</Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

Colleagues.propTypes = {
    persons: PropTypes.object.isRequired,
    getPersonsByCompany: PropTypes.func.isRequired,
    getCurrentUser: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    persons: state.persons,
    user: state.users,
    invite: state.invite
});

export default connect(mapStateToProps, {
    getPersonsByCompany,
    getCurrentUser,
    inviteViaEmail
})(Colleagues);