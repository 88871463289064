import React, {Component} from 'react';
import Resizable from 're-resizable';
import fieldTypes from "../../designtool/tool/functions/FieldTypes";
import BizzerdTypes from "../../designtool/BizzerdTypes";

class EditButton extends Component{

    render(){
        const resizeStyle = {
            position: 'absolute',
            left: this.props.layout.left + 'px',
            top: this.props.layout.top  + 'px',
            zIndex: this.props.layout.zindex
        };
        const divStyle = {
            width: this.props.layout.width,
            height: this.props.layout.height,
        };
        if(this.props.active){
            divStyle.outline = '2px solid #000'
        }
        if(this.props.layout.rotation !== undefined){
            resizeStyle.transform = "rotate(" + this.props.layout.rotation + "deg)"
        }
        if(this.props.layout.displayImage){
            if(this.props.layout.image === ''){
                if(fieldTypes[BizzerdTypes.BUTTON].filter(element => element.type===this.props.layout.type).length  > 0){
                    divStyle.backgroundImage = fieldTypes[BizzerdTypes.BUTTON].filter(element =>
                        element.type===this.props.layout.type)[0].placeholder;
                }
            }
            else{
                divStyle.backgroundImage = `url(${this.props.layout.image})`;
            }
            divStyle.backgroundSize = 'contain';
            divStyle.backgroundRepeat = 'no-repeat';
        }
        else {
            divStyle.backgroundColor = this.props.layout.backgroundColor;
        }
        let innerText;
        if(this.props.text !== undefined){
            const textStyle = {
                color: this.props.layout.color,
                fontSize: this.props.layout.fontSize
            };
            innerText = (
                <p style={textStyle}>{this.props.text}</p>
            );
        }
        let rotationelement;
        if(this.props.active && !this.props.isGroup){
            rotationelement = <div onMouseDown={(e) => {this.props.onStartRotation()}}
                                   style={{height: '4px', width:'4px', top:'-10px', left:this.props.layout.width/2-2,
                position:'absolute', backgroundColor:'#00ff00'}}/>
        }
        return (
            <Resizable enable={{ top:true, right:true, bottom:true, left:true, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }} onResizeStart={() => this.props.onResizeStart(this.props.index, BizzerdTypes.BUTTON)}
                       onResize={(e, direction, ref, d) =>
                            this.props.onResize(e, direction, ref, d, this.props.index, BizzerdTypes.BUTTON)}
                       onResizeStop={(e, direction, ref, d) =>
                            this.props.onResizeStop(e,direction,ref,d,this.props.index,BizzerdTypes.BUTTON)}
                       id={this.props.id} style={resizeStyle}
                       size={{width: this.props.layout.width, height: this.props.layout.height}}
                       onMouseDown={(e) => {e.stopPropagation();
                            this.props.onChange(e, this.props.index, BizzerdTypes.BUTTON)}}
            >
                <div style={{position:"relative"}}>
                    <div id={this.props.id} style={divStyle}>
                        {innerText}
                    </div>
                    {rotationelement}
                </div>
            </Resizable>
        )
    }
}

export default EditButton;