import React, {Component} from 'react';
import BizzerdTypes from "../BizzerdTypes";
import {ButtonTypes, IconTypes, TextTypes} from "../../common/FieldTypes";

class AddObjectComponent extends Component{
    render(){
        let texts = Object.values(TextTypes).map(
            text => {return (
                <div key={text.type} className="pane--item sidebar--item"
                     onClick={() => {this.props.addObject(BizzerdTypes.TEXT, text.type)}}>
                    {text.name}
                </div>)
            });
        let icons = Object.values(IconTypes).map(icon => {
            return(
                <div key={icon.type} className={'pane--item sidebar--item'}
                     onClick={() => this.props.addObject(BizzerdTypes.ICON, icon.type)}>
                    {icon.name}
                </div>
            )
        });
        // let buttons = Object.values(ButtonTypes).map(button => {
        //      return(
        //          <div key={button.type} className={'pane--item sidebar--item'}
        //               onClick={() => this.props.addObject(BizzerdTypes.BUTTON, button.type)}>
        //              {button.name}
        //          </div>
        //      )
        // });
        return (
            <div className="content--pane fade show active" id="nav-home" role="tabpanel"
                 aria-labelledby="nav-home-tab">
                {texts}
                <div className={'pane--item sidebar--item'}
                     onClick={(e) => this.props.onOpenModal(e)}>
                    Extra Tekst
                </div>
                {icons}
                {/*{buttons}*/}
            </div>
        )
    }
}
export default AddObjectComponent;