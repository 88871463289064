import React, {Component} from 'react'
import PersonalTab from "./PersonalTab";
import ContactTab from "./ContactTab";
import SocialTab from "./SocialTab";
import DisplayBizzerd from "../bizzerddisplay/DisplayBizzerd";
import {connect} from "react-redux";
import {addPerson, clearErrors, getPersonById, getPersonByUser, uploadPassportPhoto} from "../../actions/personActions";
import {getBizzerdByPersonId} from "../../actions/bizzerdActions";
import {getBizzerds, getCurrentCompany} from "../../actions/companyActions";
import SelectDesign from '../colleagues/SelectDesign';
import PropTypes from "prop-types";
import ColleagueHeader from "./ColleagueHeader";
import {ErrorMessage} from "../common/AuthErrorComponent";
import {AddTranslationPopUp} from "../common/AddTranslationPopUp";
import {addTranslation, getTranslation, updateTranslation} from "../../actions/translationActions";
import Validator from "validator";

class AddColleague extends Component {
    constructor(props) {
        super(props);
        this.title = 'Collega\'s beheren';
        this.state = {
            person: {
                firstname: '',
                lastname: '',
                fullname: '',
                jobtitle: '',
                bizzerdName: '',
                email: '',
                tel: '',
                mobile: '',
                adresveld: '',
                linkedin: '',
                facebook: '',
                twitter: '',
                instagram: '',
                pinterest: '',
                website: '',
                bizzerd: ''
            },
            currentTranslation: {},
            translationActive: false,
            translations: [],
            popUp: undefined,
            page: 0,
            editMode: true,
            company:{}
        };
    }

    componentDidMount() {
        this.props.person.person = {};
        this.props.clearErrors();
        if ((!this.props.bizzerd.bizzerds || this.props.bizzerd.bizzerds.length === 0) && this.props.user.user) {
            this.props.getBizzerds(this.props.user.user.company);
        }
        this.props.getCurrentCompany()
        this.refreshPerson()
    }


    refreshPerson = () => {
        this.props.person.person = {};
        if (!this.props.auth.user.superuser) {
            this.props.getPersonByUser();
        } else if (this.props.match.params.type !== undefined) {

            if (this.props.match.params.type === 'id') {
                this.props.getPersonById(this.props.match.params.value1);
                this.props.getBizzerdByPersonId(this.props.match.params.value1);
            } else if (this.props.match.params.type === 'name') {
                let fullname = this.props.match.params.value1 + ' ' + this.props.match.params.value2;
                if (fullname.indexOf("undefined") !== -1) {
                    fullname = undefined;
                }
                let person = this.state.person
                person.firstname = this.props.match.params.value1;
                person.lastname = this.props.match.params.value2;
                person.fullname = fullname;
                this.setState({person: person})
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.company && nextProps.company.company){
            this.setState({company:nextProps.company.company})
        }
        if (nextProps.person.person && nextProps.person.person !== this.state.person
            && Object.keys(nextProps.person.person).length > 0 && this.props.match.params.type === 'id'){
            if (!this.validatePerson(this.state.person)){
                this.setState({
                    person: nextProps.person.person
                })
                return
            }

        } else if (nextProps.person.person && this.state.person._id === undefined && this.props.match.params.type !== 'id' && this.props.auth.user.superuser) {
            this.setState({
                person: {
                    ...this.state.person,
                    _id: nextProps.person.person._id
                }
            })
            // return
        }
        if (nextProps.person.person.email !== "" && !this.props.auth.user.superuser) {
            this.setState({person: nextProps.person.person});
            return
        }
        if (nextProps.errors && Object.keys(nextProps.errors) > 0) {
            this.setState({
                person: nextProps.person.person
            })
        }
        if (nextProps.person.person.bizzerdName) {
            this.setState({
                person: {
                    ...this.state.person,
                    _id: nextProps.person.person._id
                }
            })
        }
        if (this.state.person && this.state.person._id && !nextProps.translations.success && false) {
            this.props.getTranslation(this.state.person._id)
        }
        if (nextProps.translations && false) {
            this.setState({translations: nextProps.translations.translations})
            if (nextProps.translations.refreshPerson) {
                this.setState({person: nextProps.translations.person})
            }
            if (nextProps.translations.updatedTranslation) {
                for (let translationId in this.state.translations) {
                    if (nextProps.translations.updatedTranslation._id === this.state.translations[translationId]._id) {
                        let allTranslation = this.state.translations

                        allTranslation[translationId] = nextProps.translations.updatedTranslation
                        this.setState({translations: allTranslation})
                    }
                }
            }
        }

    };

    componentWillUnmount() {
        this.setState({
            person: {}
        });
        this.props.person.person = {};
    };

    createFullName = (e, fullname) => {
        if (e.target.name === 'firstname') {
            if (this.state.person.lastname) {
                return e.target.value + " " + this.state.person.lastname
            }
        } else if (e.target.name === 'lastname') {
            return this.state.person.firstname + " " + e.target.value
        } else if (e.target.name === 'fullname') {
            return e.target.value
        } else {
            return fullname
        }
    };

    deletePhoto = () => {
        this.setState({
            person: {
                ...this.state.person,
                pasfoto: ''
            }
        });
    };

    editMode = () => {
        if (this.state.editMode) {
            this.onSaveChanges();
        }
        this.setState({
            editMode: !this.state.editMode
        })
    };

    onCancel = (e) => {
        if (!this.props.auth.user.superuser) {
            this.props.getPersonByUser();
        } else if (this.props.match.params.type !== undefined) {
            if (this.props.match.params.type === 'id') {
                this.props.getPersonById(this.props.match.params.value1);
                this.props.getBizzerdByPersonId(this.props.match.params.value1);
            } else if (this.props.match.params.type === 'name') {
                let fullname = this.props.match.params.value1 + ' ' + this.props.match.params.value2;
                if (fullname.indexOf("undefined") !== -1) {
                    fullname = undefined;
                }
                this.setState({
                    person: {
                        ...this.state.person,
                        firstname: this.props.match.params.value1,
                        lastname: this.props.match.params.value2,
                        fullname: fullname
                    }
                })
            }
        }
        this.setState({
            editMode: false
        })
    };

    onChange = (e) => {

        e.preventDefault();
        if (!this.state.translationActive) {
            let fullname = this.createFullName(e, this.state.person.fullname);
            this.setState({
                person: {
                    ...this.state.person,
                    [e.target.name]: e.target.value,
                    fullname: fullname
                }
            })
        } else {
            let fullname = this.createFullName(e, this.state.currentTranslation.fullname);
            this.setState({
                currentTranslation: {
                    ...this.state.currentTranslation,
                    [e.target.name]: e.target.value,
                    fullname: fullname
                }
            })
        }
    };

    onDesignSelect = (event, bizzerdId) => {
        event.preventDefault();
        this.setState({
            person: {
                ...this.state.person,
                bizzerd: bizzerdId
            }
        }, () => this.onSaveChanges());
    };

    onSaveChanges = () => {
        if (this.validatePerson(this.state.person)) {
            if (this.state.translationActive) {
                const newTranslation = this.state.currentTranslation;
                this.props.updateTranslation(newTranslation)

            } else {
                const newPerson = this.state.person;
                if (!this.state.person.bizzerd && this.props.bizzerd.bizzerds.length > 0) {
                    newPerson.bizzerd = this.props.bizzerd.bizzerds[0]._id;
                }
                if (this.props.match.params.id !== undefined) {
                    newPerson._id = this.props.match.params.id
                }
                this.props.addPerson(newPerson);
            }
        }
    };

    onClick = (page) => {
        this.setState({
            page: page
        })
    };

    onImageUpload = (file) => {
        const formData = new FormData();
        formData.append("file", file.target.files[0]);
        this.props.uploadPassportPhoto(formData, this.props.person.person._id);
    };

    onDismiss = () => {
        this.setState({popUp: undefined})
    }

    addLanguage = (name, abbreviation) => {
        this.setState({popUp: undefined})
        window.setTimeout(() => {
            if (this.state.person.translations && this.state.person.translations.length > 0) {
                document.getElementById("translation_dropdown").value = this.state.person.translations[this.state.person.translations.length - 1].translationId
                this.setState({
                    currentTranslation: this.state.translations[this.state.translations.length - 1],
                    translationActive: true
                })
            }
        }, 2000)
        this.props.addTranslation(this.state.person, name, abbreviation)
        this.props.getTranslation(this.state.person._id)
    }

    onChangeTranslation = (event) => {
        if (event.target.value === "add_language") {
            this.setState({popUp: (<AddTranslationPopUp onDismiss={this.onDismiss} addLanguage={this.addLanguage}/>)})
        } else if (event.target.value === "default") {
            this.setState({currentTranslation: {}, translationActive: false})

        } else {
            let translation_id = event.target.value
            for (let translation_index in this.state.translations) {
                let translation = this.state.translations[translation_index]
                if (translation_id === translation._id) {
                    this.setState({currentTranslation: translation, translationActive: true})
                }
            }
        }
    }

    render() {
        // console.log("Rendered")
        if (this.state.company&&!this.state.company.editableByEmployees){
            return <h4><ErrorMessage errors={"Het bedrijf heeft geen toestemming gegeven aan medewerkers om het kaartje aan te passen"}/></h4>
        }
        let tab;
        let annuleren;
        let wijzig;
        let message;
        const {errors} = this.props;

        if (Object.keys(errors).length > 0) {
            let text = errors[Object.keys(errors)[0]];
            message = <ErrorMessage errors={text}/>
            if (text === "P") {
                message = undefined;
            }
        }
        if (this.props.person.photoSuccess) {
            let text = 'Je pasfoto is opgeslagen';
            message = (<div className="alert--succes">
                <svg version="1.1" className="has-solid " viewBox="0 0 36 36"
                     preserveAspectRatio="xMidYMid meet" focusable="false" aria-hidden="true"
                     role="img" width="32"
                     height="32" fill="currentColor">
                    <path className="clr-i-outline clr-i-outline-path-1"
                          d="M18,2A16,16,0,1,0,34,18,16,16,0,0,0,18,2Zm0,30A14,14,0,1,1,32,18,14,14,0,0,1,18,32Z"/>
                    <path className="clr-i-outline clr-i-outline-path-2"
                          d="M28,12.1a1,1,0,0,0-1.41,0L15.49,23.15l-6-6A1,1,0,0,0,8,18.53L15.49,26,28,13.52A1,1,0,0,0,28,12.1Z"/>
                    <path className="clr-i-solid clr-i-solid-path-1"
                          d="M18,2A16,16,0,1,0,34,18,16,16,0,0,0,18,2ZM28.45,12.63,15.31,25.76,7.55,18a1.4,1.4,0,0,1,2-2l5.78,5.78L26.47,10.65a1.4,1.4,0,1,1,2,2Z"
                          style={{display: "none"}}/>
                </svg>
                <span className="c-alert__message">{text}</span>
            </div>)
        }
        if (this.state.editMode) {
            annuleren = (<button onClick={() => {
                this.onCancel()
            }} type="" className="button button--try">
                {this.state.editMode ? "Annuleren" : "Terug"}
            </button>)
        }
        if (this.state.page !== 3) {
            wijzig = (<button onClick={() => this.editMode()} type="" className="button button--primary">
                {this.state.editMode ? "Opslaan" : "Wijzigen"}
            </button>);
        }
        if (this.props.match.params.id !== undefined && Object.keys(this.props.person.person) === 0) {
            tab = (
                <h4>Loading...</h4>
            )
        } else {
            // console.log(this.state.person)
            if (this.state.page === 0) {
                tab = (
                    <PersonalTab editMode={this.state.editMode} onChange={this.onChange} onCancel={this.onCancel}
                                 onSubmit={this.onPageChange} translation={this.state.currentTranslation}
                                 translationActive={this.state.translationActive}
                                 person={this.state.person} onImageUpload={this.onImageUpload}
                                 deletePhoto={this.deletePhoto}/>
                )
            } else if (this.state.page === 1) {
                tab = (
                    <ContactTab editMode={this.state.editMode} onChange={this.onChange} onCancel={this.onCancel}
                                onSubmit={this.onPageChange} translation={this.state.currentTranslation}
                                person={this.state.person} translationActive={this.state.translationActive}/>
                )
            } else if (this.state.page === 2) {
                tab = (
                    <SocialTab editMode={this.state.editMode} onChange={this.onChange} onCancel={this.onCancel}
                               onSubmit={this.onPageChange} translation={this.state.currentTranslation}
                               person={this.state.person} translationActive={this.state.translationActive}/>
                )
            } else {
                tab = (
                    <SelectDesign onCancel={this.onCancel} person={this.state.person}
                                  translation={this.state.currentTranslation} onSubmit={this.onDesignSelect}
                                  translationActive={this.state.translationActive}/>
                )
            }
        }
        let bizzerd;
        if (this.state.person.bizzerd === undefined || this.state.person.bizzerd === '') {
            bizzerd = <a href={"#/"} onClick={() => this.onClick(3)}>Kies eerst een ontwerp.</a>
        } else {
            if (this.state.translationActive && this.state.personContent && this.state.personContent.companyname && false) {
                this.updateTranslationCompany(this.state.personContent.companyName)
                bizzerd =
                    <DisplayBizzerd realWidth={250} shouldBeClickable={false} bizzerdId={this.state.person.bizzerd}
                                    personContent={this.state.currentTranslation}/>
            } else {
                bizzerd =
                    <DisplayBizzerd realWidth={250} shouldBeClickable={false} bizzerdId={this.state.person.bizzerd}
                                    personContent={this.state.person}/>
            }
        }
        let colleagueHeader;
        let kiesontwerp;
        colleagueHeader = (<ColleagueHeader/>);
        kiesontwerp = (<li className={"tab--view-tab " + (this.state.page === 3 ? 'selected' : 'unselected')}>
            <a className="tab--tab-name" href={"#/"} onClick={() => this.onClick(3)}>Kies ontwerp</a>f
        </li>);
        let translation_options = [(<option
            value={"default"}>{'Engels (EN)'}</option>)];
        if (this.state.person && this.state.person.translations && false) {
            translation_options.push(this.state.person.translations.map(translation => {
                return (<option
                    value={translation.translationId}>{translation.name + ' (' + translation.abbreviation + ')'}</option>)
            }))
        }


        return (
            <div className={"dashboard--main"}>
                {this.state.popUp}
                <div className="dashboard--main-content">
                    {colleagueHeader}
                    <div className={"container"}>

                        <div className={'cards--wrapper'}>
                            <div className="card dashboard--card">
                                <header className={'card--header'}>
                                    <div className={'translation_container'}>
                                        <p>Nieuwe collega? Vul hier de gegevens in.</p>
                                        {/*<select className={"translation_dropdown"} id="translation_dropdown"*/}
                                        {/*        onChange={(event) => this.onChangeTranslation(event)}>*/}

                                        {/*    {translation_options}*/}
                                        {/*    <option value={"add_language"}>Add language</option>*/}
                                        {/*</select>*/}
                                    </div>
                                </header>
                                <ul className="tab--view">
                                    <li className={"tab--view-tab " + (this.state.page === 0 ? 'selected' : 'unselected')}>
                                        <a className="tab--tab-name" href={"#/"}
                                           onClick={() => this.onClick(0)}>Persoonsgegevens</a>
                                    </li>
                                    <li className={"tab--view-tab " + (this.state.page === 1 ? 'selected' : 'unselected')}>
                                        <a className="tab--tab-name" href={"#/"}
                                           onClick={() => this.onClick(1)}>Contactgegevens</a>
                                    </li>
                                    <li className={"tab--view-tab " + (this.state.page === 2 ? 'selected' : 'unselected')}>
                                        <a className="tab--tab-name" href={"#/"} onClick={() => this.onClick(2)}>Social
                                            media</a>
                                    </li>
                                    {kiesontwerp}

                                </ul>
                                <div className="card--main">
                                    {tab}
                                </div>
                                {message}
                                <div className="card--footer collega--footer">
                                    {wijzig}
                                    {annuleren}
                                </div>
                            </div>
                            <div className="card dashboard--card">
                                <header className={'card--header'}>
                                    <p>Dit is hoe het visitekaartje eruit komt te zien.</p>
                                </header>
                                <div className="card--main">
                                    <div className={'bizzerd--cards-wrapper'}>
                                        <div className={'bizzerd'}>
                                            {bizzerd}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    updateTranslationCompany = (companyName) => {
        return
        let currentTranslation = this.state.currentTranslation
        currentTranslation['companyname'] = companyName
        this.setState({currentTranslation: currentTranslation})
    }

    validatePerson = (person) => {
        let valid = true;
        if (!person.email || !Validator.isEmail(person.email)) {
            valid = false;
        }
        if (!person.firstname) {
            valid = false;
        }
        if (!person.bizzerdName || (person.bizzerdName && person.bizzerdName.length < 2) || (person.bizzerdName && !/[a-z0-9]+/.test(person.bizzerdName)) || (person.bizzerdName && person.bizzerdName.indexOf(" ") !== -1)) {
            valid = false;
        }
        if (!person.bizzerdName) {
            valid = false;
        }
        if (person.website && !Validator.isURL(person.website)) {
            valid = false;
        }
        if (person.facebook && !Validator.isURL(person.facebook)) {
            valid = false;
        }
        if (person.linkedin && !Validator.isURL(person.linkedin)) {
            valid = false;
        }
        if (person.youtube && !Validator.isURL(person.youtube)) {
            valid = false;
        }
        if (person.twitter && !Validator.isURL(person.twitter)) {
            valid = false;
        }
        if (person.instagram && !Validator.isURL(person.instagram)) {
            valid = false;
        }
        if (person.pinterest && !Validator.isURL(person.pinterest)) {
            valid = false;
        }

        return valid;
    };
}

AddColleague.propTypes = {
    person: PropTypes.object.isRequired,
    addPerson: PropTypes.func.isRequired,
    getPersonById: PropTypes.func.isRequired,
    getBizzerdByPersonId: PropTypes.func.isRequired,
    getCurrentCompany: PropTypes.func.isRequired,
    getPersonByUser: PropTypes.func.isRequired,
    uploadPassportPhoto: PropTypes.func.isRequired,
    addTranslation: PropTypes.func.isRequired,
    getTranslation: PropTypes.func.isRequired,
    updateTranslation: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
    return ({
        person: state.persons,
        bizzerd: state.bizzerd,
        user: state.users,
        auth: state.auth,
        translations: state.translations,
        errors: state.errors,
        company:state.company,
    })
};


export default connect(mapStateToProps, {
    addPerson, getPersonById, getBizzerdByPersonId, getPersonByUser,
    uploadPassportPhoto, getBizzerds, clearErrors, addTranslation, getTranslation, updateTranslation,getCurrentCompany,
})(AddColleague);
