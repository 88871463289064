import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {getCurrentUser} from "../../../actions/userActions";
import {deleteBizzerd, newBizzerdFromTemplate} from "../../../actions/bizzerdActions";
import {getBizzerds} from "../../../actions/companyActions";
import DisplayBizzerd from "../../bizzerddisplay/DisplayBizzerd";
import nodesignsvg from "../../../assets/img/no_design.svg"
import DesignHeader from './DesignHeader';
import Spinner from '../../../assets/img/Spinner.svg';
import DesignPrompt from './DesignPrompt'

class ChooseDesign extends Component {
    componentDidMount() {
        this.props.getBizzerds();
    }

    deleteBizzerd = (id) => {
        const result = window.confirm("Weet je zeker dat je dit ontwerp wil verwijderen?");
        if (result) {
            this.props.deleteBizzerd(id);
            this.props.getBizzerds();
        }
    };


    duplicateBizzerd = (id,bizzerd) => {
        delete bizzerd._id
        this.props.newBizzerdFromTemplate('',bizzerd);
        this.props.getBizzerds();

    };


    render() {
        const {loading, bizzerds} = this.props.bizzerd;
        let content;
        if (loading) {
            content = (
                <div className="placeholder--container">
                    <h3>Visitekaartjes ophalen.</h3>
                    <div className="card--graphic">
                        <img alt={"spinner"} src={Spinner}/>
                    </div>
                </div>
            );
        } else {
            if (bizzerds.length > 0) {
                const designs = bizzerds.map(bizzerd => {
                    return (
                        <div className={'bizzerd'} key={bizzerd._id}>
                            <Link to={`/dashboard/ontwerpen/tool/${bizzerd._id}`}>
                                <DisplayBizzerd bizzerdLayout={bizzerd} shouldBeClickable={false} realWidth={150}/>
                            </Link>

                            <button style={{zIndex: 100}} className="button--hover" onClick={(e) => {
                                e.stopPropagation();
                                this.deleteBizzerd(bizzerd._id)
                            }}>
                                <svg version="1.1" className="has-solid " viewBox="0 0 36 36"
                                     preserveAspectRatio="xMidYMid meet" color="#FFFFFF" focusable="false"
                                     aria-hidden="true" role="img" width="32" height="32" fill="#FFFFFF">
                                    <path className="clr-i-outline clr-i-outline-path-1"
                                          d="M27.14,34H8.86A2.93,2.93,0,0,1,6,31V11.23H8V31a.93.93,0,0,0,.86,1H27.14A.93.93,0,0,0,28,31V11.23h2V31A2.93,2.93,0,0,1,27.14,34Z"/>
                                    <path className="clr-i-outline clr-i-outline-path-2"
                                          d="M30.78,9H5A1,1,0,0,1,5,7H30.78a1,1,0,0,1,0,2Z"/>
                                    <path className="clr-i-outline clr-i-outline-path-5"
                                          d="M23,5.86H21.1V4H14.9V5.86H13V4a2,2,0,0,1,1.9-2h6.2A2,2,0,0,1,23,4Z"/>
                                    <path className="clr-i-solid clr-i-solid-path-1"
                                          d="M6,9V31a2.93,2.93,0,0,0,2.86,3H27.09A2.93,2.93,0,0,0,30,31V9Zm9,20H13V14h2Zm8,0H21V14h2Z"
                                          style={{display: 'none'}}/>
                                    <path className="clr-i-solid clr-i-solid-path-2"
                                          d="M30.73,5H23V4A2,2,0,0,0,21,2h-6.2A2,2,0,0,0,13,4V5H5A1,1,0,1,0,5,7H30.73a1,1,0,0,0,0-2Z"
                                          style={{display: 'none'}}/>
                                    <rect className="clr-i-outline clr-i-outline-path-3" x="21" y="13" width="2"
                                          height="15"/>
                                    <rect className="clr-i-outline clr-i-outline-path-4" x="13" y="13" width="2"
                                          height="15"/>
                                </svg>
                            </button>
                            <button style={{zIndex: 100, right: 50}} className="button--hover2" onClick={(e) => {
                                e.stopPropagation();
                                this.duplicateBizzerd(bizzerd._id,bizzerd)
                            }}>

                                <svg width="32" height="32" viewBox="0 0 32 32" fill="#FFFFFF" id="_16_-_Duplicate"
                                     data-name="16 - Duplicate" xmlns="http://www.w3.org/2000/svg" color="#FFFFFF">
                                    <path id="Path_245" data-name="Path 245"
                                          d="M31,4a3,3,0,0,0-3-3H15.59a3,3,0,0,0-3,3V16.41a3,3,0,0,0,3,3H28a3,3,0,0,0,3-3ZM29,4V16.41a1,1,0,0,1-1,1H15.59a1,1,0,0,1-1-1V4a1,1,0,0,1,1-1H28A1,1,0,0,1,29,4Z"
                                          transform="translate(-1 -1)" fill-rule="evenodd"/>
                                    <path id="Path_246" data-name="Path 246"
                                          d="M10.4,12.59H4a3,3,0,0,0-3,3V28a3,3,0,0,0,3,3H16.41a3,3,0,0,0,3-3V21.6a1,1,0,1,0-2,0V28a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V15.59a1,1,0,0,1,1-1h6.4a1,1,0,0,0,0-2Z"
                                          transform="translate(-1 -1)" fill-rule="evenodd"/>
                                </svg>

                            </button>
                        </div>);
                });

                content = (
                    <div className="container">
                        <div className="cards--wrapper">
                            <div className="card dashboard--card element--card">
                                <div className="card--header">
                                    <p>Klik op een ontwerp om te wijzigen.</p>
                                </div>
                                <div className="card--main">
                                    <div className={'bizzerd--cards-wrapper'}>
                                        {designs}
                                    </div>
                                </div>
                                <div className="card--footer">
                                    <Link to={`/dashboard/ontwerpen/kiestemplate`}
                                          className="button button--text footer--secondary">
                                        Of klik hier om een nieuw ontwerp te maken →</Link>
                                </div>
                            </div>

                        </div>

                    </div>
                )
            } else {
                content = (
                    <div className="placeholder--container">
                        <p>Het lijkt erop dat je nog geen ontwerpen hebt gemaakt.</p>
                        <div className="card--graphic">
                            <img src={nodesignsvg} alt="Klik hier om terug te gaan"/>
                        </div>
                        <Link to={`/dashboard/ontwerpen/kiestemplate`} className="button button--primary">
                            Nieuw ontwerp maken</Link>
                    </div>
                );
            }
        }
        return (
            <div className="dashboard--main">
                <div className="dashboard--main-content">
                    <DesignHeader/>
                    {content}

                    <DesignPrompt/>
                </div>
            </div>
        )
    }
}

ChooseDesign.propTypes = {
    getCurrentUser: PropTypes.func.isRequired,
    newBizzerdFromTemplate: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    getBizzerds: PropTypes.func.isRequired,
    bizzerd: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    user: state.users,
    bizzerd: state.bizzerd
});

export default connect(mapStateToProps, {
    getCurrentUser, getBizzerds, deleteBizzerd, newBizzerdFromTemplate
})(ChooseDesign);
