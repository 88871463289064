import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from "prop-types";
import {getCurrentCompany, updateCompany} from "../../actions/companyActions";
import {getPersonsByCompany} from "../../actions/personActions";
import "react-table/react-table.css";
import {Link} from "react-router-dom";
import KoppelingHeader from "./KoppelingHeader";
import TableInputField from "../common/TableInputField";


class Koppeling extends Component {
    constructor(props) {
        super(props);
        this.state = {
            company: {},
            editMode: false
        };
    }

    componentDidMount() {
        this.props.getPersonsByCompany();
        if (!this.props.company.name) {
            this.props.getCurrentCompany();
        } else {
            this.setState({
                company: this.props.company.company,
                afas: this.props.company.company.afas
            });
        }
        if (this.props.company.company.afas) {
            this.setState({afas:this.props.company.company.afas})
        }
        window.addEventListener('resize', this.updateWindowDimensions);
        this.updateWindowDimensions()
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.company.company) {
            this.setState({company:nextProps.company.company})
            if (nextProps.company.company.afas) {
                this.setState({afas:nextProps.company.company.afas})
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        let widthOfGraph = 0;
        if (window.innerWidth < 1300)
            if (window.innerWidth > 990)
                widthOfGraph = (window.innerWidth - 210) / 1.2;
            else
                widthOfGraph = (window.innerWidth) / 1.2;
        else {
            widthOfGraph = 950
        }
        this.setState({width: widthOfGraph});
    };


    render() {
        return (
            <div className="dashboard--main">
                <div className="dashboard--main-content">
                    <KoppelingHeader/>
                    <div className="container">
                        <div className="cards--wrapper">
                            <div className="card dashboard--card element--card">
                                <div className="card--header">
                                    <h4><Link to={'/dashboard/collegas/toevoegen'}>AFAS koppeling</Link></h4>
                                    <p>Vul hier je AFAS gegevens in</p>
                                </div>
                                <div className="card--main">
                                    <div className="table-body">
                                        <TableInputField label={"URL"} editMode={this.state.editMode} name="url"
                                                         onChange={this.onNewAfas}
                                                         value={this.state.afas && this.state.afas.url ? this.state.afas.url : ""}/>

                                        <TableInputField label={"Token"} editMode={this.state.editMode} name="token"
                                                         onChange={this.onNewAfas}
                                                         value={this.state.afas && this.state.afas.token ? this.state.afas.token : ""}/>

                                    </div>
                                </div>
                                <div className="card--footer--koppeling">
                                    <button onClick={() => {
                                        if (this.state.editMode) {
                                            if (this.state.afas.url !== "" && this.state.afas.token !== "") {
                                                let company = this.state.company
                                                company.afas = this.state.afas
                                                this.setState({company:company})
                                                this.props.updateCompany(this.state.company)
                                            }
                                        }
                                        this.setState({
                                            editMode: !this.state.editMode
                                        })
                                    }} type="" className="button button--primary">
                                        {this.state.editMode ? "Opslaan" : "Wijzigen"}
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }


    onNewAfas = (e) => {
        e.preventDefault();
        this.setState({
            afas: {
                ...this.state.afas,
                [e.target.name]: e.target.value
            }

        });
    }

}

Koppeling
    .propTypes = {
    getPersonsByCompany: PropTypes.func.isRequired
};

const
    mapStateToProps = (state) => ({
        company: state.company,
        data: state.data,
        persons: state.persons
    });

export default connect(mapStateToProps, {
    getCurrentCompany,
    getPersonsByCompany,
    updateCompany
})(Koppeling);
