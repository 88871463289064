import {getPos} from "./../../Utils";
import BizzerdTypes from "../../BizzerdTypes";

export function onStartSquare(state, props, e){
    const pos=getPos(document.getElementById('main'),document.getElementById('background'),e.pageX,e.pageY);
    return {
        ...state,
        item: [],
        selectionSquare : {
            dragging : true,
            startingx : pos.x,
            startingy: pos.y
        }
    }
}

export function dragSquare(state,event){
    const newState = state;
    const pos = getPos(document.getElementById('main'),document.getElementById('background'),event.pageX,event.pageY);
    newState.selectionSquare = {
        ...state.selectionSquare,
        currentX: pos.x,
        currentY: pos.y
    };
    return newState;
}

export function reduceItem(array, button, index, type, selectionSquare, endpos){
    if(checkItemInSquare(button, selectionSquare, endpos)){
        array.push({ name: index, type:type, startingPosition:{ x:button.left, y:button.top }} );
        return array;
    }
    else{
        return array;
    }
}


export function selectFromSquare(state, props, e){
    const endpos=getPos(document.getElementById('main'),document.getElementById('background'),e.pageX,e.pageY);
    const itemArray = [];
    Object.values(BizzerdTypes).forEach(type => {
        itemArray.push(...state.bizzerd[type].reduce((array, item, index) => reduceItem(array, item, index, type,
            state.selectionSquare, endpos), []))
    });
    if(itemArray.length === 0 && state.item.length === 0){
        itemArray.push({type: "background", name:0})
    }
    return {
        ...state,
        item : itemArray,
        selectionSquare: {
            dragging: false,
            startingx: 0,
            startingy: 0
        }
    }
}

export function checkItemInSquare(item, startingPosition, endpos){
    const squareStartX = Math.min(startingPosition.startingx, endpos.x);
    const squareStartY = Math.min(startingPosition.startingy, endpos.y);
    const squareEndX = Math.max(endpos.x, startingPosition.startingx);
    const squareEndY = Math.max(endpos.y, startingPosition.startingy);
    if(
        item.left < squareEndX
        && item.left + item.width > squareStartX
        && item.top < squareEndY
        && item.top + item.height > squareStartY
    ){
        return true;
    }
    else{
        return false;
    }
}