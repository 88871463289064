import React from 'react';
import {Link} from 'react-router-dom';

export const ScreenSizeModal = (props) =>
{
    return (
        <div className="modal">
            <h2>Oeps...</h2>
            <p>Helaas is het momenteel nog niet mogelijk om een ontwerp aan te passen met het huidige schermformaat. Log in op een computer om het ontwerp aan te passen. Klik op bekijken om je digitale visitekaartje te bekijken of klik op terug om je gegevens aan te passen.</p>
            <Link to={'/dashboard/collegas'} className={'button button--try'}>Terug</Link>
            <p></p>
            <a target={"_blank"} rel={"noopener noreferrer"} href={props.link} className={'button button--primary'}>Bekijken</a>
        </div>);
};
