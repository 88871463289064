import  React, {Component} from 'react';
import {IconSets} from "../../common/IconSets";
import vCardsJS from 'vcards-js';

class Icon extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let image;
        const style = {
            position: 'absolute',
            left: this.props.resize(this.props.layout.left),
            top: this.props.resize(this.props.layout.top),
            zIndex: this.props.layout.zindex,
            backgroundSize: 'contain',
            width: this.props.resize(this.props.layout.width),
            height: this.props.resize(this.props.layout.height)
        };
        const iconStyle = {
            width: this.props.resize(this.props.layout.width),
            height: this.props.resize(this.props.layout.height),
            color: this.props.layout.backgroundColor
        };
        if (this.props.layout.backgroundColor !== undefined) {
            iconStyle.fill = this.props.layout.backgroundColor;
        }
        if (this.props.layout.color !== undefined) {
            iconStyle.fill = this.props.layout.color;
        }
        if (this.props.layout.image === "") {
            if (this.props.iconset !== undefined) {
                image = IconSets(iconStyle, this.props.id)[this.props.iconset][this.props.layout.type]
            }
        } else {
            image = <img src={this.props.layout.image}/>
        }
        if (this.props.layout.rotation !== undefined) {
            style.transform = "rotate(" + this.props.layout.rotation + "deg)"
        }

        if (this.props.link && this.props.link.includes("vcard", 0)) {
            let w=undefined;
            let h = undefined;
            for (let img in this.props.bizzerd.shape){
                let shape = this.props.bizzerd.shape[img];
                if (shape.type === "photo"){
                    w = shape.width
                    h = shape.height
                }
            }
            function getBase64Image(img,w,h) {
                let canvas = document.createElement("canvas");
                canvas.width = w;
                canvas.height = h;
                let ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0,w,h);
                let dataURL = canvas.toDataURL("image/png");
                return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
            }
            return (
                <div>
                    <img crossOrigin="anonymous" id="imageid" src={this.props.person.pasfoto}
                         style={{visibility:"hidden"}}/>
                    <a onClick={() => {
                        let base64 = getBase64Image(document.getElementById("imageid"),w,h);
                        let person = this.props.person;
                        const vCard = vCardsJS();
                        // vCard.isOrganization = true;
                        vCard.firstName = person.firstname;
                        let lastname = person.fullname
                        lastname = lastname.replace(person.firstname,'')
                        lastname = lastname.trim()
                        vCard.lastName = lastname;
                        vCard.photo.embedFromString(base64,'JPEG');
                        vCard.title = person.jobtitle;
                        vCard.formattedName = person.fullname
                        vCard.workPhone = person.tel;
                        vCard.organization = person.companyname;
                        vCard.workAddress.street = person.adresveld
                        vCard.cellPhone = person.mobile;
                        vCard.workEmail = person.email;
                        vCard.socialUrls['facebook'] = person.facebook;
                        vCard.socialUrls['linkedin'] = person.linkedin;
                        vCard.socialUrls['twitter'] = person.twitter;
                        vCard.socialUrls['instagram'] = person.instagram;
                        vCard.socialUrls['pinterest'] = person.pinterest;
                        vCard.url = person.website;
                        vCard.note = window.location.href;

                        var responseString = vCard.getFormattedString()
                        // we need this replace because the latest release (2.10.0) of https://github.com/enesser/vCards-js does not yet contain commit https://github.com/enesser/vCards-js/commit/574c9ea4b77ca95b85872fe0c1e5f97e1a67be98
                        // because of this, the social profiles only get CHARSET=UTF-8 filled in on ios/macos, don't bother checking if it works on Android. Android doesn't support x-socialprofile.
                        while(responseString.includes("X-SOCIALPROFILE;CHARSET=UTF-8;")){
                            responseString = responseString.replace("X-SOCIALPROFILE;CHARSET=UTF-8;","X-SOCIALPROFILE;");
                        }

                        const url = window.URL.createObjectURL(new Blob([responseString],{type:'text/x-vcard',name:person.fullname+".vcf"}));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', person.fullname+ `.vcf`);
                        // 3. Append to html page
                        document.body.appendChild(link);
                        // 4. Force download
                        link.click();
                        // 5. Clean up and remove the link
                        link.parentNode.removeChild(link);
                    }} style={style}>{image}
                    </a>
                </div>
            );
        }
        if (!this.props.link || this.props.layout.type === 'delen') {
            return (
                <a href={this.props.link} style={style}>
                    {image}
                </a>
            )
        }

        return (
            <a target="_blank" href={this.props.link} style={style}>
                {image}
            </a>
        )
    }
}

export default Icon;
