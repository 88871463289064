import React, {Component} from 'react';
import {Link} from 'react-router-dom';

class SettingsHeader extends Component{
    render(){
        let header;
        if(window.location.href.includes('betaal')){
            header = 'Betalingsgegevens';
        }
        else if(window.location.href.includes('bedrijf')){
            header = 'Bedrijfsinformatie';
        }
        else if(window.location.href.includes('gebruiker')){
            header = 'Gebruikersbeheer';
        }
        return(
            <div className="container container--header">
                <div className="navigation">
                    <nav>
                        <ul className="dashboard--nav">
                            <li>
                                <Link to="/dashboard/instellingen/bedrijf"
                                      className={window.location.href.includes('bedrijf') ? 'active' : ''}>
                                    Bedrijfsinformatie
                                </Link>
                            </li>
                            <li><Link className={window.location.href.includes('betaal') ? 'active' : ''}
                                      to="/dashboard/instellingen/betaal">Betalingsgegevens</Link></li>
                            <li><Link className={window.location.href.includes('gebruiker') ? 'active' : ''}
                                      to="/dashboard/instellingen/gebruiker">Gebruikersbeheer</Link></li>
                        </ul>
                    </nav>
                    <header className="navigation--header">
                        <h3 className="section-title">{header}</h3>
                    </header>
                </div>
            </div>
        )
    }
}

export default SettingsHeader;