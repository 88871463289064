import React, {Component} from 'react';
import {Link} from 'react-router-dom'

class ColleagueHeader extends Component{
    render() {
        let header;
        let active = [false, false]
        if(window.location.href.includes('kiesdesign')){
            header = (
                <header className="navigation--header">
                    <h3 className="section-title">Bekijk en wijzig je ontwerpen</h3>
                </header>
            );
            active = [true,false];
        }
        else if(window.location.href.includes('kiestemplate')){
            header = (
                <header className="navigation--header">
                    <h3 className="section-title">Kies een template voor een nieuw ontwerp</h3>
                </header>
            );
            active = [false,true];
        }
        return(
            <div className="container container--header">
                <div className="navigation">
                    <nav>
                        <ul className="dashboard--nav">
                            <li><Link to="/dashboard/ontwerpen/kiesdesign" className={active[0]? 'active': ''}>Je ontwerpen</Link></li>
                            <li><Link to="/dashboard/ontwerpen/kiestemplate" className={active[1]? 'active': ''}>Template kiezen</Link></li>
                        </ul>
                    </nav>
                    {header}
                </div>
            </div>
        )
    }
}

export default ColleagueHeader;