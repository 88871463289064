import React from 'react';

export const OpMaatPopOver = (props) => (
    <div className="pop-over-container">
        <div className="pop-over">
            <h3>bizzerd op maat</h3>
            <p>De gegevens die je ingevuld hebt komen ons niet bekend voor in dit systeem. Wel herkennen we deze gegevens uit ons bizzerd op maat systeem. <br/><b>Wil je door naar het bizzerd op maat systeem?</b></p>
            <button type="button" onClick={props.onDismiss} className="button button--text">Annuleer</button>
            <a href={"https://app.bizzerd.com/s_edit.php?email=" + props.email} className="button button--primary">Ja, graag</a>
        </div>
    </div>
);

