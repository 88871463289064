import React, {Component} from 'react';
import {Link} from 'react-router-dom'
import {connect} from 'react-redux';

class ColleagueHeader extends Component{
    render(){
        let nav;

        if(this.props.auth !== undefined){
            let terug;
            if(window.location.href.includes('wijzigen')){
                terug = <li><Link to={'/dashboard/collegas/overzicht'}>&#8592; Terug</Link></li>
            }
            if(this.props.auth.user.superuser){
                nav = (
                    <ul className="dashboard--nav">
                        {terug}
                        <li>
                            <Link className={window.location.href.includes('overzicht') || window.location.href.includes('wijzigen') ? 'active':''}
                                  to={"/dashboard/collegas/overzicht"}>Collega's wijzigen</Link>
                        </li>
                        <li>
                            <Link className={window.location.href.includes('toevoegen') ? 'active':''}
                                  to={"/dashboard/collegas/toevoegen"}>Collega's toevoegen</Link>
                        </li>
                        <li>
                            <Link className={window.location.href.includes('uitnodigen') ? 'active':''}
                                  to={"/dashboard/collegas/uitnodigen"}>Collega's uitnodigen</Link>
                        </li>
                        <li>
                            <Link className={window.location.href.includes('registratie') ? 'active':''}
                                  to={"/dashboard/collegas/registratielink"}>Registratielink genereren
                            </Link>
                        </li>
                        <li>
                            <a href={"https://www.youtube.com/watch?v=At4xW3IG0Mo"} target={"_blank"} rel={"noopener noreferrer"}>Uitleg video
                            </a>
                        </li>
                    </ul>
                )
            }
            else {
                nav = (
                    <ul className="dashboard--nav">
                        <li>
                            <Link className={window.location.href.includes('toevoegen') ? 'active':''}
                                  to={`/dashboard/collegas/toevoegen`}>Je visitekaartje wijzigen</Link>
                        </li>
                    </ul>
                )
            }
        }
        let header = <header className="navigation--header">
            <h3 className="section-title">Visitekaartjes beheren</h3>
            <p>Wijzig bestaande collega's, voeg collega's toe, nodig collega's uit of genereer een registratielink.</p>
        </header>;
        if(window.location.href.includes('toevoegen')){
            if(this.props.auth.user.superuser){
                header = <header className="navigation--header">
                    <h3 className="section-title">Collega's toevoegen</h3>
                </header>
            }
            else{
                header = <header className="navigation--header">
                    <h3 className="section-title">Gegevens wijzigen</h3>
                </header>
            }
        }
        else if(window.location.href.includes('overzicht') || window.location.href.includes('wijzigen')){
            header = <header className="navigation--header">
                <h3 className="section-title">Collega's wijzigen</h3>
            </header>
        }
        else if(window.location.href.includes('uitnodigen')){
            header = <header className="navigation--header">
                <h3 className="section-title">Collega's uitnodigen</h3>
            </header>
        }
        else if(window.location.href.includes('registratielink')){
            header = <header className="navigation--header">
                <h3 className="section-title">Registratielink genereren</h3>
            </header>
        }
        return(
            <div className="container container--header">
                <div className="navigation">
                    <nav>
                        {nav}
                    </nav>
                    {header}
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps,{})(ColleagueHeader);
