import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {generateLink} from '../../actions/inviteActions'
import ColleagueHeader from "./ColleagueHeader";

class GenerateLink extends Component{
    constructor(props){
        super(props);
        this.state ={
            expIndex: 2,
            link : ''
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.invite.link !== this.props.invite.link){
            this.setState({
                link : nextProps.invite.link
            })
        }
    }

    copyToClipboard = () => {
        document.getElementById('linkField').select();
        document.execCommand('copy');
    }

    getExp = (index) => {
        if(index === 0){
            return 'in 1 dag';
        }
        else if(index === 1){
            return 'in 7 dagen';
        }
        else if(index === 2){
            return 'in 30 dagen';
        }
        else if(index === 3){
            return 'niet';
        }
    };

    resetLink = () => {
        this.setState({
            link: ''
        })
    };

    onFieldChange = (event) => {
        this.setState({
            expIndex: event.nativeEvent.target.selectedIndex
        })
    };

    submit = () => {
        this.props.generateLink(this.state.expIndex)
    };

    render(){
        let content;
        if(this.state.link === ""){
            content =
                <select name="select_shared_invite_expiration" onChange={this.onFieldChange} className="sharelink--selector">
                    <option value="1">Verloopt in 1 dag</option>
                    <option value="7">Verloopt in 7 dagen</option>
                    <option selected="selected" value="30">Verloopt in 30 dagen</option>
                    <option value="365000">Altijd geldig</option>
                </select>
        }
        else{
            content =
                (<div className="invitelink--message">
                    <div className="invitelink--container">
                        <input name="" type="text" id="linkField" className="invitelink"
                               data-invite-url="" readOnly="" value={this.state.link}
                               onClick="this.select();"/>
                        <button type="" onClick={() => {this.copyToClipboard()}}
                                className="button button--primary">
                            Kopiëren
                        </button>
                    </div>
                    <div className="invitelink--text">
                        <p>Deze link verloopt {this.getExp(this.state.expIndex)}. Deze link verwijderen of een andere link
                            aanmaken?</p>
                        <a href={"#/"} onClick={() => {this.resetLink()}} className="button button--text">Link deactiveren</a>
                    </div>
                </div>);
        }
        return(
            <div className="dashboard--main">
                <div className="dashboard--main-content">
                    <ColleagueHeader/>
                    <div className="container">
                        <div className="cards--wrapper">
                            <div className="card dashboard--card element--card">
                                <div className="card--header">
                                    <p>Stel in hoelang de link geldig is. Iedereen met deze link kan deelnemen aan
                                        bizzerd.
                                    </p>
                                </div>
                                <div className="card--main">
                                    {content}
                                </div>
                                <div className="card--footer">
                                    <button onClick={() => this.submit()} type="" className="button button--primary">
                                        Genereren
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
GenerateLink.propTypes = {
    generateLink: PropTypes.func.isRequired,
    invite: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return({
        invite: state.invite
    })
};

export default connect (mapStateToProps, {generateLink})(GenerateLink)