import React, {Component} from 'react'
import {connect} from "react-redux";
import {addPerson, addPersonExcellijst} from "../../actions/personActions";
import {getCurrentCompany, uploadExcellijst} from "../../actions/companyActions";
import PropTypes from "prop-types";
import ColleagueHeader from "./ColleagueHeader";
import CheckBoxTable from "react-table";

class AddColleague extends Component {
    constructor(props) {
        super(props);
        this.title = 'Collega\'s beheren';
        this.state = {
            filename: "",
            company: {},
            data: [],
            selected: {},
            selectAll: 0,
        };
    }

    componentDidMount() {
        this.props.getCurrentCompany();

        // if ((!this.props.bizzerd.bizzerds || this.props.bizzerd.bizzerds.length === 0) && this.props.user.user) {
        //     this.props.getBizzerds(this.props.user.user.company);
        // }
        // if (!this.props.auth.user.superuser) {
        //     this.props.getPersonByUser();
        // } else if (this.props.match.params.type !== undefined) {
        //
        //     if (this.props.match.params.type === 'id') {
        //         this.props.getPersonById(this.props.match.params.value1);
        //         this.props.getBizzerdByPersonId(this.props.match.params.value1);
        //     } else if (this.props.match.params.type === 'name') {
        //         let fullname = this.props.match.params.value1 + ' ' + this.props.match.params.value2;
        //         if (fullname.indexOf("undefined") !== -1) {
        //             fullname = undefined;
        //         }
        //         this.state.person.firstname = this.props.match.params.value1;
        //         this.state.person.lastname = this.props.match.params.value2;
        //         this.state.person.fullname = fullname;
        //     }
        // }
    }

    componentWillReceiveProps(nextProps) {

        // if (nextProps.person.person && nextProps.person.person !== this.state.person
        //     && Object.keys(nextProps.person.person).length > 0 && this.props.match.params.type === 'id') {
        //     this.setState({
        //         person: nextProps.person.person
        //     })
        // } else if (nextProps.person.person && this.state.person.id === undefined && this.props.match.params.type !== 'id') {
        //     this.setState({
        //         id: nextProps.person.person.id
        //     })
        // }
        // if (nextProps.person.person.email !== "" && !this.props.auth.user.superuser) {
        //     this.setState({person: nextProps.person.person});
        // }
        // if (nextProps.errors && Object.keys(nextProps.errors) > 0) {
        //     this.setState({
        //         person: nextProps.person.person
        //     })
        // }
        // if (nextProps.person.person.bizzerdName) {
        //     this.setState({
        //         person: nextProps.person.person
        //     })
        // }
        if (nextProps.company.company) {
            this.setState({
                company: nextProps.company.company
            })
        }
        if (nextProps.company.data) {
            this.setState({
                data: nextProps.company.data
            })
        }
    };

    onFileUpload = (file) => {
        const formData = new FormData();
        formData.append("file", file.target.files[0]);
        this.setState({
            filename: file.target.files[0].name
        });
        this.props.uploadExcellijst(formData, this.state.company._id);
    };

    componentWillUnmount() {

    };

    deleteFile = () => {
        this.setState({
            filename: "",
            data: {},
        })
    };

    toggleRow(bizzerdname) {
        const newSelected = Object.assign({}, this.state.selected);
        newSelected[bizzerdname] = !this.state.selected[bizzerdname];
        this.setState({
            selected: newSelected,
            selectAll: 2
        });
    }

    toggleSelectAll() {
        let newSelected = {};

        if (this.state.selectAll === 0) {
            this.state.data.forEach(x => {
                newSelected[x.bizzerdName] = true;
            });
        }

        this.setState({
            selected: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }

    handleClick = () => {
        let bizzerdnames = Object.keys(this.state.selected);
        let personList = this.state.data.filter(function (person) {
            return bizzerdnames.includes(person.bizzerdName);
        });
        this.props.addPersonExcellijst(personList)
        // for (const person of personList) {
        //     this.props.addPerson(person)
        // }
    };

    getPersonTable = () => {
        const colums = [
            {
                Header: "Personen",
                columns: [
                    {
                        id: "checkbox",
                        accessor: "",
                        Cell: ({original}) => {
                            return (
                                <input
                                    type="checkbox"
                                    className="checkbox"
                                    checked={this.state.selected[original.bizzerdName] === true}
                                    onChange={() => this.toggleRow(original.bizzerdName)}
                                />
                            );
                        },
                        Header: x => {
                            return (
                                <input
                                    type="checkbox"
                                    className="checkbox"
                                    width={45}
                                    checked={this.state.selectAll === 1}
                                    ref={input => {
                                        if (input) {
                                            input.indeterminate = this.state.selectAll === 2;
                                        }
                                    }}
                                    onChange={() => this.toggleSelectAll()}
                                />
                            );
                        },
                        sortable: false,
                        width: 45
                    }
                    ,
                    {
                        Header: "Voornaam",
                        id: "firstName",
                        accessor: d => d.firstname
                    },
                    {
                        Header: "Achternaam",
                        id: "lastName",
                        accessor: d => d.lastname
                    },
                    {
                        Header: "Volledige naam",
                        id: "fullName",
                        accessor: d => d.fullname
                    },
                    {
                        Header: "Functie",
                        id: "jobTitle",
                        accessor: d => d.jobtitle
                    },
                    {
                        Header: "Quote",
                        id: "quote",
                        accessor: d => d.quote
                    },
                    {
                        Header: "Bizzerd-Naam",
                        id: "bizzerdName",
                        accessor: d => d.bizzerdName
                    },
                    {
                        Header: "Email",
                        id: "email",
                        accessor: d => d.email
                    },
                    {
                        Header: "Telefoon",
                        id: "tel",
                        accessor: d => d.tel
                    },
                    {
                        Header: "Mobiel",
                        id: "mobile",
                        accessor: d => d.mobile
                    },
                    {
                        Header: "Adres",
                        id: "adresveld",
                        accessor: d => d.adresveld
                    },
                    {
                        Header: "Linkedin",
                        id: "linkedin",
                        accessor: d => d.linkedin
                    },
                    {
                        Header: "YouTube",
                        id: "youtube",
                        accessor: d => d.youtube
                    },
                    {
                        Header: "Facebook",
                        id: "facebook",
                        accessor: d => d.facebook
                    },
                    {
                        Header: "Twitter",
                        id: "twitter",
                        accessor: d => d.twitter
                    },
                    {
                        Header: "Website",
                        id: "website",
                        accessor: d => d.website
                    },
                    {
                        Header: "Instagram",
                        id: "instagram",
                        accessor: d => d.instagram
                    },
                    {
                        Header: "Pinterest",
                        id: "pinterest",
                        accessor: d => d.pinterest
                    },
                    {
                        Header: "Pasfoto",
                        id: "pasfoto",
                        accessor: d => d.pasfoto
                    }
                ]
            }];
        return (<div className="table--body">
            <div>
                <CheckBoxTable
                    ref={r => (this.checkboxTable = r)}
                    toggleSelection={this.toggleSelection}
                    selectAll={this.state.selectAll}
                    toggleAll={this.toggleAll}
                    selectType="checkbox"
                    isSelected={this.isSelected}
                    data={this.state.data}
                    keyField="id"
                    columns={colums}
                    defaultPageSize={5}
                    className="-striped -highlight"
                />
                <br/>
                <button style={{color: "#FFF", position: "absolute", left: 0}}
                        onClick={this.handleClick}>Opslaan
                </button>
            </div>
        </div>);
    };


    render() {
        let excellijst = <div><input className="content--pane fade show active" type={'file'} name={'file'}
                                     onClick={(event) => {
                                         event.target.value = null
                                     }}
                                     onInput={this.onFileUpload}/> {this.state.filename !== "" ? this.state.filename : ""}
        </div>;
        let verwijderen;
        if (this.state.filename !== "") {
            verwijderen = <button className={'button button--primary'} onClick={this.deleteFile}>Verwijderen</button>;
        }
        let personTable;
        if (this.state.data && this.state.data !== {}) {
            personTable = this.getPersonTable();
        }
        let colleagueHeader;
        colleagueHeader = (<ColleagueHeader/>);
        return (
            <div className={"dashboard--main"}>
                <div className="dashboard--main-content">
                    {colleagueHeader}
                    <div className={"container"}>
                        <div className="cards--wrapper">
                            <div className="card dashboard--card element--card handmatig">
                                <div className="card--header">
                                    <p>Excelijst Uploaden</p>
                                </div>
                                <div className="card--main">
                                    <div className="table--body">
                                        {excellijst}
                                        {verwijderen}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cards--wrapper">
                        <div className="card dashboard--card element--card wijzigen">
                            <div className="card--header">
                                <p>Gevonden collega's</p>
                            </div>
                            <div className="card--main">
                                <div className="table--body">
                                    {personTable}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


AddColleague.propTypes = {
    person: PropTypes.object.isRequired,
    addPerson: PropTypes.func.isRequired,
    uploadExcellijst: PropTypes.func.isRequired,
    getCurrentCompany: PropTypes.func.isRequired,
    addPersonExcellijst: PropTypes.func.isRequired

};

const mapStateToProps = (state) => {
    return ({
        person: state.persons,
        bizzerd: state.bizzerd,
        data: state.data,
        company: state.company,
        user: state.users,
        auth: state.auth,
        errors: state.errors
    })
};


export default connect(mapStateToProps, {
    addPerson, uploadExcellijst, getCurrentCompany, addPersonExcellijst
})(AddColleague);
