import React, {Component} from 'react'
import PersonalTab from "../colleagues/PersonalTab";
import ContactTab from "../colleagues/ContactTab";
import SocialTab from "../colleagues/SocialTab";
import Validator from 'validator';
import {connect} from "react-redux";
import {
    addPerson,
    checkPersonAndContinue,
    clearErrors,
    getPersonById,
    getPersonByUser,
    uploadPassportPhoto
} from "../../actions/personActions";
import {getBizzerdByPersonId} from "../../actions/bizzerdActions";
import {getBizzerds} from "../../actions/companyActions";
import PropTypes from "prop-types";
import {ErrorMessage} from "../common/AuthErrorComponent";
import bizzerdLogo from "../../assets/img/bizzerd_logo.png";

class AddColleague extends Component {
    constructor(props) {
        super(props);
        this.title = 'Collega\'s beheren';
        this.state = {
            person: {
                firstname: '',
                lastname: '',
                fullname: '',
                jobtitle: '',
                bizzerdName: '',
                email: '',
                tel: '',
                mobile: '',
                adresveld: '',
                linkedin: '',
                facebook: '',
                twitter: '',
                instagram: '',
                pinterest: '',
                website: '',
                bizzerd: ''
            },
            page: 0,
            editMode: true
        };
    }

    componentDidMount() {
        //If there is an ID in the URL, but not person was loaded yet.
        this.props.clearErrors();
        if ((!this.props.bizzerd.bizzerds || this.props.bizzerd.bizzerds.length === 0) && this.props.user.user) {
            this.props.getBizzerds(this.props.user.user.company);
        }
        this.setState({
            person: {
                ...this.state.person,
                email: this.props.auth.user.email,

            }
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.person.person.email !== "" && !this.props.auth.user.superuser) {
            this.setState({person: nextProps.person.person});
        }
        if (nextProps.errors && Object.keys(nextProps.errors) > 0) {
            this.setState({
                person: nextProps.person.person
            })
        }
        if (nextProps.person.person) {
            if (this.validatePerson(nextProps.person.person)){
                this.setState({
                    person: nextProps.person.person
                })
            }
        }
    };

    deletePhoto = () => {
        this.setState({
            person: {
                ...this.state.person,
                pasfoto: ''
            }
        });
    };

    editMode = () => {
        if (this.state.editMode) {
            this.onSaveChanges();
        }
        this.setState({
            editMode: !this.state.editMode
        })
    };

    onVerder = (e) => {

        if (this.state.person && this.validatePerson(this.state.person)) {
            this.props.checkPersonAndContinue(this.props.history);

        }else{
        }

    };

    onChange = (e) => {
        e.preventDefault();
        let fullname = this.createFullName(e, this.state.person.fullname);
        this.setState({
            person: {
                ...this.state.person,
                [e.target.name]: e.target.value,
                fullname: fullname
            }
        })
    };

    validatePerson = (person) => {
        let valid = true;
        if (!person.email || !Validator.isEmail(person.email)) {
            valid = false;
        }
        if (!person.firstname) {
            valid = false;
        }
        if (!person.bizzerdName || (person.bizzerdName && person.bizzerdName.length < 2) || (person.bizzerdName && !/[a-z0-9]+/.test(person.bizzerdName)) || (person.bizzerdName && person.bizzerdName.indexOf(" ") !== -1)) {
            valid = false;
        }
        if (!person.bizzerdName) {
            valid = false;
        }
        if (person.website && !Validator.isURL(person.website)) {
            valid = false;
        }
        if (person.facebook && !Validator.isURL(person.facebook)) {
            valid = false;
        }
        if (person.linkedin && !Validator.isURL(person.linkedin)) {
            valid = false;
        }
        if (person.youtube && !Validator.isURL(person.youtube)) {
            valid = false;
        }
        if (person.twitter && !Validator.isURL(person.twitter)) {
            valid = false;
        }
        if (person.instagram && !Validator.isURL(person.instagram)) {
            valid = false;
        }
        if (person.pinterest && !Validator.isURL(person.pinterest)) {
            valid = false;
        }

        return valid;
    };

    createFullName = (e, fullname) => {
        if (e.target.name === 'firstname') {
            if (this.state.person.lastname) {
                return e.target.value + " " + this.state.person.lastname
            }
        } else if (e.target.name === 'lastname') {
            return this.state.person.firstname + " " + e.target.value
        } else if (e.target.name === 'fullname') {
            return e.target.value
        } else {
            return fullname
        }
    };

    onSaveChanges = () => {
        const newPerson = this.state.person;
        if (!this.state.person.bizzerd && this.props.bizzerd.bizzerds.length > 0) {
            newPerson.bizzerd = this.props.bizzerd.bizzerds[0]._id;
        }
        if (this.props.match.params.id !== undefined) {

            newPerson._id = this.props.match.params.id
        }
        this.props.addPerson(newPerson);
    };

    onClick = (page) => {
        this.setState({
            page: page
        })
    };

    onImageUpload = (file) => {
        const formData = new FormData();
        formData.append("file", file.target.files[0]);
        // console.log(this.props.person);
        this.props.uploadPassportPhoto(formData, this.props.person.person._id);
    };

    render() {
        let tab;
        let verder;
        let wijzig;
        let message;
        const {errors} = this.props;
        if (Object.keys(errors).length > 0) {
            let text = errors[Object.keys(errors)[0]];
            message = <ErrorMessage errors={text}/>
        }
        if (this.props.person.photoSuccess) {
            let text = 'Je pasfoto is opgeslagen';
            message = (<div className="alert--succes">
                <svg version="1.1" className="has-solid " viewBox="0 0 36 36"
                     preserveAspectRatio="xMidYMid meet" focusable="false" aria-hidden="true"
                     role="img" width="32"
                     height="32" fill="currentColor">
                    <path className="clr-i-outline clr-i-outline-path-1"
                          d="M18,2A16,16,0,1,0,34,18,16,16,0,0,0,18,2Zm0,30A14,14,0,1,1,32,18,14,14,0,0,1,18,32Z"/>
                    <path className="clr-i-outline clr-i-outline-path-2"
                          d="M28,12.1a1,1,0,0,0-1.41,0L15.49,23.15l-6-6A1,1,0,0,0,8,18.53L15.49,26,28,13.52A1,1,0,0,0,28,12.1Z"/>
                    <path className="clr-i-solid clr-i-solid-path-1"
                          d="M18,2A16,16,0,1,0,34,18,16,16,0,0,0,18,2ZM28.45,12.63,15.31,25.76,7.55,18a1.4,1.4,0,0,1,2-2l5.78,5.78L26.47,10.65a1.4,1.4,0,1,1,2,2Z"
                          style={{display: "none"}}/>
                </svg>
                <span className="c-alert__message">{text}</span>
            </div>)
        }
        if (!this.state.editMode) {
            verder = (<button onClick={() => {
                this.onVerder()
            }} type="" className="button button--try">
                {"Verder"}
            </button>)
        }
        if (this.state.page !== 3) {
            wijzig = (<button onClick={() => this.editMode()} type="" className="button button--primary">
                {this.state.editMode ? "Opslaan" : "Wijzigen"}
            </button>);
        }
        if (this.props.match.params.id !== undefined && Object.keys(this.props.person.person) === 0) {
            tab = (
                <h4>Loading...</h4>
            )
        } else {

            if (this.state.page === 0) {
                tab = (
                    <PersonalTab editMode={this.state.editMode} onChange={this.onChange} onCancel={this.onCancel}
                                 onSubmit={this.onPageChange}
                                 person={this.state.person} onImageUpload={this.onImageUpload}
                                 deletePhoto={this.deletePhoto}/>
                )
            } else if (this.state.page === 1) {
                tab = (
                    <ContactTab editMode={this.state.editMode} onChange={this.onChange} onCancel={this.onCancel}
                                onSubmit={this.onPageChange}
                                person={this.state.person}/>
                )
            } else if (this.state.page === 2) {
                tab = (
                    <SocialTab editMode={this.state.editMode} onChange={this.onChange} onCancel={this.onCancel}
                               onSubmit={this.onPageChange}
                               person={this.state.person}/>
                )
            }
        }
        return (

            <div className={'wrapper'}>
                <div className="card">
                    <h2>Vul hier je gegevens in.</h2>

                    <ul className="tab--view">
                        <li className={"tab--view-tab " + (this.state.page === 0 ? 'selected' : 'unselected')}>
                            <a className="tab--tab-name" href={"#/"}
                               onClick={() => this.onClick(0)}>Persoonsgegevens</a>
                        </li>
                        <li className={"tab--view-tab " + (this.state.page === 1 ? 'selected' : 'unselected')}>
                            <a className="tab--tab-name" href={"#/"}
                               onClick={() => this.onClick(1)}>Contactgegevens</a>
                        </li>
                        <li className={"tab--view-tab " + (this.state.page === 2 ? 'selected' : 'unselected')}>
                            <a className="tab--tab-name" href={"#/"} onClick={() => this.onClick(2)}>Social
                                media</a>
                        </li>
                    </ul>
                    <div className="card--main">
                        {tab}
                    </div>
                    {message}
                    <div className="card--footer collega--footer">
                        {wijzig}
                        {verder}
                    </div>
                </div>
                <div className="wrapper--footer">
                    <p>Versie: 2.0. Ondervind je problemen? Neem dan contact op met <a
                        href="mailto:support@bizzerd.com" target="_blank" rel="noopener noreferrer">support@bizzerd.com</a>.</p>
                </div>
                <div className="wrapper--logo">
                    <a href="https://www.bizzerd.com" target="_blank" rel="noopener noreferrer">
                        <img src={bizzerdLogo} alt="Klik hier om naar de homepage te gaan"/>
                    </a>
                </div>
            </div>
        )
    }
}

AddColleague.propTypes = {
    person: PropTypes.object.isRequired,
    addPerson: PropTypes.func.isRequired,
    getPersonById: PropTypes.func.isRequired,
    getBizzerdByPersonId: PropTypes.func.isRequired,
    getPersonByUser: PropTypes.func.isRequired,
    checkPersonAndContinue: PropTypes.func.isRequired,
    uploadPassportPhoto: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return ({
        person: state.persons,
        bizzerd: state.bizzerd,
        user: state.users,
        auth: state.auth,
        errors: state.errors
    })
};


export default connect(mapStateToProps, {
    addPerson, getPersonById, getBizzerdByPersonId, getPersonByUser,
    uploadPassportPhoto, getBizzerds, clearErrors, checkPersonAndContinue
})(AddColleague);