import React, {Component} from 'react'
import BizzerdTypes from "../../designtool/BizzerdTypes";
import Resizable from 're-resizable'

class EditImage extends Component {
    render() {
        const resizeStyle = {
            position: 'absolute',
            left: this.props.layout.left + 'px',
            top: this.props.layout.top + 'px',
            zIndex: this.props.layout.zindex,
            objectFit: "cover",
        };
        const divStyle = {
            width: this.props.layout.width,
            height: this.props.layout.height,
            backgroundImage: `url(${this.props.layout.image})`,
            backgroundSize: '100% 100%',
        };
        if (this.props.layout.rotation !== undefined) {
            resizeStyle.transform = "rotate(" + this.props.layout.rotation + "deg)"
        }
        if (this.props.active) {
            divStyle.outline = '2px solid #000'
        }
        let rotationelement;
        if (this.props.active && !this.props.isGroup) {
            rotationelement = <div onMouseDown={() => {
                this.props.onStartRotation()
            }}
                                   style={{
                                       height: '4px', width: '4px', top: '-10px', left: this.props.layout.width / 2 - 2,
                                       position: 'absolute', backgroundColor: '#00ff00'
                                   }}/>
        }
        return (
            <Resizable enable={{
                top: true,
                right: true,
                bottom: true,
                left: true,
                topRight: false,
                bottomRight: false,
                bottomLeft: false,
                topLeft: false
            }} onResizeStart={() => this.props.onResizeStart(this.props.index, BizzerdTypes.IMAGE)}
                       onResize={(e, direction, ref, d) => {
                           this.props.onResize(e, direction, ref, d, this.props.index, BizzerdTypes.IMAGE)
                       }}
                       onResizeStop={(e, direction, ref, d) => {
                           this.props.onResizeStop(e, direction, ref, d, this.props.index, BizzerdTypes.IMAGE)
                       }}
                       id={this.props.id} style={resizeStyle}
                       size={{width: this.props.layout.width, height: this.props.layout.height}}
                       onMouseDown={(e) => {
                           e.stopPropagation();
                           this.props.onChange(e, this.props.index, BizzerdTypes.IMAGE)
                       }}
            >
                <div style={{position: "relative"}}>
                    <div style={divStyle} id={this.props.id}/>
                    {rotationelement}
                </div>
            </Resizable>
        )
    }
}

export default EditImage;
