import React, {Component} from 'react';
import Bizzerd from "./Bizzerd";
import PropTypes from 'prop-types'
import {getBizzerdById, getBizzerdByPersonId} from '../../actions/bizzerdActions';
import {getPersonById, getPersonByUser, getVcardByPerson} from '../../actions/personActions';
import {addStat} from '../../actions/statsActions';
import {connect} from 'react-redux';
import isEmpty from './../../validation/is-empty';
import {PlaceholderPerson} from "../common/PlaceholderPerson";
import {getTranslation} from "../../actions/translationActions";


class DisplayBizzerd extends Component {

    componentDidMount() {
        //Load from ID in URL
        if (this.props.match !== undefined) {
            this.props.getBizzerdByPersonId(this.props.match.params.id);
            this.props.getPersonById(this.props.match.params.id);
        }
        //Load from personID in props
        else if (this.props.personId !== undefined) {
            this.props.getBizzerdByPersonId(this.props.personId);
            this.props.getPersonById(this.props.personId);
            if (this.props.personId && false)
                this.props.getTranslation(this.props.personId)

        }
        //Load from BizzerdID in Props with Placeholder content
        else if (this.props.bizzerdId !== undefined) {
            this.props.getBizzerdById(this.props.bizzerdId);
            if (this.props.personContent === undefined) {
                this.setState({
                    person: PlaceholderPerson,
                    placeholder: true
                })
            } else {
                if (this.props.personContent && this.props.personContent._id  && false)
                    this.props.getTranslation(this.props.personContent._id)
                this.setState({
                    person: this.props.personContent,
                    placeholder: false
                })
            }
        }
        //Bizzerd in Props either with or without person.
        else if (this.props.bizzerdLayout !== undefined) {
            if (this.props.personContent === undefined) {
                this.setState({
                    bizzerd: this.props.bizzerdLayout,
                });
                this.props.getPersonByUser();

            } else {

                if (this.props.personContent && this.props.personContent._id  && false) {
                    this.props.getTranslation(this.props.personContent._id)
                    // console.log("Get translation 1")
                    // console.log(this.props.personContent)
                }
                this.setState({
                    bizzerd: this.props.bizzerdLayout,
                    person: this.props.personContent
                });
            }
        }
    }

    loadVcard = (nextProps) => {
        this.setState({downloaded:true})
        let res = nextProps.data.data.data;
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', this.state.person.fullname + `.vcf`);
        // 3. Append to html page
        document.body.appendChild(link);
        // 4. Force download
        link.click();
        // 5. Clean up and remove the link
        // console.log(link);
        link.parentNode.removeChild(link);
        if (window.location.href.includes("?vcard=true")) {
            // window.location.href = window.location.href.replace("?vcard=true", "");
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.stats && nextProps.stats.success){
            this.changeStatStatus()
        }
        if (window.location.href.includes("?vcard=true", 0) && this.state.person._id && !nextProps.data.data.status) {
            this.props.getVcardByPerson(this.state.person._id);
        }
        if (nextProps.data.data && nextProps.data.data.status && this.state.person._id && window.location.href.includes("?vcard=true", 0) && !this.state.downloaded) {
            this.loadVcard(nextProps)
        }
        if (nextProps.personContent !== this.state.person) {
            this.setState({
                person: nextProps.personContent
            })
            if (nextProps.personContent && nextProps.personContent._id  && false) {
                this.props.getTranslation(nextProps.personContent._id)
            }
        }
        if (nextProps.bizzerd !== undefined && this.props.bizzerdLayout === undefined) {
            if (nextProps.bizzerd.bizzerd !== this.state.bizzerd) {
                this.setState({
                    bizzerd: nextProps.bizzerd.bizzerd
                })
            }
        }
        if (nextProps.bizzerdId !== this.props.bizzerdId) {
            this.props.getBizzerdById(nextProps.bizzerdId);
        }

        if (nextProps.person !== undefined && this.props.personContent === undefined && !this.state.placeholder) {
            this.setState({
                person: nextProps.person.person
            })
            if (nextProps.personContent && nextProps.personContent._id  && false) {
                this.props.getTranslation(nextProps.personContent._id)
            }

        } else if (nextProps.bizzerdLayout !== undefined) {
            if (nextProps.personContent === undefined) {
                this.setState({
                    bizzerd: nextProps.bizzerdLayout,
                    person: PlaceholderPerson,
                    placeholder: true
                });
            } else {
                this.setState({
                    bizzerd: nextProps.bizzerdLayout,
                    person: nextProps.personContent
                });
            }
        }
        if (nextProps.translations) {
            this.setState({translations: nextProps.translations.translations})
        }
    };

    constructor(props) {
        super(props);
        this.state =
            {
                // realWidth: props.realWidth ? 640 : props.realWidth,
                downloaded: false,
                bizzerd: {},
                didStats: false,
                person: {
                    firstname: 'Yannick',
                    lastname: 'Mijsters',
                    functionTitle: 'CTO',
                    tel: '+31 (0)88 277 22 22',
                    email: 'yannick@bizzerd.com',
                    address: 'Bilthoven: Rembrandtlaan 24',
                    website: 'https://www.bizzerd.com/'
                },
                placeholder: false,
                translations: [],
                currentTranslation: 0,
                translationActive: false,
            };
    }

    resize = (value) => {
        let realWidth = 640;
        if (this.props.realWidth !== undefined) {
            realWidth = this.props.realWidth;
        }
        return value * realWidth / 640;
    };

    changeStatStatus = () =>{
        this.setState({didStats: true})
    }


    render() {
        const {bizzerd, person, translations} = this.state;
        let bizzerdDisplay;
        if (isEmpty(bizzerd)) {
            bizzerdDisplay = <h4>Loading....</h4>
        } else {
            if (this.props.personContent) {
                if (!this.props.personContent.pasfoto && !this.props.personContent.base64pasfoto) {
                    if (this.props.bizzerdLayout) {
                        this.props.bizzerdLayout.shape.forEach(shape => {
                            if (shape.type === "photo") {
                                shape.height = 0;
                                shape.width = 0;
                            }
                        })
                    }
                }
            }
            bizzerdDisplay = <Bizzerd shouldBeClickable={this.props.shouldBeClickable} style={{display: 'inline-block'}} bizzerdLayout={bizzerd} resize={this.resize}
                                      personContent={person} translations={translations} vcard={this.vcard}/>;
            if (!this.state.didStats) {
                this.props.addStat(person, window.location.href)
            }


        }
        return (
            <div>
                {bizzerdDisplay}
            </div>
        )
    }
}

DisplayBizzerd.propTypes = {
    bizzerd: PropTypes.object.isRequired,
    person: PropTypes.object.isRequired,
    getBizzerdByPersonId: PropTypes.func.isRequired,
    getPersonByUser: PropTypes.func.isRequired,
    getPersonById: PropTypes.func.isRequired,
    getVcardByPerson: PropTypes.func.isRequired,
    getBizzerdById: PropTypes.func.isRequired,
    addStat: PropTypes.func.isRequired,
    personId: PropTypes.number,
    realWidth: PropTypes.number,
    bizzerdId: PropTypes.string,
    bizzerdLayout: PropTypes.object,
    personContent: PropTypes.object,
    translations: PropTypes.object

};

const mapStateToProps = (state) => ({
    bizzerd: state.bizzerd,
    data: state.data,
    person: state.persons,
    stats: state.stats,
    translations: state.translations
});

export default connect(mapStateToProps, {
    getBizzerdById,
    addStat,
    getBizzerdByPersonId,
    getPersonById,
    getPersonByUser,
    getVcardByPerson,
    getTranslation,
})(DisplayBizzerd);
