import React, {Component} from 'react';
import Icon from "./bizzerdComponents/Icon";
import TextLine from "./bizzerdComponents/TextLine";
import Button from "./bizzerdComponents/Button";
import BizzerdTypes from "../designtool/BizzerdTypes";
import Image from "./bizzerdComponents/Image";
import isEmpty from "../../validation/is-empty";
import Shape from "./bizzerdComponents/Shape";

const skippableFields = ["companyname", "fullname", "firstname", "lastname", "jobtitle", "adresveld", "extratext", 'quote', 'prefix', 'appendix', 'prefix_fullname','prefix_lastname_appendix', 'prefix_fullname_appendix','fullname_appendix'];
class Bizzerd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTranslation: 0,
            translationActive: false,
        }
    }

    walkThroughTranslation = () => {
        this.setState({
            binaryTranslation: this.props.translations.length === 1
        })
        if (this.state.binaryTranslation) {
            this.setState({translationActive: !this.state.translationActive})
        } else {
            this.setState({currentTranslation: this.state.currentTranslation + 1, translationActive: true});
            if (this.props.translations.length - 1 === this.state.currentTranslation) {
                this.setState({currentTranslation: 0, translationActive: true})
            }
        }
    }

    getLink = (link, person, iconObject) => {
        let value = '';
        let dataSource = person
        if (this.state.translationActive && false) {
            dataSource = this.props.translations[this.state.currentTranslation]
        }
        // if (["linkedin","facebook","twitter","instagram","pinterest","googlemaps", "website","email","tel","mobile","delen",'opslaan'].includes(iconObject.link))
        if (iconObject.link)
            link = iconObject.link
        if (link !== undefined) {
            if (link === "googlemaps") {
                if (dataSource.adresveld)
                    value = "https://www.google.com/maps/place/" + dataSource.adresveld.replace("\n", ' ')
            } else if (link === 'email') {
                value = 'mailto:' + dataSource.email
            } else if (link === 'translation') {
                value = this.walkThroughTranslation
            } else if (link === 'tel') {
                value = 'tel:' + (dataSource.tel ? dataSource.tel.replace(/[^0-9.+]/g, '') : dataSource.tel);
            } else if (link === 'mobile') {
                value = 'tel:' + (dataSource.mobile ? dataSource.mobile.replace(/[^0-9.+]/g, '') : dataSource.mobile);
            } else if (link === 'delen') {
                value = window.location.href + '/deel'
            } else if (link === 'opslaan') {
                value = '/api/dataSource/vcard/' + person._id;
            } else if (link) {
                switch (link) {
                    case "facebook":
                        value = dataSource.facebook;
                        break;
                    case "twitter":
                        value = dataSource.twitter;
                        break;
                    case "linkedin":
                        value = dataSource.linkedin;
                        break;
                    case "website":
                        value = dataSource.website;
                        if (value && !value.includes('http') && (!value.includes('https') && (!value.includes("www.")))) {
                            value = 'https://www.' + value;
                        }
                        break;
                    case "instagram":
                        value = dataSource.instagram;
                        break;
                    case "pinterest":
                        value = dataSource.pinterest;
                        break;
                    case "vimeo":
                        value = iconObject.link;
                        break;
                    default:
                        if (link.includes('http')) {
                            value = link
                        } else {
                            value = 'http://' + link
                        }
                        skippableFields.forEach((type) => {
                            if (type === link) {
                                value = '';
                            }
                        })
                }
                if (value && !value.includes('http')) {
                    value = 'http://' + value;
                }

            } else {
                value = '';

            }

        }
        return value;
    };

    getText = (text, person) => {
        let value = '';
        if (text.type === 'extratext') {
            value = text.text;
        } else if (text.type === "translation" && this.props.translations && this.props.translations.length > 0) {
            let currentTranslationId = this.props.translations[this.state.currentTranslation]._id
            for (let translationIndex in this.props.personContent.translations) {
                if (currentTranslationId === this.props.personContent.translations[translationIndex].translationId) {
                    value = this.props.personContent.translations[translationIndex].abbreviation
                }
                if (!this.state.translationActive) {
                    value = "EN"
                }
            }
        } else {
            if (this.state.translationActive) {
                if (text.type !== 'companyname') {
                    value = this.props.translations[this.state.currentTranslation][text.type]
                } else {
                    value = this.props.personContent.companyname
                }
            }
            else {
                value = person[text.type];
                if (text.type === "prefix_fullname") {
                    value = (person["prefix"] ? person["prefix"] : "");
                    value += " " + (person["fullname"] ? person["fullname"] : "");
                } else if (text.type === "prefix_fullname_appendix") {
                    value = (person["prefix"] ? person["prefix"] : "");
                    value += " " + (person["fullname"] ? person["fullname"] : "");
                    value += " " + (person["appendix"] ? person["appendix"] : "");
                }
                else if (text.type === "fullname_appendix") {
                    value = (person["fullname"] ? person["fullname"] : "");
                    value += " " + (person["appendix"] ? person["appendix"] : "");
                }else if (text.type === "prefix_lastname_appendix") {
                    value = (person["prefix"] ? person["prefix"] : "");
                    value += " " + (person["lastname"] ? person["lastname"] : "");
                    value += " " + (person["appendix"] ? person["appendix"] : "");
                }
            }
        }
        return value;
    }

    render() {
        const bizzerdStyle = {
            position: 'relative',
            backgroundSize: 'contain',
            overflow: 'hidden',
            borderRadius: '8px',
            boxShadow: '#0e1e251f 0px 2px 4px 0px'
        };
        if (!isEmpty(this.props.bizzerdLayout) && this.props.bizzerdLayout.background.length > 0) {
            bizzerdStyle.height = this.props.resize(this.props.bizzerdLayout.background[0].height) + "px";
            bizzerdStyle.width = this.props.resize(this.props.bizzerdLayout.background[0].width) + "px";
            bizzerdStyle.backgroundColor = this.props.bizzerdLayout.background[0].backgroundColor;
        }
        const personIcons = this.props.bizzerdLayout[BizzerdTypes.ICON].filter(icon => {
            return this.getLink(icon.type, this.props.personContent, icon) !== '';
        });
        let icons = personIcons.map((value, index) => {
            return <Icon shouldBeClickable={this.props.shouldBeClickable} id={value.name} key={index}
                         resize={this.props.resize} layout={value}
                         link={this.getLink(value.type, this.props.personContent, value)}
                         bizzerd={this.props.bizzerdLayout} person={this.props.personContent}
                         iconset={this.props.bizzerdLayout.iconset}/>
        });
        const personTexts = this.props.bizzerdLayout[BizzerdTypes.TEXT].filter(text => {
            // eslint-disable-next-line
            if (text.type === "prefix_fullname") {
                return this.props.personContent["fullname"] !== undefined && this.props.personContent["fullname"] !== ''
            }
            if (text.type === "prefix_fullname_appendix") {
                return this.props.personContent["fullname"] !== undefined && this.props.personContent["fullname"] !== ''
            }
            if (text.type === "fullname_appendix") {
                return this.props.personContent["fullname"] !== undefined && this.props.personContent["fullname"] !== ''
            }
            if (text.type === "prefix_lastname_appendix") {
                return this.props.personContent["fullname"] !== undefined && this.props.personContent["fullname"] !== ''
            }
            return this.props.personContent[text.type] !== undefined && this.props.personContent[text.type] !== '' || text.type === 'extratext' || text.type === "translation";
        });
        let texts = personTexts.map((value, index) => {
            return <TextLine shouldBeClickable={this.props.shouldBeClickable} id={value.name} key={index}
                             link={this.getLink(value.type, this.props.personContent, value)}
                             resize={this.props.resize} text={this.getText(value, this.props.personContent)}
                             layout={value}
            />
        });
        let buttons = this.props.bizzerdLayout[BizzerdTypes.BUTTON].map((value, index) => {
            return <Button shouldBeClickable={this.props.shouldBeClickable} id={value.name} key={index} layout={value}
                           resize={this.props.resize}
                           link={this.getLink(value.link, this.props.personContent, value)}/>
        });
        let images = this.props.bizzerdLayout[BizzerdTypes.IMAGE].map((value, index) => {
            // console.log(value)

            return <Image shouldBeClickable={this.props.shouldBeClickable} id={value.name} key={index} layout={value}
                          resize={this.props.resize}
                          person={this.props.personContent}
                          link={this.getLink(value.link, this.props.personContent, value)}

            />
        });
        //rijks: '60f049adc644fc0017eb01b6'
        let companies_to_skip = []
        if (!companies_to_skip.includes(this.props.personContent.company)) {
            let i =
                {
                    height: 24,
                    image: "https://app.bizzerd.com/images/mobicard_i.png",
                    left: 610,
                    link: "website",
                    name: "9",
                    top: 975,
                    width: 24,
                    zindex: "10"
                }
            images.push(<Image id={'i'} shouldBeClickable={true} key={this.props.bizzerdLayout[BizzerdTypes.IMAGE].length + 1}
                               layout={i}
                               resize={this.props.resize} person={this.props.personContent}
                               link={'https://www.bizzerd.com'}/>);
        }
        let shapes = this.props.bizzerdLayout[BizzerdTypes.SHAPE].map((value, index) => {
            return <Shape id={value.name} key={index} layout={value} resize={this.props.resize}
                          link={(this.props.personContent[value.type] !== undefined ?
                              this.props.personContent[value.type] : 'test123')}
                          photo={this.props.personContent.base64pasfoto ? this.props.personContent.base64pasfoto : this.props.personContent.pasfoto}/>
        })
        return (
            <div id={'bizzerd'} style={bizzerdStyle} onMouseMove={this.props.onMouseMove}>
                {icons}
                {texts}
                {buttons}
                {images}
                {shapes}
            </div>
        )
    }
}

export default Bizzerd
