import React from 'react';

export const AddTranslationPopUp = (props) => (
        <div className="pop-over-container">
            <div className="pop-over">
                <h3>bizzerd op maat</h3>
                <p>Welke taal wil je toevoegen???</p>
                <input type="text" id="translation_name" name="translation_name"/><br/>
                <p>Welke afkorting moet worden laten zien?</p>
                <input type="text" id="translation_abbreviation" name="translation_abbreviation"/><br/>
                <button type="button" onClick={props.onDismiss} className="button button--text">Annuleren</button>
                <button type="button" onClick={() => {
                    let language_name = document.getElementById("translation_name").value
                    let language_abbreviation = document.getElementById("translation_abbreviation").value
                    if (language_name !== "" && language_name !== undefined && language_abbreviation !== "" && language_abbreviation !== undefined) {
                        props.addLanguage(language_name,language_abbreviation)
                    }
                }
                } className="button button--text">Toevoegen
                </button>
            </div>
        </div>
    )
;

