import React, {Component} from 'react'
import TextFieldGroup from '../common/TextFieldGroup';
import {Link} from 'react-router-dom'
import {login} from "../../actions/userActions";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {ErrorMessage} from "../common/AuthErrorComponent";
import {OpMaatPopOver} from "../common/OpMaatPopOver";
import * as qs from "query-string";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            state_id:"1987",
            nonce: this.uuidv4(),
            id_token:undefined
        }
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({errors: nextProps.errors});
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    };

    onSubmit = (e) => {
        e.preventDefault();
        this.props.login({email: this.state.username, password: this.state.password});
    };

    dismissPopOver = () => {
        let errors = this.state.errors
        errors.emailExistsInOpMaat = undefined;
        this.setState({errors:errors})
        this.forceUpdate()
    };

    uuidv4 = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            // eslint-disable-next-line
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    };

    componentDidMount() {
        if (window.location.href.includes('#')){
            let parsed_url = qs.parse(window.location.href.split('#')[1]);
            if (parsed_url.state === this.state.state_id){
                this.setState({id_token:parsed_url.id_token});
            }
        }
    }

    clicked_Rijksmuseum = () =>{
        window.location.href = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?' +
            'client_id=f27d1da7-6f71-4ae7-bcd3-1d7f00139923&' +
            'response_type=id_token&' +
            'redirect_uri=https://app2.bizzerd.com/ssoauth&' +
            'response_mode=fragment&' +
            'scope=email+openid&' +
            'state=' + this.state.state_id + '&' +
            'nonce=' + this.state.nonce;
    }

    render() {
        const {errors} = this.state;
        let error;

        if(errors !== undefined){
            if(errors.emailExistsInOpMaat !== undefined && errors.emailExistsInOpMaat){
                error = <OpMaatPopOver email={this.state.username} onDismiss={this.dismissPopOver}/>
            } else if (errors.errors !== undefined){
                error = <ErrorMessage errors={errors.errors}/>
            }
        }

        /** for development. Uncommend above and delete below before release. */
        // error = <OpMaatPopOver onDismiss={this.dismissPopOver}  email={this.state.username}/>
        /** end of dev section */

        return (
            <div className="login--left">
                <ul className="tab--view">
                        <li className={"tab--view-tab selected"}>
                            <a className="tab--tab-name" href={"#/"}>bizzerd</a>
                        </li>
                        <li className={"tab--view-tab unselected"}>
                            <a className="tab--tab-name" href={"https://app.bizzerd.com/s_edit.php"}>bizzerd op maat</a>
                        </li>
                        <li className={"tab--view-tab unselected"}>
                            <a className="tab--tab-name" href={"#/"} onClick={this.clicked_Rijksmuseum}>Single Sign On</a>
                        </li>
                    </ul>
                <h3 className="login--title">Inloggen</h3>
                {/* <p className="login--text">Log hier in met je e-mailadres en je wachtwoord.</p> */}
                <form onSubmit={this.onSubmit} className={'form-group'}>
                    <TextFieldGroup
                        labeltop={"E-mailadres"}
                        placeholder={"Je e-mailadres"}
                        autofocus={"autofocus"}
                        name={'username'}
                        type={'email'}
                        value={this.state.username}
                        onChange={this.onChange}
                        error={errors}/>
                    <TextFieldGroup
                        labeltop={"Wachtwoord"}
                        placeholder={"Wachtwoord"}
                        name={'password'}
                        type={'password'}
                        value={this.state.password}
                        onChange={this.onChange}/>
                    {error}
                    <div className="form-group__footer">
                        <button type="submit" className="button button--primary">
                            Inloggen
                        </button>
                    </div>
                    <div className="login__forgot-password">
                        <span>
                            <Link to="/login/request">Wachtwoord vergeten</Link>
                        </span>
                        <span>
                            <Link to="https://www.bizzerd.com/gratisdigitaalvisitekaartje">Nieuwe gebruiker?</Link>
                        </span>
                    </div>
                </form>
            </div>

            );
    }
}
Login.propTypes = {
    login: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(mapStateToProps, {login})(Login);
