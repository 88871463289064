import {GET_TEMPLATES} from "../actions/types";

const initialState = {
    templates: [],
    loading: false
};

export default function(state=initialState, action){
    switch(action.type){
        case GET_TEMPLATES:
            return{
                ...state,
                templates: action.payload,
                loading: false
            };
        default:
            return state;
    }
}