import axios from "axios/index";
import {CLEAR_ERRORS, GET_ERRORS, POST_STAT,} from "./types";

export const addStat = (person) => dispatch => {
    axios.post(`/api/stats/view`, person)
        .then(res => {
            dispatch({
                type: POST_STAT,
                payload: res.data
            })
        })
        .catch(err => {
                // console.log(err)
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response && err.response.data ? err.response.data: undefined
                })
            }
        )
};


//Person loading
export const clearErrors = () => {
    return {
        type: CLEAR_ERRORS
    }
};
