import BizzerdTypes from "../../BizzerdTypes";

const fieldTypes = {
    [BizzerdTypes.TEXT]: [
        {
            naam: 'Voornaam',
            type: 'firstname',
            placeholder: 'Voornaam  '
        },
        {
            naam: 'Achternaam',
            type: 'lastname',
            placeholder: 'Achternaam'
        },
        {
            naam: 'E-mail',
            type: 'email',
            placeholder: 'emailadres@example.com'
        },
        {
            naam: 'Telefoonnummer',
            type: 'tel',
            placeholder: '+31 (0)6 12 34 56 79'
        },
        {
            naam: 'Mobiel',
            type: 'mobile',
            placeholder: '+31 (0)6 12 34 56 79'
        },
        {
            naam: 'Functietitel',
            type: 'jobtitle',
            placeholder: 'Functietitel'
        },
        {
            naam: 'Website',
            type: 'website',
            placeholder: 'https://www.example.com/'
        },
        {
            naam: 'Bedrijfsnaam',
            type: 'bedrijfsnaam',
            placeholder: 'https://www.example.com/'
        }
    ],
    [BizzerdTypes.ICON]: [
        {
            naam: 'LinkedIn',
            type: 'linkedin',
            placeholder: 'https://bizzerdcard.com/bizzerd2/images/icons/linkedin.png'
        },
        {
            naam: 'Facebook',
            type: 'facebook',
            placeholder: 'https://bizzerdcard.com/bizzerd2/images/icons/facebook.png'
        },
        {
            naam: 'Twitter',
            type: 'twitter',
            placeholder: 'https://bizzerdcard.com/bizzerd2/images/icons/twitter.png'
        },
    ],
    [BizzerdTypes.BUTTON]: [
        {
            naam: 'Deelknop',
            type: 'deel',
            placeholder: 'url("https://bizzerdcard.com/bizzerd2/images/layout/button_deel.png")'
        },
        {
            naam: 'Opslaanknop',
            type: 'save',
            placeholder: 'url("https://bizzerdcard.com/bizzerd2/images/layout/button_contacten.png")'
        },
        {
            naam: 'Extraknop',
            type: 'extra',
            placeholder: 'url("https://via.placeholder.com/150")'
        }
    ],
    [BizzerdTypes.IMAGE]: [
        {
            naam: 'Image',
            type: 'image',
            placeholder: 'url("https://bizzerdcard.com/bizzerd2/images/layout/button_deel.png")'
        },
        {
            naam: 'Pasfoto',
            type: 'pasfoto',
            placeholder: 'url("https://bizzerdcard.com/bizzerd2/images/layout/button_contacten.png")'
        }
    ]
}

export default fieldTypes;