import React, {Component} from 'react';


class KoppelingHeader extends Component {
    render() {
        let header;
        if (window.location.href.includes('koppeling')) {
            header = 'Koppeling';
        }
        return (
            <div className="container container--header">
                <div className="navigation">

                    <header className="navigation--header">
                        <h3 className="section-title">{header}</h3>
                    </header>
                </div>
            </div>
        )
    }
}

export default KoppelingHeader;
