import React, {Component} from 'react';
import ColleagueHeader from "./ColleagueHeader";
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {getCurrentUser} from "../../actions/userActions";
import {deletePerson, getPersonsByCompany} from "../../actions/personActions";
import {inviteViaEmail} from "../../actions/inviteActions";

class ColleagueOverview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            persons: []
        }
    }

    componentDidMount() {
        if (this.state.user === undefined) {
            this.props.getCurrentUser();
        }
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {

        }
        if (this.props.user.user !== nextProps.user.user) {
            this.props.getPersonsByCompany();
        }
        if (nextProps.persons.persons) {
            this.setState({
                persons: nextProps.persons.persons
            })
        }
    };

    onDelete = (id) => {
        const result = window.confirm("Weet je zeker dat je deze persoon wil verwijderen?");
        if (result) {
            this.props.deletePerson(id);
        }
    };
    onInvite = (email,name,bizzerdname) => {
        const result = window.confirm("Weet je zeker dat je deze persoon wil uitnodigen?");
        if (result) {
            this.props.inviteViaEmail(email, name,bizzerdname);
        }

    };

    render() {
        let tableContent;
        if (this.state.persons !== undefined) {
            tableContent = this.state.persons.map(person => {
                let url = 'https://app2.bizzerd.com/show/' + person.domain + '/' + person.bizzerdName;
                let account =  (<a href={"#/"} onClick={() => this.onInvite(person.email,person.fullname,person.bizzerdName)}>Opnieuw Uitnodigen</a>);

                if (!person.hasReceivedMail){
                    account = ( <a href={"#/"} onClick={() => this.onInvite(person.email,person.fullname,person.bizzerdName)}>Uitnodigen</a>);
                }
                return (
                    <tr key={person._id}>
                        <td>
                            {person.firstname}
                        </td>
                        <td>
                            {person.lastname}
                        </td>
                        <td>
                            <a href={url} target={"_black"} rel={"noopener"}
                               onClick={(e) => {
                                   if (person.bizzerd === undefined) {
                                       e.preventDefault();
                                   }
                               }}
                               style={person.bizzerd === undefined ? {color: "#a0a0a0"} : {}}>{url}</a>
                        </td>
                        <td>
                            <Link to={"/dashboard/collegas/wijzigen/id/" + person._id}>Wijzigen</Link>
                        </td>
                        <td>
                            <a href={"#/"} onClick={() => this.onDelete(person._id)}>Verwijderen</a>
                        </td>
                        <td>
                            {account}
                        </td>
                    </tr>)
            });
        }
        return (
            <div className={"dashboard--main"}>
                <div className="dashboard--main-content">
                    <ColleagueHeader/>
                    <div className="container">
                        <div className="cards--wrapper">
                            <div className="card dashboard--card element--card">
                                <div className="card--header">
                                    <p>Bekijk en wijzig je collega's.</p>
                                </div>
                                <div className="card--main">
                                    <table className={'table--body'}>
                                        <thead>
                                        <tr>
                                            <th>Voornaam</th>
                                            <th>Achternaam</th>
                                            <th>Bekijken</th>
                                            <th>Wijzigen</th>
                                            <th>Verwijderen</th>
                                            <th>Account</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {tableContent}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card--footer">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    persons: state.persons,
    user: state.users
});

export default connect(mapStateToProps, {getCurrentUser, getPersonsByCompany, deletePerson,inviteViaEmail})(ColleagueOverview);
