import React, {Component} from 'react';
import {IconSets} from "../../common/IconSets";

class IconSetComponent extends Component{
    render(){
        const style = {width: '40px', height:'40px', margin: '5px', fill: '#000'};
        let iconsets = Object.values(IconSets(style, 0)).map((iconset, key) => {
            return (
                <div className={'pane--item sidebar--item'} key={key}
                     onClick={() => this.props.setIconSet(Object.keys(IconSets())[key])}>
                    {iconset.facebook}
                    {iconset.website}
                    {iconset.linkedin}
                    {iconset.twitter}
                    {iconset.instagram}
                </div>
            )
        });
        return(
            <div className="content--pane fade show active" id="nav-home" role="tabpanel">
                {iconsets}
            </div>
        )
    }
}

export default IconSetComponent;