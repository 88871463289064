import React, {Component} from 'react';
import {connect} from 'react-redux';
import TextFieldGroup from "../common/TextFieldGroup";
import {submitCompany} from "../../actions/companyActions";
import PropTypes from "prop-types";
import bizzerdLogo from '../../assets/img/bizzerd_logo.png'
import {ErrorMessage} from "../common/AuthErrorComponent";


class CreateCompany extends Component{
    constructor(props){
        super(props);
        this.state= {
            companyName:''
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.errors){
            this.setState({errors: nextProps.errors.errors});
        }
    }

    onChange = (event) => {
        this.setState({
            [event.target.name] : event.target.value
        })
    };

    onSubmit = (event) => {
        event.preventDefault();
        this.props.submitCompany({companyName: this.state.companyName}, this.props.history)
    };

    render(){
        const {errors} = this.state;
        let error;
        if(errors !== undefined){
            error = <ErrorMessage errors={errors}/>
        }
        return(
            <div className="wrapper">
                <div className="card">
                    <h2>Vul je bedrijfsnaam in</h2>
                    <p>Hoe heet je bedrijf? Vul hier de naam in. Je kunt hem later altijd aanpassen, als je dat
                        wilt. </p>
                    <form className="form-group" onSubmit={this.onSubmit}>
                        <TextFieldGroup
                            placeholder={"Bedrijfsnaam"}
                            labeltop={"Bedrijfsnaam"}
                            name={'companyName'}
                            value={this.state.companyName}
                            onChange={this.onChange}/>
                        <div className="form-group__footer">
                            <button type="submit" className="button button--primary">
                                Naar bizzerd-URL &#8594;</button>
                        </div>
                        {error}
                    </form>
                </div>
                <div className="wrapper--footer">
                    <p>Versie: 2.0. Ondervind je problemen? Neem dan contact op met <a href="mailto:support@bizzerd.com" target="_blank" rel="noopener noreferrer">support@bizzerd.com</a>.</p>
                </div>
                <div className="wrapper--logo">
                    <a href="https://www.bizzerd.com" target="_blank" rel="noopener noreferrer">
                        <img src={bizzerdLogo} alt="Klik hier om naar de homepage te gaan"/>
                    </a>
                </div>
            </div>
        )
    }
}
CreateCompany.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    submitCompany : PropTypes.func.isRequired,
    company: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
    company: state.company
});
export default connect(mapStateToProps, {submitCompany})(CreateCompany)