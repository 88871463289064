import React, {Component} from 'react';
import nodesignsvg from "../../assets/img/no_design.svg"
import {Link} from 'react-router-dom';

class RightsError extends Component{
   render(){
       return (
           <div className="placeholder--container">
               <p>Je hebt geen toegang tot deze pagina. Neem contact op met de beheerder</p>
               <div className="card--graphic">
                   <img src={nodesignsvg} alt="Klik hier om terug te gaan"/>
               </div>
               <Link to={`/dashboard/collegas/toevoegen`} className="button button--primary">
                   Klik hier om je bizzerd te wijzigen</Link>
           </div>
       )
   }
}
export default RightsError;