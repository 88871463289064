import React, {Component} from 'react';
import TableInputField from "../common/TableInputField";

class PersonalTab extends Component {
    render() {
        let photo;
        if (this.props.editMode && this.props.person._id !== undefined) {
            if (this.props.person.pasfoto)
                photo = <div><input className="content--pane fade show active" type={'file'} name={'file'}
                                    onChange={this.props.onImageUpload}/>
                    <button className={'button button--primary'} onClick={this.props.deletePhoto}>Pasfoto weghalen
                    </button>
                </div>;
            else {
                photo = <div><input className="content--pane fade show active" type={'file'} name={'file'}
                                    onChange={this.props.onImageUpload}/></div>;
            }
        } else {
            if (this.props.person.pasfoto) {
                photo = <dd>{this.props.person.pasfoto}</dd>;
            } else {
                photo = <dd>Geen pasfoto gevonden</dd>
            }
        }
        let firstname = this.props.person.firstname
        let lastname = this.props.person.lastname
        let fullname = this.props.person.fullname
        let email = this.props.person.email
        let jobtitle = this.props.person.jobtitle
        let quote = this.props.person.quote
        let bizzerdNaamField = (
            <TableInputField editMode={this.props.editMode} name={"bizzerdName"} label={"Bizzerd-naam"}
                             value={this.props.person.bizzerdName} onChange={this.props.onChange}
                             info={"Dit is het persoonlijke gedeelte van jouw URL."}
            />)
        let pasfotoField = (<dl>
                    <span data-wenk-length="fit"
                          data-wenk={"Zorg dat de pasfoto die je uploadt dezelfde verhouding heeft als in het ontwerp."}><dt>Pasfoto</dt></span>
            {photo}
        </dl>)
        if (this.props.translationActive && false) {
            firstname = this.props.translation.firstname
            lastname = this.props.translation.lastname
            fullname = this.props.translation.fullname
            email = this.props.translation.email
            jobtitle = this.props.translation.jobtitle
            quote = this.props.translation.quote
            bizzerdNaamField = undefined
            pasfotoField = undefined
        }


        return (
            <div className="table--body">
                <TableInputField editMode={this.props.editMode} name={"firstname"} label={"Voornaam*"}
                                 value={firstname} onChange={this.props.onChange}/>
                <TableInputField editMode={this.props.editMode} name={"lastname"} label={"Achternaam"}
                                 value={lastname} onChange={this.props.onChange}/>
                <TableInputField editMode={this.props.editMode} name={"fullname"} label={"Volledige naam"}
                                 value={fullname} onChange={this.props.onChange}/>
                <TableInputField editMode={this.props.editMode} name={"email"} label={"E-mailadres"}
                                 value={email} onChange={this.props.onChange}/>
                <TableInputField editMode={this.props.editMode} name={"jobtitle"} label={"Functietitel"}
                                 value={jobtitle} onChange={this.props.onChange}/>
                {bizzerdNaamField}
                <TableInputField editMode={this.props.editMode} name={"quote"} label={"Persoonlijke quote"}
                                 value={quote} onChange={this.props.onChange}
                                 info={"Dit is een persoonlijke quote"}
                />
                {pasfotoField}
            </div>
        )
    }
}

export default PersonalTab;
