export function startResize(state, props, name, type) {
    return {
        ...state,
        item:
            [{
                type: type,
                name: name,
                startingPosition: {
                    x: state.bizzerd[type][name].left,
                    y: state.bizzerd[type][name].top,
                    width: state.bizzerd[type][name].width,
                    height: state.bizzerd[type][name].height
                },
            }],
        actionstatus: {
            ...state.actionstatus,
            dragging: false,
            resizing: true
        }
    };
}

export function resize(state, props, e, direction, ref, d, name, type) {
    const changes = getAspectRatioChanges(d, state.item[0].startingPosition);
    const newArray = state.bizzerd[type].slice();
    changes.height = parseInt(changes.height)
    changes.width = parseInt(changes.width)
    // console.log(direction, direction.length,direction.length > 6)
    // if (direction.length > 6) {
    //     return {
    //         ...state, bizzerd: state.bizzerd
    //     }
    // }
    newArray[name].width = state.item[0].startingPosition.width + changes.width;
    newArray[name].height = state.item[0].startingPosition.height + changes.height;
    if (direction.toLowerCase() === "left") {
        newArray[name].left = state.item[0].startingPosition.x - changes.width;
    }
    if (direction.toLowerCase() === "top") {
        newArray[name].top = state.item[0].startingPosition.y - changes.height;
    }
    return {
        ...state,
        bizzerd: {
            ...state.bizzerd,
            [type]: newArray
        }
    }
}

export function getAspectRatioChanges(d, startingPosition) {
    let changes = {};
    if (d.width === 0 || d.height === 0) {
        changes = d;
    } else {
        if (d.height > d.width) {
            changes.height = d.height;
            changes.width = d.height * startingPosition.width / startingPosition.height;
        } else {
            changes.width = d.width;
            changes.height = d.width * startingPosition.height / startingPosition.width;
        }
    }
    return changes
}

export function stopResize(state, props, e, direction, ref, d, name, type) {
    const newItemArray = state.item.map(item => {
            return {
                name: item.name,
                type: item.type,
                startingPosition: {
                    x: state.bizzerd[item.type][item.name].left,
                    y: state.bizzerd[item.type][item.name].top,
                    width: state.bizzerd[item.type][item.name].width,
                    height: state.bizzerd[item.type][item.name].height
                }
            }
        }
    );
    return {
        ...state,
        item: newItemArray,
        actionstatus: {
            ...state.actionstatus,
            resizing: false
        }
    }
}