import React, {Component} from 'react';
import SettingsHeader from "./SettingsHeader";
import {connect} from 'react-redux';
import {getCurrentCompany, updateCompany} from "../../actions/companyActions";
import {getPayments} from "../../actions/paymentActions";
import TableInputField from "../common/TableInputField";
import {Link} from 'react-router-dom';

class PaymentSettings extends  Component{
    constructor(props){
        super(props);
        this.state = {
            company : {
                paymentDetails:{}
            },
            editMode: false,
            payments: []
        };
    }
    componentDidMount(){
        if(Object.keys(this.props.company.company).length === 0){
            this.props.getCurrentCompany();
        }
        else{
            if(this.props.company.company.paymentDetails === undefined){
                const newCompany = this.props.company.company;
                newCompany.paymentDetails = {};
                this.setState({
                    company: newCompany
                });
            }
            else{
                this.setState({
                    company: this.props.company.company
                })
            }
        }
        this.props.getPayments();
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.company.company !== this.state.company){
            if(nextProps.company.company.paymentDetails === undefined){
                const newCompany = {
                    ...nextProps.company.company,
                    paymentDetails: {}
                };
                this.setState({
                    company: newCompany
                });
            }
            else{
                this.setState({
                    company: nextProps.company.company
                })
            }
        }
        if(nextProps.payment.payments.length > 0){
            this.setState({
                payments: nextProps.payment.payments
            })
        }
    }

    handleCancel = (e) => {
        e.preventDefault();
        if(this.props.company.company.paymentDetails === undefined){
            const newCompany = this.props.company.company;
            newCompany.paymentDetails = {};
            this.setState({
                company: newCompany
            });
        }
        else{
            this.setState({
                company: this.props.company.company
            })
        }
        this.setState({
            editMode: false
        })
    };

    onChange = (e) => {
        this.setState({
            company:{
                ...this.state.company,
                paymentDetails: {
                    ...this.state.company.paymentDetails,
                    [e.target.name]: e.target.value
                }
            }
        })
    };

    switchEditMode = () => {
        if(this.state.editMode){
            this.props.updateCompany(this.state.company);
        }
        this.setState({
            editMode: !this.state.editMode
        })
    };

    render(){
        let cancelButton;
        let content;
        if(this.state.company.recurringtoken !== undefined){
            content = (
                <div className="table--body">
                    <TableInputField editMode={this.state.editMode} label={'Factuurnaam'}
                                     value={this.state.company.paymentDetails.invoiceName}
                                     name={'invoiceName'} onChange={this.onChange}/>
                    <TableInputField editMode={this.state.editMode} label={'Factuuradres'}
                                     value={this.state.company.paymentDetails.address}
                                     name={'address'} onChange={this.onChange}/>
                    <TableInputField editMode={this.state.editMode} label={'E-mailadres'}
                                     value={this.state.company.paymentDetails.email}
                                     name={'email'} onChange={this.onChange}/>
                </div>
            )
        }
        else{
            content = (
                <div>
                    <p>Je hebt nog geen betaalgegevens opgegeven.</p>
                    <Link to={'/payment'} className={'button button--text footer--secondary'}>Ga hier naar de betaalpagina</Link>
                </div>
            );
        }
        if(this.state.editMode){
            cancelButton = (<a href="#/" onClick={(e) => {this.handleCancel(e)}} className="button button--try">
                Annuleren</a>)
        }
        else{
            cancelButton = (
                <Link to={'/payment'} type=""
                      className="button button--text footer--secondary">
                    Of wijzig hier je betaalmethode →</Link>
            )
        }
        let paymentDisplay;
        if(this.state.payments.length > 0){
            paymentDisplay = this.state.payments.map(payment => {

                if (payment.status !== "paid"){
                    return undefined
                }
                console.log(payment.date)
                return (<dl key={payment._id}>
                            <dt>{(new Date(payment.date)).getMonth() + 1}</dt>
                            <dd><Link to={"/dashboard/instellingen/invoice/" + payment._id}>Bekijken</Link></dd>
                        </dl>)
            })
        }
        return(
            <div className="dashboard--main">
                <div className="dashboard--main-content">
                    <SettingsHeader/>
                    <div className="container">
                        <div className="cards--wrapper">
                            <div className="card dashboard--card">
                                <div className="card--header">
                                    <p>Wijzig je betalingsgegevens.</p>
                                </div>
                                <div className="card--main">
                                    {content}
                                </div>
                                <div className={this.state.editMode ? "card--footer collega--footer" : "card--footer"}>
                                    <button type="" className="button button--primary"
                                            onClick={() => {this.switchEditMode()}}>
                                        {!this.state.editMode ? 'Wijzigen' : 'Opslaan'}
                                    </button>
                                    {cancelButton}
                                </div>
                            </div>
                            <div className="card dashboard--card">
                                <div className="card--header">
                                    <p>Bekijk je facturen.</p>
                                </div>
                                <div className="card--main">
                                    <div className="table--body">
                                        {paymentDisplay}
                                    </div>
                                </div>
                                <div className="card--footer collega--footer">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    company: state.company,
    payment: state.payment
});

export default connect(mapStateToProps, {getCurrentCompany, updateCompany, getPayments})(PaymentSettings)