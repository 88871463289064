import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {submitTOS} from "../../actions/registerActions";

class TermsOfService extends Component{
    constructor(props){
        super(props);
        this.state = {
            accepted: false,
            errors : ''
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.errors){
            this.setState({errors: nextProps.errors.errors});
        }
        if(nextProps.user.user.tosaccepted){
            if(nextProps.user.user.company !== undefined){
                this.props.history.push('/dashboard/collegatoevoegen');
            }
            else{
                this.props.history.push('/createcompany');
            }
        }
    }

    onChange = (event) => {
        // console.log(event);
        this.setState({
            accepted: event.target.checked
        });
    };

    onSubmit = (event) => {
        event.preventDefault();
        if(this.state.accepted){
            this.props.submitTOS();
        }
        else{
            this.setState({
                errors: 'Accepteer eerst de voorwaarden.'
            })
        }
    };

    render(){
        const {errors} = this.state;
        return(
            <div>
                <h1>Terms Of Service</h1>
                <p>
                    Hier komt the TOS...
                </p>
                <form onSubmit={this.onSubmit}>
                    <input type={'checkbox'} onChange={this.onChange} checked={this.state.accepted} name={'tos'}/><label>Ik accepteer de Terms of Service.</label>
                    <p>{errors}</p>
                    <input type={'submit'} value={'Submit'}/>
                </form>
            </div>
        )
    }
}

TermsOfService.propTypes = {
    submitTOS: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    errors: state.errors,
    user: state.auth
});

export default connect(mapStateToProps, {submitTOS})(TermsOfService);