import React, {Component} from 'react';

class DesignPrompt extends Component {
    render() {
        let text = "Kom je eruit met ontwerpen? Misschien kun je wat hulp gebruiken. Wij kunnen ook het ontwerp van jouw bizzerd maken. Onze designers doen dat graag voor een vast bedrag van € 49,- excl. btw. Dat is inclusief 1 correctieronde. Je hebt dan zonder verdere moeite een professioneel ontworpen visitekaartje. Stuur een mail naar ";
        return ( 
            <div className="container">
            <div className="cards--wrapper">
              <div className="element--card dashboard--card">
                < div className = "alert--succes " > 
                    <span className="c-alert__message">{text}<a href="mailto:maakmijnontwerp@bizzerd.com">maakmijnontwerp@bizzerd.com</a></span> 
                </div>
              </div>
              </div>
            </div>)
    }
}

export default DesignPrompt;